import React, { useState } from 'react';
import './collapse.css';

function Collapse(props) {
  const [showBody, setShowBody] = useState(false);

  return (
    <li>
      <h2
        className="collapseHeader"
        onClick={() => {
          setShowBody(!showBody);
        }}
      >
        {props.title}
      </h2>
      {
        <li
          className="collapseBody"
          style={{
            maxHeight: `${showBody ? '10000px' : 0}`,
          }}
        >
          {props.children}
        </li>
      }
    </li>
  );
}

export default Collapse;
// style={{
//   height: `${showBody ? 'auto' : '0'}`,
// }}
// {showBody && <li className="collapseBody">{props.children}</li>}
