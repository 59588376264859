import React, { useState } from 'react';
import { MapChart } from './MapChart/mapchart';
import ReactTooltip from 'react-tooltip';
import Table from './Table';

function CountriesPage(props) {
  const [content, setContent] = useState('');
  const [country, setCountry] = useState('كندا');

  return (
    <>
      <section>
        <div className="container">
          <MapChart
            setTooltipContent={setContent}
            setCountry={setCountry}
            locations={null}
          />
          <ReactTooltip>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </ReactTooltip>
          {country !== '' ? (
            <Table country={country} className="container" />
          ) : (
            <p className="text-center mb-2" style={{ fontSize: '1.5rem' }}>
              عفوا لا يوجد نتائج لدينا لهذه الدولة في الوقت الحالي
            </p>
          )}
        </div>
      </section>
    </>
  );
}

export default CountriesPage;
