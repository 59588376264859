import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
// import { Button, Collapse, UncontrolledCollapse } from 'reactstrap';
import Collapse from './collapse/collapse';

function Index() {
  const data = {
    labels: ['2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'المواسم',
        data: [780.737, 695.405, 667.195, 552.398, 464.241],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };

  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [2696, 49313.382],
            offset: [5],

            // tooltip: {
            //   callbacks: {
            //     label: function (context) {
            //       let label = context.label;
            //       let value = context.formattedValue;

            //       if (!label) label = 'Unknown';

            //       let sum = 0;
            //       let dataArr = context.chart.data.datasets[0].data;
            //       dataArr.map((data) => {
            //         sum += Number(data);
            //       });

            //       const x = value.replace(/[,]/g, '');
            //       let percentage = ((x * 100) / sum).toFixed(2) + '%';
            //       return label + ': ' + percentage;
            //     },
            //   },
            // },
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
        // datalabels: {
        //   formatter: (value, dnct1) => {
        //     let sum = 0;
        //     let dataArr = dnct1.chart.data.datasets[0].data;
        //     dataArr.map((data) => {
        //       sum += data;
        //     });
        //     let percentage = ((value * 100) / sum).toFixed(2) + '%';
        //     return percentage;
        //   },
        //   display: true,
        //   color: 'black',
        //   align: 'end',
        //   // rotation: [10, 20],
        //   padding: {
        //     right: 200,
        //   },
        //   textAlign: 'center',
        //   labels: {
        //     padding: { top: 10 },
        //     title: {
        //       font: {
        //         weight: 'bold',
        //       },
        //     },
        //     value: {
        //       color: 'green',
        //     },
        //   },
        //   // align: 'center',
        //   // anchor: 'right',
        //   // offset: [10, 6],
        //   // textAlign: 'end',
        //   // padding: {
        //   //   right: [5, 10],
        //   // },
        // },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
    // plugins: [ChartDataLabels],
  };
  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/14/main.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  البطاطس
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    البطاطس من المحاصيل الرئيسية في مصر وتحتل مركزمتقدم في
                    معدلات تصدير المحاصيل الزراعية المصرية نظرا لجودتها العالية.
                    وتزرع البطاطس في الوجه البحري والجيزة والمنيا وبعض المحافظات
                    الأخري . وتزرع البطاطس في ثلاث عروات " العروة الخريفية
                    والعروة الشتوية والعروة الصيفية " . ويؤكد الحجر الزراعي
                    المصري دوما علي إنه يتم تطبيق الإجراءات والضوابط علي محطات
                    تصدير البطاطس من اجل الحفاظ على سمعة الصادرات الزراعية
                    المصرية.
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات البطاطس من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات البطاطس المصرية مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%5.47</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات البطاطس المصرية
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- الدودة القارضة*/}
                      <Collapse title="1- الدودة القارضة">
                        {/* <h2 className="details-page-title subTitle">
                          1- الدودة القارضة
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/14/qarda.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تقرض اليرقات البادرات الصغيرة عند مستوى سطح
                                التربة إما قرضا کاملا فتموت البادرة أو جزئيا
                                فتميل وتذبل مع ظهورقطع صغيرة خضراء مفتتة قرضتها
                                الدودة ولم تأكلها تظهرالإصابة في بؤرولا تعم
                                الحقل كله
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>أثناء طور البادرة وقبل أن يتخشب الساق</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1765
                                  </td>
                                  <td dir="RTL" align="right">
                                    افكت باور5% EC
                                  </td>
                                  <td dir="LTR">Lambda-cyhalothrin</td>
                                  <td dir="RTL">200سم مكعب/فدان</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1239
                                  </td>
                                  <td dir="RTL" align="right">
                                    اكسون 5% EC
                                  </td>
                                  <td dir="LTR">Lambda-cyhalothrin</td>
                                  <td dir="RTL">200سم مكعب/فدان</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1337
                                  </td>
                                  <td dir="RTL" align="right">
                                    بالسار 5% EC
                                  </td>
                                  <td dir="LTR">Lambda-cyhalothrin</td>
                                  <td dir="RTL">200سم مكعب/فدان</td>
                                  <td class="xl69" dir="LTR">
                                    70
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2630
                                  </td>
                                  <td dir="RTL" align="right">
                                    تليكام 25%EC
                                  </td>
                                  <td dir="LTR">Bifenthrin</td>
                                  <td dir="RTL">200سم مكعب/فدان</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2161
                                  </td>
                                  <td dir="RTL" align="right">
                                    توب ستار 5%EC
                                  </td>
                                  <td dir="LTR">Lambda-cyhalothrin</td>
                                  <td dir="RTL">200سم مكعب/فدان</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2495
                                  </td>
                                  <td dir="RTL" align="right">
                                    دولف - اكس 5%EC
                                  </td>
                                  <td dir="LTR">Lambda-cyhalothrin</td>
                                  <td dir="RTL">400سم مكعب/فدان</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1307
                                  </td>
                                  <td dir="RTL" align="right">
                                    فانتكس 6%CS
                                  </td>
                                  <td dir="LTR">Gamma-cyhalothrin</td>
                                  <td dir="RTL">100سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2595
                                  </td>
                                  <td dir="RTL" align="right">
                                    لامبافال 5%EC
                                  </td>
                                  <td dir="LTR">Lambda-cyhalothrin</td>
                                  <td dir="RTL">200سم مكعب/فدان</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2359
                                  </td>
                                  <td dir="RTL" align="right">
                                    نيوباور 5%EC
                                  </td>
                                  <td dir="LTR">Lambda-cyhalothrin</td>
                                  <td dir="RTL">200سم مكعب/فدان</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1536
                                  </td>
                                  <td dir="RTL" align="right">
                                    انجيو 24.7%SC
                                  </td>
                                  <td dir="LTR">
                                    Lambda-cyhalothrin 10.6%
                                    <br />
                                    Thiamethoxam 14.1%
                                  </td>
                                  <td dir="RTL">100سم مكعب/فدان</td>
                                  <td dir="LTR">-</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2- الذبابة البيضاء على البطاطس*/}
                      <Collapse title="2- الذبابة البيضاء على البطاطس">
                        {/* <h2 className="details-page-title subTitle">
                          2- الذبابة البيضاء على البطاطس
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/14/byda2.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تجعد الاوراق واصفرارها ويمكن رؤية الاطوار الغير
                                كاملة بإستخدام عدسة 10x - ظهور ندوة عسلية في
                                حالة زيادة الإصابة -تقزم النباتات وتشوها وعدم
                                نضجها
                              </p>
                            </div>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وصول الاعداد إلى 5 حشرات على الورقة في الأوراق
                              التي يتم فحصها معمليا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr height="21">
                                  <td height="21" dir="LTR" align="right">
                                    3092
                                  </td>
                                  <td dir="RTL" align="right">
                                    جامواريد 50%SC
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">60سم مكعب/لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 3- المن*/}
                      <Collapse title="3- المن">
                        {/* <h2 className="details-page-title subTitle">
                          3- المن
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/14/mn.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ظهور بقع صفراء باهتة - تجعد وإلتفاف الأوراق -
                                الندوة العسلية - ذبول الأوراق وجفافها وموتها -
                                وجود الحوريات والأفراد المجنحة - ظهور نباتات
                                مصابة بالفيرس بعد الإصابة بحشرات المن
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تبدأ في الظهور من شهر فبراير وحتى شهر إبريل وذلك
                              خلال العروة الصيفي
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              لابد من العلاج الوقائي حيث تعتبر الحشرات الكاملة
                              لمن الخوخ ناقل رئيسي لمرض إلتفاف الأوراق الفيروسي
                              وفيروس y في البطاطس
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1820
                                  </td>
                                  <td dir="RTL" align="right">
                                    اجرى فليكس 18.56%SC
                                  </td>
                                  <td dir="LTR">
                                    Abamectin 3.32%
                                    <br />
                                    Thiamethox+am 15.24%
                                  </td>
                                  <td dir="RTL">240سم مكعب/فدان</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3280
                                  </td>
                                  <td dir="RTL" align="right">
                                    انسكتساينس 18.6%SC
                                  </td>
                                  <td dir="LTR">
                                    Thiamethoxam 15.3%
                                    <br />
                                    Abamectin 3.3%
                                  </td>
                                  <td dir="RTL">60سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2889
                                  </td>
                                  <td dir="RTL" align="right">
                                    اميداتوب 70%WG
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">40جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2500
                                  </td>
                                  <td dir="RTL" align="right">
                                    اميدافارم 60%ES
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">200سم مكعب/طن(معاملة تقاوى)</td>
                                  <td dir="LTR">65</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1663
                                  </td>
                                  <td dir="RTL" align="right">
                                    بلانش 48%SC
                                  </td>
                                  <td dir="LTR">Thiacloprid</td>
                                  <td dir="RTL">30سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2265
                                  </td>
                                  <td dir="RTL" align="right">
                                    تربوبرد 48%SC
                                  </td>
                                  <td dir="LTR">Thiacloprid</td>
                                  <td dir="RTL">20سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">9</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1235
                                  </td>
                                  <td dir="RTL" align="right">
                                    تشيس 50%WG
                                  </td>
                                  <td dir="LTR">Pymetrozine</td>
                                  <td dir="RTL">20جم/100لتر ماء</td>
                                  <td dir="LTR">2</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2034
                                  </td>
                                  <td dir="RTL" align="right">
                                    تيبيكى 50%WG
                                  </td>
                                  <td dir="LTR">Flonicamid</td>
                                  <td dir="RTL">20جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    509
                                  </td>
                                  <td dir="RTL" align="right">
                                    جاوشو 70%WS
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">150جم/طن(معاملة تقاوى)</td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1690
                                  </td>
                                  <td dir="RTL" align="right">
                                    سينودور 70%WG
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">40جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1149
                                  </td>
                                  <td dir="RTL" align="right">
                                    كاليبسو 48%SC
                                  </td>
                                  <td dir="LTR">Thiacloprid</td>
                                  <td dir="RTL">20سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1908
                                  </td>
                                  <td dir="RTL" align="right">
                                    كروزرماكس بوتيتو 42%FS
                                  </td>
                                  <td dir="LTR">
                                    Fludioxonil 8.4%
                                    <br />
                                    Thiamethoxam 33.6%
                                  </td>
                                  <td dir="RTL">240سم مكعب/طن(معاملة تقاوى)</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2352
                                  </td>
                                  <td dir="RTL" align="right">
                                    كلوزر 24%SC
                                  </td>
                                  <td dir="LTR">Sulfoxaflor</td>
                                  <td dir="RTL">50سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2343
                                  </td>
                                  <td dir="RTL" align="right">
                                    ليبنويد 25%WG
                                  </td>
                                  <td dir="LTR">Thiamethoxam</td>
                                  <td dir="RTL">20جم/100لتر ماء</td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1918
                                  </td>
                                  <td dir="RTL" align="right">
                                    موتيف 50%WG
                                  </td>
                                  <td dir="LTR">Pirimicarb</td>
                                  <td dir="RTL">200جم/فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1385
                                  </td>
                                  <td dir="RTL" align="right">
                                    مونسرين جى 37%FS
                                  </td>
                                  <td dir="LTR">
                                    Imidacloprid 12%
                                    <br />
                                    Pencycuron 25%
                                  </td>
                                  <td dir="LTR">
                                    0.6سم مكعب/كجم(معاملة تقاوى)
                                  </td>
                                  <td dir="LTR">70</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2242
                                  </td>
                                  <td dir="RTL" align="right">
                                    نافيجاتو 48%SC
                                  </td>
                                  <td dir="LTR">Thisacloprid</td>
                                  <td dir="RTL">20سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2892
                                  </td>
                                  <td dir="RTL" align="right">
                                    نيوكسام 25%WG
                                  </td>
                                  <td dir="LTR">Thiamethoxam</td>
                                  <td dir="RTL">80جم/فدان</td>
                                  <td dir="LTR">70</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3025
                                  </td>
                                  <td dir="RTL" align="right">
                                    يوروكاد 60%FS
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">200سم مكعب/طن(معاملة تقاوى)</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3201
                                  </td>
                                  <td dir="RTL" align="right">
                                    ايمدسك 35%SC
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">75سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3017
                                  </td>
                                  <td dir="RTL" align="right">
                                    ايميداكيم 35%SC
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">300سم مكعب/طن(معاملة تقاوى)</td>
                                  <td dir="LTR">45</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1339
                                  </td>
                                  <td dir="RTL" align="right">
                                    ايميدازد 20%SC
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">50سم مكعب/فدان</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3189
                                  </td>
                                  <td dir="RTL" align="right">
                                    باتريوت 30.5%SC
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">300سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr height="21">
                                  <td height="21" dir="LTR" align="right">
                                    3289
                                  </td>
                                  <td dir="RTL" align="right">
                                    لوسكا بلس 48%SC
                                  </td>
                                  <td dir="LTR">Clothianidin</td>
                                  <td dir="RTL">250سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 4- دودة درنات البطاطس (في الحقل)*/}
                      <Collapse title="4- دودة درنات البطاطس (في الحقل)">
                        {/* <h2 className="details-page-title subTitle">
                          4- دودة درنات البطاطس (في الحقل)
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/14/drnat.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ظهور أنفاق كبيرة مفلطحة بين سطحى الورقة وتبدو
                                هذه الأنفاق فضية شفافة تسببها يرقات الحشرة.
                                وتصيب الدرنات المكشوفة القريبة من سطح التربة في
                                الحقل حيث تدخل اليرقات داخل الدرنات وتصنع أنفاق
                                بداية من عيون الدرنات وتستمر للداخل
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تظهر الإصابة خلال الفترة من شهرإبريل حتى
                              شهرنوفمبرمن كل عام وأشد العروات إصابة بالحشرة هي
                              زراعات العروة الصيفى والصيفي المتأخرة
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وصول الإصابة إلى 5% من النباتات التي تم فحصها
                              عشوائيا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3259
                                  </td>
                                  <td dir="RTL" align="right">
                                    اجرى اندوكس 15%EC
                                  </td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">25سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2093
                                  </td>
                                  <td dir="RTL" align="right">
                                    افوست 30%WG
                                  </td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">50جم/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2389
                                  </td>
                                  <td dir="RTL" align="right">
                                    بيدلايس 5%EC
                                  </td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">160سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1960
                                  </td>
                                  <td dir="RTL" align="right">
                                    بينى 9%SC
                                  </td>
                                  <td dir="LTR">
                                    Emamectin benzoate 1.5%
                                    <br />
                                    Indoxacarb 7.5%
                                  </td>
                                  <td dir="RTL">200سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1057
                                  </td>
                                  <td dir="RTL" align="right">
                                    تريسر 24%SC
                                  </td>
                                  <td dir="LTR">Spinosad</td>
                                  <td dir="RTL">30سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2322
                                  </td>
                                  <td dir="RTL" align="right">
                                    داى اب 90%SP
                                  </td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300جم/فدان</td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2393
                                  </td>
                                  <td dir="RTL" align="right">
                                    دالتاراب 15%SC
                                  </td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">26سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1052
                                  </td>
                                  <td dir="RTL" align="right">
                                    رنر 24%SC
                                  </td>
                                  <td dir="LTR">Methoxyfenozide</td>
                                  <td dir="LTR">37.5سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1732
                                  </td>
                                  <td dir="RTL" align="right">
                                    روكسى 10%EC
                                  </td>
                                  <td dir="LTR">Novaluron</td>
                                  <td dir="RTL">300سم مكعب/فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    300
                                  </td>
                                  <td dir="RTL" align="right">
                                    سومثيون كزد 50%EC
                                  </td>
                                  <td dir="LTR">Fenitrothion</td>
                                  <td dir="LTR">1.5لتر/فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2768
                                  </td>
                                  <td dir="RTL" align="right">
                                    شوك ميل 90%SP
                                  </td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300جم/فدان</td>
                                  <td dir="LTR">21</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1404
                                  </td>
                                  <td dir="RTL" align="right">
                                    كوراجن 20%SC
                                  </td>
                                  <td dir="LTR">Chloranraniliprole</td>
                                  <td dir="RTL">60سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    609
                                  </td>
                                  <td dir="RTL" align="right">
                                    ماتش 5%EC
                                  </td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">160سم مكعب/فدان</td>
                                  <td dir="LTR">21</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1340
                                  </td>
                                  <td dir="RTL" align="right">
                                    ملاثين 57%EC
                                  </td>
                                  <td dir="LTR">Malathion</td>
                                  <td dir="RTL">500سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    588
                                  </td>
                                  <td dir="RTL" align="right">
                                    ملاسون/كوروماندل 57%EC
                                  </td>
                                  <td dir="LTR">Malathion</td>
                                  <td dir="RTL">2لتر/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2329
                                  </td>
                                  <td dir="RTL" align="right">
                                    هاى دوكس 30%WG
                                  </td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">15جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr height="21">
                                  <td height="21" dir="LTR" align="right">
                                    2981
                                  </td>
                                  <td dir="RTL" align="right">
                                    فيكارب 15%SC
                                  </td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">100سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 5- دودة ورق القطن*/}
                      <Collapse title="5- دودة ورق القطن">
                        {/* <h2 className="details-page-title subTitle">
                          5- دودة ورق القطن
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/14/wra2.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                وجود لطع البيض على الأوراق - تغذية اليرقات على
                                القمم النامية ووجود ثقوب في الأوراق
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              زراعات شهورأكتوبر ونوفمبر خلال العروتين النيلي
                              والشتوى
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور مناطق مصابة باللطع والفقس الحديث</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2115
                                  </td>
                                  <td dir="RTL" align="right">
                                    ايماتوكس 2%EC
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">100سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1726
                                  </td>
                                  <td dir="RTL" align="right">
                                    بيتافانت 14.5%SC
                                  </td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">110سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1812
                                  </td>
                                  <td dir="RTL" align="right">
                                    جاسبر 3.5%ME
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">120سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2363
                                  </td>
                                  <td dir="RTL" align="right">
                                    صنيورون 5%EC
                                  </td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">160سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1379
                                  </td>
                                  <td dir="RTL" align="right">
                                    كابريس 5%EC
                                  </td>
                                  <td dir="LTR">Chlorfluazuron</td>
                                  <td dir="RTL">400سم مكعب/فدان</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2538
                                  </td>
                                  <td dir="RTL" align="right">
                                    كوماتش اكس 5%EC
                                  </td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">160سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    531
                                  </td>
                                  <td dir="RTL" align="right">
                                    كويك 90%SP
                                  </td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300جم/فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2615
                                  </td>
                                  <td dir="RTL" align="right">
                                    كويك جولد 40%SP
                                  </td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">675جم/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    609
                                  </td>
                                  <td dir="RTL" align="right">
                                    ماتش 5%EC
                                  </td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">160سم مكعب/فدان</td>
                                  <td dir="LTR">21</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2920
                                  </td>
                                  <td dir="RTL" align="right">
                                    مارسا 24%SC
                                  </td>
                                  <td dir="LTR">Methoxyfenozide</td>
                                  <td dir="RTL">40سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">21</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1890
                                  </td>
                                  <td dir="RTL" align="right">
                                    ميثوماكس 90%SP
                                  </td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300جم/فدان</td>
                                  <td class="xl75" dir="LTR">
                                    7
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1060
                                  </td>
                                  <td dir="RTL" align="right">
                                    ميثوميت 90%SP
                                  </td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300جم/فدان</td>
                                  <td class="xl75" dir="LTR">
                                    6
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1073
                                  </td>
                                  <td dir="RTL" align="right">
                                    نومولت 15%SC
                                  </td>
                                  <td dir="LTR">Teflubenzuron</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td class="xl75" dir="LTR">
                                    5
                                  </td>
                                </tr>
                                <tr height="21">
                                  <td height="21" dir="LTR" align="right">
                                    3316
                                  </td>
                                  <td dir="RTL" align="right">
                                    دل وان 90%SP
                                  </td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300جم/فدان</td>
                                  <td class="xl76" dir="LTR">
                                    7
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 6- العنكبوت الأحمر*/}
                      <Collapse title="6- العنكبوت الأحمر">
                        {/* <h2 className="details-page-title subTitle">
                          6- العنكبوت الأحمر
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/14/3nkbot.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                وجود نباتات مصفرة اللون في وسط الحقل - ظهوربقع
                                صفراء أو بنية على سطح الورقة سرعان ما تعم الورقة
                                - خشونة سطح الورقة المصفرة – ذبول الأوراق
                                وجفافها
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تظهر الإصابة في البطاطس الصيفي خلال شهر أبريل
                              وتظهر في البطاطس النيلي أوائل شهر سبتمبر
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وصول متوسط أعداد العنكبوت إلى عدد 5 أفراد على
                              الورقة من الأوراق التي يتم فحصها عشوائيا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1093
                                  </td>
                                  <td dir="RTL" align="right">
                                    ابالون 1.8%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">40سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1977
                                  </td>
                                  <td dir="RTL" align="right">
                                    اسينابير 24%SC
                                  </td>
                                  <td dir="LTR">Chlorfenapyr</td>
                                  <td dir="RTL">60سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1532
                                  </td>
                                  <td dir="RTL" align="right">
                                    افدال مكتين 1.8%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">160سم مكعب/فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2632
                                  </td>
                                  <td dir="RTL" align="right">
                                    اكركس 24%SC
                                  </td>
                                  <td dir="LTR">Chlorfenapyr</td>
                                  <td dir="RTL">60سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1696
                                  </td>
                                  <td dir="RTL" align="right">
                                    اوبالتين 5%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">20سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2092
                                  </td>
                                  <td dir="RTL" align="right">
                                    جات فاست 12%SC
                                  </td>
                                  <td dir="LTR">
                                    Abamectin 2%
                                    <br />
                                    Thiamethoxam 10%
                                  </td>
                                  <td dir="RTL">160سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1233
                                  </td>
                                  <td dir="RTL" align="right">
                                    جولد 1.8%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">40سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">9</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1251
                                  </td>
                                  <td dir="RTL" align="right">
                                    ديمكتين 1.8%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">160سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1420
                                  </td>
                                  <td dir="RTL" align="right">
                                    شالنجرسوبر 24%SC
                                  </td>
                                  <td dir="LTR">Chlorfenapyr</td>
                                  <td dir="RTL">60سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2336
                                  </td>
                                  <td dir="RTL" align="right">
                                    كابيتول 24%SC
                                  </td>
                                  <td dir="LTR">Chlorfenapyr</td>
                                  <td dir="RTL">60سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1136
                                  </td>
                                  <td dir="RTL" align="right">
                                    كام ميك 1.8%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">40سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1137
                                  </td>
                                  <td dir="RTL" align="right">
                                    كراتر 3.37%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">30سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">12</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2439
                                  </td>
                                  <td dir="RTL" align="right">
                                    كرومك 1.8%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">40سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1450
                                  </td>
                                  <td dir="RTL" align="right">
                                    لاسيو 1.8%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">40سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2023
                                  </td>
                                  <td dir="RTL" align="right">
                                    نوسبيدر 1.8%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">40سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    466
                                  </td>
                                  <td dir="RTL" align="right">
                                    فيرتميك 1.8%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">40سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr height="21">
                                  <td height="21" dir="LTR" align="right">
                                    3269
                                  </td>
                                  <td dir="RTL" align="right">
                                    تراست ميك 1.8%EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">40سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 7- القشرة السوداء (فطريات التربة)*/}
                      <Collapse title="7- القشرة السوداء (فطريات التربة)">
                        {/* <h2 className="details-page-title subTitle">
                          7- القشرة السوداء (فطريات التربة)
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/14/black.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تقرحات بنية داكنة تحيط بالساق جزئيا أو كليا أعلى
                                سطح التربة تكون بقع داكنة على الدرنات المصابة مع
                                وجود أجسام حجرية سوداء
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>فترة صب الدرنات</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>معاملة الدرنات قبل الزراعة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1710
                                  </td>
                                  <td dir="RTL" align="right">
                                    اميستو 25%SC
                                  </td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">1لتر/طن درنات</td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2455
                                  </td>
                                  <td dir="RTL" align="right">
                                    ايمستوبرايم 5%FS
                                  </td>
                                  <td dir="LTR">Penflufen</td>
                                  <td dir="RTL">40سم مكعب/100كجم درنات</td>
                                  <td dir="LTR">65</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1718
                                  </td>
                                  <td dir="RTL" align="right">
                                    بيوكونترول T34 12%WP
                                  </td>
                                  <td dir="LTR">Trichoderma asperellum</td>
                                  <td dir="RTL">250جم/طن درنات</td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1718
                                  </td>
                                  <td dir="RTL" align="right">
                                    بيوكونترول T34 12%WP
                                  </td>
                                  <td dir="LTR">Trichoderma asperellum</td>
                                  <td dir="RTL">250جم/فدان رى بعد الزراعة</td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1182
                                  </td>
                                  <td dir="RTL" align="right">
                                    سيليست 10%FS
                                  </td>
                                  <td dir="LTR">Fludioxonil</td>
                                  <td dir="RTL">200سم مكعب/طن درنات</td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2933
                                  </td>
                                  <td dir="RTL" align="right">
                                    فريمولد 56%WP
                                  </td>
                                  <td dir="LTR">
                                    Hymexazol 16%
                                    <br />
                                    Thiophanate methyl 40%
                                  </td>
                                  <td dir="RTL">1جم/لتر ماء</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1908
                                  </td>
                                  <td dir="RTL" align="right">
                                    كروزرماكس بوتيتو 42%FS
                                  </td>
                                  <td dir="LTR">
                                    Fludioxonil 8.4%
                                    <br />
                                    Thiamethoam 33.6%
                                  </td>
                                  <td dir="RTL">240سم مكعب/طن درنات</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1385
                                  </td>
                                  <td dir="RTL" align="right">
                                    مونسرين جى 37%FS
                                  </td>
                                  <td dir="LTR">
                                    Imidacloprid 12%
                                    <br />
                                    Pencycuron 25%
                                  </td>
                                  <td dir="LTR">0.6سم مكعب/كجم درنات</td>
                                  <td dir="LTR">70</td>
                                </tr>
                                <tr height="21">
                                  <td height="21" dir="LTR" align="right">
                                    1411
                                  </td>
                                  <td dir="RTL" align="right">
                                    مومنتو 25%SC
                                  </td>
                                  <td dir="LTR">Pencycuron</td>
                                  <td dir="RTL">1سم مكعب/كجم درنات</td>
                                  <td dir="LTR">-</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 8- العفن الجاف*/}
                      <Collapse title="8- العفن الجاف">
                        {/* <h2 className="details-page-title subTitle">
                          8- العفن الجاف
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/14/3fn.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهر بداية أعراض العفن الجاف على الدرنات على شكل
                                مناطق منخفضة على السطح الخارجي للدرنة -
                                بتطورالإصابة تتجعد القشرة الخارجية للدرنة ويحدث
                                موت للأنسجة أسفل هذه المنطقة والأعراض على
                                الأجزاء الداخلية للدرنة تكون على هيئة منطقة
                                متقرحة ذات لون أسود داكن أو بني هذه الأجزاء
                                غالبا ماتجف و تنفصل عن باقي الدرنة ويدخل
                                الفطرإلى الدرنة ويسبب حدوث التعفن في منتصف
                                الدرنة وتظهرهذه المنطقة المصابة على شكل تجويف و
                                هذه التجاويف غالبا تغطى بميسيليوم وجراثيم الفطر
                                بألوان مختلفه إما صفراء أو بنفسجية وفي النهاية
                                تفقد الدرنة المصابة مائها وتصبح جافة وذابلة
                                وصلبة
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>ما بعد الحصاد وأثناء التخزين</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>معاملة الدرنات قبل التخزين</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr height="21">
                                  <td height="21" dir="LTR" align="right">
                                    3240
                                  </td>
                                  <td dir="RTL" align="right">
                                    ديابولو SL%10
                                  </td>
                                  <td dir="LTR">Imazalil</td>
                                  <td dir="RTL">150سم مكعب/طن درنات</td>
                                  <td dir="LTR">3</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 9- الندوة المبكرة (البدرية)*/}
                      <Collapse title="9- الندوة المبكرة (البدرية)">
                        {/* <h2 className="details-page-title subTitle">
                          9- الندوة المبكرة (البدرية)
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/14/mobkr.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهر الأعراض على صورة بقع مستديرة منتظمة الشكل
                                لونها بني مع وجود حلقات متداخلة داخل البقع تظهر
                                بوضوح عند تعريض الأوراق لأشعة الشمس وتظهر
                                الأعراض أيضا على السوق في صورة بقع طولية بنية
                                اللون
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              عند ملائمة الظروف الجوية 25 إلى 30 درجة حرارة
                              مئوية مع رطوبة نسبية عالية
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور أعراض الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    698
                                  </td>
                                  <td
                                    class="xl77"
                                    dir="RTL"
                                    align="right"
                                    width="162"
                                  >
                                    اكواجن برو 52.5%WG
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 30%
                                    <br />
                                    Famoxado ne 22.5%
                                  </td>
                                  <td dir="RTL">40جم/100لتر ماء</td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    744
                                  </td>
                                  <td dir="RTL" align="right">
                                    انادول 80%WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1035
                                  </td>
                                  <td dir="RTL" align="right">
                                    اندكس 77%WP
                                  </td>
                                  <td dir="LTR">Copper hydroxide</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1503
                                  </td>
                                  <td dir="RTL" align="right">
                                    اوبتيما 25%EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2723
                                  </td>
                                  <td dir="RTL" align="right">
                                    اوت دو 32%SC
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin 20%
                                    <br />
                                    Propiconazole 12%
                                  </td>
                                  <td dir="RTL">300سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1795
                                  </td>
                                  <td dir="RTL" align="right">
                                    اى بى كور 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 8%
                                    <br />
                                    Mancozeb 64%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1900
                                  </td>
                                  <td dir="RTL" align="right">
                                    ايرون 48.9%WG
                                  </td>
                                  <td dir="LTR">
                                    Copper hydroxide 24.5%
                                    <br />
                                    Copper oxych loride 24.4%
                                  </td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1598
                                  </td>
                                  <td dir="RTL" align="right">
                                    ايمثان 75%WG
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">300جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3369
                                  </td>
                                  <td dir="RTL" align="right">
                                    التيما تريكس 52.5%WG
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 30%
                                    <br />
                                    Famoxado ne 22.5%
                                  </td>
                                  <td dir="RTL">40جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1914
                                  </td>
                                  <td dir="RTL" align="right">
                                    برافو توب 55%SC
                                  </td>
                                  <td dir="LTR">
                                    Chlorothalonil 50%
                                    <br />
                                    Difenocon azole 5%
                                  </td>
                                  <td dir="RTL">500سم مكعب/فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1646
                                  </td>
                                  <td dir="RTL" align="right">
                                    بروكسانيل 45%SC
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 5%
                                    <br />
                                    Propamocarb hydrochloride 40%
                                  </td>
                                  <td dir="RTL">250سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    298
                                  </td>
                                  <td dir="RTL" align="right">
                                    بوليرام (دى اف)80%DF
                                  </td>
                                  <td dir="LTR">Metiram</td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    946
                                  </td>
                                  <td dir="RTL" align="right">
                                    بيللكيوت 40%WP
                                  </td>
                                  <td dir="LTR">
                                    (Iminoctadine tris )albesilate
                                  </td>
                                  <td dir="RTL">100جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2226
                                  </td>
                                  <td dir="RTL" align="right">
                                    بيوجيل سى اكس 42.5%SC
                                  </td>
                                  <td dir="LTR">
                                    Chlorothalonil 37.5%
                                    <br />
                                    Cymoxanil 5%
                                  </td>
                                  <td dir="RTL">200سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3239
                                  </td>
                                  <td dir="RTL" align="right">
                                    باريوديم 18.7%WG
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 12%
                                    <br />
                                    Pyraclostrobin 6.7%
                                  </td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3358
                                  </td>
                                  <td dir="RTL" align="right">
                                    باسنجر 85%WP\
                                  </td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">250سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3073
                                  </td>
                                  <td dir="RTL" align="right">
                                    تيمبانى 34%SC
                                  </td>
                                  <td dir="LTR">
                                    Tebuconazole 9%
                                    <br />
                                    Chlorothalonil 25%
                                  </td>
                                  <td dir="RTL">250سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    743
                                  </td>
                                  <td dir="RTL" align="right">
                                    تازولين 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metalaxyl 8%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    411
                                  </td>
                                  <td dir="RTL" align="right">
                                    ترايدكس 80%WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    410
                                  </td>
                                  <td dir="RTL" align="right">
                                    ترايدكس سوبر 75%WG
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1946
                                  </td>
                                  <td dir="RTL" align="right">
                                    توليدو 43%SC
                                  </td>
                                  <td dir="LTR">Tebuconazole</td>
                                  <td dir="RTL">35سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1291
                                  </td>
                                  <td dir="RTL" align="right">
                                    جادنر 25%EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2378
                                  </td>
                                  <td dir="RTL" align="right">
                                    جانجر 50%WP
                                  </td>
                                  <td dir="LTR">
                                    Fosetyl-Aluminium 28%
                                    <br />
                                    Mancozeb 22%
                                  </td>
                                  <td dir="RTL">300جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2765
                                  </td>
                                  <td dir="RTL" align="right">
                                    جليدر 40%EW
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">35سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2299
                                  </td>
                                  <td dir="RTL" align="right">
                                    جينكس 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metalaxyl 8%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1803
                                  </td>
                                  <td dir="RTL" align="right">
                                    دايفازول 25%EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1638
                                  </td>
                                  <td dir="RTL" align="right">
                                    دايفى كور 25%EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2219
                                  </td>
                                  <td dir="RTL" align="right">
                                    دلما 50%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 45%
                                    <br />
                                    Tebuconazole 5%
                                  </td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1706
                                  </td>
                                  <td dir="RTL" align="right">
                                    ديسنت 32.5%SC
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin 20%
                                    <br />
                                    Difenoconazole 12.5%
                                  </td>
                                  <td dir="RTL">300سم مكعب/فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1644
                                  </td>
                                  <td dir="RTL" align="right">
                                    ديوت ام 73%WG
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 5%
                                    <br />
                                    Mancozeb 68%
                                  </td>
                                  <td dir="RTL">150جم/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2237
                                  </td>
                                  <td dir="RTL" align="right">
                                    روكسيل ام 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metalaxyl 8%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2274
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريدوزين 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metalaxyl 8%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2860
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريدوستار 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 8%
                                    <br />
                                    Mancozeb 64%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1354
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريفاس ام زد 65%WG
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 60%
                                    <br />
                                    Mandipropamid 5%
                                  </td>
                                  <td dir="RTL">1كجم/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1651
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريميك 30%WG
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 22%
                                    <br />
                                    Metalaxyl 8%
                                  </td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3284
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريدو سيف 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 8%
                                    <br />
                                    Mancozeb 68%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1439
                                  </td>
                                  <td dir="RTL" align="right">
                                    ساندكيور 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metalaxyl 8%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1976
                                  </td>
                                  <td dir="RTL" align="right">
                                    ساندوزول 25%EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2357
                                  </td>
                                  <td dir="RTL" align="right">
                                    سبيد سايد 25%EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1576
                                  </td>
                                  <td dir="RTL" align="right">
                                    ستون 50%WG
                                  </td>
                                  <td dir="LTR">Dimethomorph</td>
                                  <td dir="RTL">50جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2809
                                  </td>
                                  <td dir="RTL" align="right">
                                    سكوتر توب 25%EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    945
                                  </td>
                                  <td dir="RTL" align="right">
                                    سكور 25%EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2159
                                  </td>
                                  <td dir="RTL" align="right">
                                    سكوربيو 25%EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2780
                                  </td>
                                  <td dir="RTL" align="right">
                                    سيمدون 52.5%WG
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 30%
                                    <br />
                                    Famoxadone 22.5%
                                  </td>
                                  <td dir="RTL">40جم/100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2855
                                  </td>
                                  <td dir="RTL" align="right">
                                    فريكى 32.5%SC
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin 20%
                                    <br />
                                    Difenoconazole 12.5%
                                  </td>
                                  <td dir="RTL">30سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1526
                                  </td>
                                  <td dir="RTL" align="right">
                                    صنكوزيب 75%WG
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2648
                                  </td>
                                  <td dir="RTL" align="right">
                                    فانجو 50%WG
                                  </td>
                                  <td dir="LTR">Pyraclostrobin</td>
                                  <td dir="RTL">70جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1026
                                  </td>
                                  <td dir="RTL" align="right">
                                    فلنت 50%WG
                                  </td>
                                  <td dir="LTR">Trifloxystrobin</td>
                                  <td dir="RTL">20جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1704
                                  </td>
                                  <td dir="RTL" align="right">
                                    فلو اى زد 80%WG
                                  </td>
                                  <td dir="LTR">Folpet</td>
                                  <td dir="RTL">150جم/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    360
                                  </td>
                                  <td dir="RTL" align="right">
                                    فوليكور 25%EC
                                  </td>
                                  <td dir="LTR">Tebuconazole</td>
                                  <td dir="RTL">60سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2558
                                  </td>
                                  <td dir="RTL" align="right">
                                    فونيل 52.5%WG
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 30%
                                    <br />
                                    Famoxadone 22.5%
                                  </td>
                                  <td dir="RTL">40جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1600
                                  </td>
                                  <td dir="RTL" align="right">
                                    كاديلاك 80%WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3232
                                  </td>
                                  <td dir="RTL" align="right">
                                    سترايكر 20%SC
                                  </td>
                                  <td dir="LTR">Penthiopyrad</td>
                                  <td dir="RTL">100سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3011
                                  </td>
                                  <td dir="RTL" align="right">
                                    شوتير 30%SC
                                  </td>
                                  <td dir="LTR">
                                    Boscalid.20% Pyraclostrobin 10%
                                  </td>
                                  <td dir="RTL">75سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3127
                                  </td>
                                  <td dir="RTL" align="right">
                                    كينتوبلس 35%SC
                                  </td>
                                  <td dir="LTR">
                                    Epoxiconazole 10%
                                    <br />
                                    Pyraclostrobin 25%
                                  </td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1171
                                  </td>
                                  <td dir="RTL" align="right">
                                    كبريوتوب 60%WG
                                  </td>
                                  <td dir="LTR">
                                    Metiram 55%
                                    <br />
                                    Pyraclostrobin 5%
                                  </td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1839
                                  </td>
                                  <td dir="RTL" align="right">
                                    كبريوديو 11.2%EC
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 7.2%
                                    <br />
                                    Pyraclostrobin 5%
                                  </td>
                                  <td dir="RTL">250سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    372
                                  </td>
                                  <td dir="RTL" align="right">
                                    كوبرس كزد 56.35%WP
                                  </td>
                                  <td dir="LTR">Cuprous oxide</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">11</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    640
                                  </td>
                                  <td dir="RTL" align="right">
                                    كوسيد(2000)53.8%DF
                                  </td>
                                  <td dir="LTR">Copper hydroxide</td>
                                  <td dir="RTL">180جم/100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1234
                                  </td>
                                  <td dir="RTL" align="right">
                                    كونسنتو 45%SC
                                  </td>
                                  <td dir="LTR">
                                    Fenamidone 7.5% Propam ocarb
                                    <br />
                                    hydrochloride 37.5%
                                  </td>
                                  <td dir="RTL">200سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1501
                                  </td>
                                  <td dir="RTL" align="right">
                                    كيرف 25%EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    734
                                  </td>
                                  <td dir="RTL" align="right">
                                    كيور ام 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metalaxyl 8%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3206
                                  </td>
                                  <td dir="RTL" align="right">
                                    كوالا 32.5%SC
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin 20%
                                    <br />
                                    Difenoconazole 12.5%
                                  </td>
                                  <td dir="RTL">300سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1994
                                  </td>
                                  <td dir="RTL" align="right">
                                    لوناترانكيوليتى 50%SC
                                  </td>
                                  <td dir="LTR">
                                    Fluopyram 12.5%
                                    <br />
                                    Pyrimetha nil 37.5%
                                  </td>
                                  <td dir="LTR">87.5سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1645
                                  </td>
                                  <td dir="RTL" align="right">
                                    ماتكوجولد 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metalaxyl 8%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1982
                                  </td>
                                  <td dir="RTL" align="right">
                                    ماجما 52.5%WG
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 30%
                                    <br />
                                    Fanaoxadone 22.5%
                                  </td>
                                  <td dir="RTL">50جم/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2200
                                  </td>
                                  <td dir="RTL" align="right">
                                    مورانو 50%EC
                                  </td>
                                  <td dir="LTR">
                                    Difenoconazole 25%
                                    <br />
                                    Propiconazole 25%
                                  </td>
                                  <td dir="RTL">30سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2868
                                  </td>
                                  <td dir="RTL" align="right">
                                    ميتولكس 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metalaxyl 8%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2761
                                  </td>
                                  <td dir="RTL" align="right">
                                    ميجال 80%WG
                                  </td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">16جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1386
                                  </td>
                                  <td dir="RTL" align="right">
                                    نوفيكيور 70%WG
                                  </td>
                                  <td dir="LTR">
                                    COPPER<span>&nbsp; </span>sulfate (
                                    tribasic)
                                  </td>
                                  <td dir="RTL">175جم/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1863
                                  </td>
                                  <td dir="RTL" align="right">
                                    يوروتك 80%WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1988
                                  </td>
                                  <td dir="RTL" align="right">
                                    يونميل 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metalaxyl 8%
                                  </td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2994
                                  </td>
                                  <td dir="RTL" align="right">
                                    مادشوك 25%EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3197
                                  </td>
                                  <td dir="RTL" align="right">
                                    بيرامورف 18.7%WG
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 12%
                                    <br />
                                    Pyraclostrobin 6.7%
                                  </td>
                                  <td dir="RTL">100جم/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr height="41">
                                  <td height="41" dir="LTR" align="right">
                                    3147
                                  </td>
                                  <td dir="RTL" align="right">
                                    سيموفام 52.5%WG
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 30%
                                    <br />
                                    Famoxado ne 22.5%
                                  </td>
                                  <td dir="RTL">40جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 10- الندوة المتأخرة*/}
                      <Collapse title="10- الندوة المتأخرة">
                        {/* <h2 className="details-page-title subTitle">
                          10- الندوة المتأخرة
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/14/mo5r.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهر أعراض المرض على الأوراق في صورة بقع غير
                                منتظمة رمادية اللون محاطة بهالة صفراء أو صفراء
                                مخضرة ويوجد على السطح السفلي لهذه البقع نمو زغبي
                                أبيض أو رمادي عبارة عن الحوامل الجرثومية للفطر
                                المسبب والإصابة على أعناق الأوراق والسوق على
                                هيئة بقع طولية رمادية اللون وفي الإصابة الشديدة
                                يحدث كسر للسوق في حالة وجود تيار هوائي قوى أو
                                رياح شديدة
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              عند تلامس النباتات في الحقل وإرتفاع الرطوبة
                              وإنخفاض الحرارة
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند ظهور الإصابة خاصة في العروة النيلي أو في مناطق
                              الري بالرش
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1040
                                  </td>
                                  <td dir="RTL" align="right">
                                    اروميل ام زد 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metalaxyl 8%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1713
                                  </td>
                                  <td dir="RTL" align="right">
                                    اكروبات مانكوزيب 69%WG
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 9%
                                    <br />
                                    Mancozeb 60%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    546
                                  </td>
                                  <td dir="RTL" align="right">
                                    اكروبات نحاس 73.2%WP
                                  </td>
                                  <td dir="LTR">
                                    Gopper oxychloride 67.2%
                                    <br />
                                    Dimethomorph 6%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">20</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2985
                                  </td>
                                  <td dir="RTL" align="right">
                                    التيما 50%SC
                                  </td>
                                  <td dir="LTR">Fluazinam</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3039
                                  </td>
                                  <td dir="RTL" align="right">
                                    ازور اوبتى 48%SC
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin 8%
                                    <br />
                                    Chlorotha lonil 40%
                                  </td>
                                  <td dir="RTL">850سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2429
                                  </td>
                                  <td dir="RTL" align="right">
                                    اكروبست بلس 46%WG
                                  </td>
                                  <td dir="LTR">
                                    Gopper oxychloride 40%
                                    <br />
                                    Dimethomorph 6%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">9</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    698
                                  </td>
                                  <td dir="RTL" align="right">
                                    اكواجن برو 52.5%WG
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 30%
                                    <br />
                                    Famoxadone 22.5%
                                  </td>
                                  <td dir="RTL">40جم/100لتر ماء</td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1997
                                  </td>
                                  <td dir="RTL" align="right">
                                    اورفيجو 52.5%SC
                                  </td>
                                  <td dir="LTR">
                                    Ametoctradin 30%
                                    <br />
                                    Dimethomorph 22.5%
                                  </td>
                                  <td dir="RTL">80سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1146
                                  </td>
                                  <td dir="RTL" align="right">
                                    اوكسى بلس 47.89%WP
                                  </td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3041
                                  </td>
                                  <td dir="RTL" align="right">
                                    اجرومورف 50%WG
                                  </td>
                                  <td dir="LTR">Dimethomorph</td>
                                  <td dir="RTL">50جم/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1396
                                  </td>
                                  <td dir="RTL" align="right">
                                    برادو 72%SC
                                  </td>
                                  <td dir="LTR">Chlorothalonil</td>
                                  <td dir="RTL">875سم مكعب/فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1914
                                  </td>
                                  <td dir="RTL" align="right">
                                    برافو توب 55%SC
                                  </td>
                                  <td dir="LTR">
                                    Chlorothalonil 50%
                                    <br />
                                    Difenocon+azole 5%
                                  </td>
                                  <td dir="RTL">500سم مكعب/فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    699
                                  </td>
                                  <td dir="RTL" align="right">
                                    بروبلانت 72.2%SL
                                  </td>
                                  <td dir="LTR">Propamocarb hydrochloride</td>
                                  <td dir="RTL">250سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1324
                                  </td>
                                  <td dir="RTL" align="right">
                                    بروكيور 72.2%SL
                                  </td>
                                  <td dir="LTR">Propamocarb hydrochloride</td>
                                  <td dir="RTL">250سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1403
                                  </td>
                                  <td dir="RTL" align="right">
                                    بريفكس ان 72.2%SL
                                  </td>
                                  <td dir="LTR">Propamocarb hydrochloride</td>
                                  <td dir="RTL">250سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2807
                                  </td>
                                  <td dir="RTL" align="right">
                                    بريسيديوم 36%SC
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 18% <br />
                                    Zoxamide 18%
                                  </td>
                                  <td dir="RTL">100سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    270
                                  </td>
                                  <td dir="RTL" align="right">
                                    يريفيكور - ن 72.2%SL
                                  </td>
                                  <td dir="LTR">Propamocarb hydrochloride</td>
                                  <td dir="RTL">250سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1851
                                  </td>
                                  <td dir="RTL" align="right">
                                    بريميتوكس فورت 41%WP
                                  </td>
                                  <td dir="LTR">
                                    Copper Complex 21%
                                    <br />
                                    Mancozeb 20%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1813
                                  </td>
                                  <td dir="RTL" align="right">
                                    بوكاجى 50%SC
                                  </td>
                                  <td dir="LTR">Fluazinam</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3340
                                  </td>
                                  <td dir="RTL" align="right">
                                    براجولين 75%WG
                                  </td>
                                  <td dir="LTR">
                                    Trifloxystrobin 25%
                                    <br />
                                    Tebuconazole 50%
                                  </td>
                                  <td dir="RTL">40جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3374
                                  </td>
                                  <td dir="RTL" align="right">
                                    تيكرز 40%WP
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 30% <br />
                                    Cyazofamid 10%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2846
                                  </td>
                                  <td dir="RTL" align="right">
                                    تازير 25%SC
                                  </td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    411
                                  </td>
                                  <td dir="RTL" align="right">
                                    ترايدكس 80%WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    410
                                  </td>
                                  <td dir="RTL" align="right">
                                    ترايدكس سوبر 75%WG
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3054
                                  </td>
                                  <td dir="RTL" align="right">
                                    تاور 69.93%WG
                                  </td>
                                  <td dir="LTR">
                                    %3.13 Mancozeb + Cymoxanil 4% + copper
                                    oxychloride 52.63%
                                  </td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    84
                                  </td>
                                  <td dir="RTL" align="right">
                                    جالبن مانكوزيب 58%WP
                                  </td>
                                  <td dir="LTR">
                                    Benalaxyl 10%
                                    <br />
                                    Mancozeb 48%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    85
                                  </td>
                                  <td dir="RTL" align="right">
                                    جالبن نحاس 69.8%WP
                                  </td>
                                  <td dir="LTR">
                                    Benalaxyl 11%
                                    <br />
                                    Copper oxychloride 58.8%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">20</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2721
                                  </td>
                                  <td dir="RTL" align="right">
                                    جروماكس 86%WG
                                  </td>
                                  <td dir="LTR">
                                    Bordeaux mixture 83%
                                    <br />
                                    Cymoxanil 3%
                                  </td>
                                  <td dir="RTL">400جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1724
                                  </td>
                                  <td dir="RTL" align="right">
                                    جولد ستون 69%WP
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 9%
                                    <br />
                                    Mancozeb 60%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    189
                                  </td>
                                  <td dir="RTL" align="right">
                                    دايثين ام 80-45%WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    735
                                  </td>
                                  <td dir="RTL" align="right">
                                    دايكوريب 80%WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2281
                                  </td>
                                  <td dir="RTL" align="right">
                                    دودجو 68%WG
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64
                                    <br />
                                    Metalax yl-M 4%
                                  </td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">21</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2974
                                  </td>
                                  <td dir="RTL" align="right">
                                    رازونيل 45%WP
                                  </td>
                                  <td dir="LTR">
                                    Copper oxychloride 40%
                                    <br />
                                    Metala xyl-M 4%
                                  </td>
                                  <td dir="RTL">800جم/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1485
                                  </td>
                                  <td dir="RTL" align="right">
                                    رادو النصر 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metala xyl 8%
                                  </td>
                                  <td dir="RTL">300جم/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2066
                                  </td>
                                  <td dir="RTL" align="right">
                                    رانمان 40%SC
                                  </td>
                                  <td dir="LTR">Cyazofamid</td>
                                  <td dir="RTL">30سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1062
                                  </td>
                                  <td dir="RTL" align="right">
                                    رولكس 50%WP
                                  </td>
                                  <td dir="LTR">
                                    Copper oxychloride 35%
                                    <br />
                                    Metalax yl 15%
                                  </td>
                                  <td dir="RTL">150جم/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1211
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريدوميل جولد ام زد 68%WG
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metalax yl-M 4%
                                  </td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    763
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريدوميل جولد بلاس 71.5%WP
                                  </td>
                                  <td dir="LTR">
                                    Cooper oxychloride 69%
                                    <br />
                                    Metalax yl 2.5%
                                  </td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1220
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريفاس 25%SC
                                  </td>
                                  <td dir="LTR">Mandipropamid</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1774
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريفاس توب 50%SC
                                  </td>
                                  <td dir="LTR">
                                    Difenoconazole 25%
                                    <br />
                                    Mandipropamid 25%
                                  </td>
                                  <td dir="RTL">200سم مكعب/فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1354
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريفاس ام زد 65%WG
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 60%
                                    <br />
                                    Mandipropamid 25%
                                  </td>
                                  <td dir="RTL">1كجم/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1873
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريفال 72.2%SL
                                  </td>
                                  <td dir="LTR">Propamocarb hydrochloride</td>
                                  <td dir="RTL">250سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2948
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريدوميل جولد ار 26.89%WG
                                  </td>
                                  <td dir="LTR">
                                    Copper oxychloride 24.89%
                                    <br />
                                    Metalax yl-M 2%
                                  </td>
                                  <td dir="RTL">2كجم/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2292
                                  </td>
                                  <td dir="RTL" align="right">
                                    زان 50%SC
                                  </td>
                                  <td dir="LTR">Fluazinam</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1953
                                  </td>
                                  <td dir="RTL" align="right">
                                    زجنال 50%SC
                                  </td>
                                  <td dir="LTR">Fluazinam</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1141
                                  </td>
                                  <td dir="RTL" align="right">
                                    زووم(2007)38.37%WP
                                  </td>
                                  <td dir="LTR">Copper hydroxide</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2420
                                  </td>
                                  <td dir="RTL" align="right">
                                    ستروك 80%WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2108
                                  </td>
                                  <td dir="RTL" align="right">
                                    ستروماك 25%SC
                                  </td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2243
                                  </td>
                                  <td dir="RTL" align="right">
                                    سيفومان 10%SC
                                  </td>
                                  <td dir="LTR">Cyazofamid</td>
                                  <td dir="RTL">120سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1214
                                  </td>
                                  <td dir="RTL" align="right">
                                    شامب دى بى 57.6%WG
                                  </td>
                                  <td dir="LTR">Copper hydroxide</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">1</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1102
                                  </td>
                                  <td dir="RTL" align="right">
                                    شيرلان 50%SC
                                  </td>
                                  <td dir="LTR">Fluazinam</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2874
                                  </td>
                                  <td dir="RTL" align="right">
                                    شيرمان برو 25%WP
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 12.5%
                                    <br />
                                    Metalax yl 12.5%
                                  </td>
                                  <td dir="RTL">170جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3194
                                  </td>
                                  <td dir="RTL" align="right">
                                    شينزى 34%SC
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin 17%
                                    <br />
                                    Fluazin am 17%
                                  </td>
                                  <td dir="RTL">125سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2276
                                  </td>
                                  <td dir="RTL" align="right">
                                    فنجى مورت 69%WP
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 9%
                                    <br />
                                    Pyraclostr obin 60%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    674
                                  </td>
                                  <td dir="RTL" align="right">
                                    فنحيوران او اتش 77%WP
                                  </td>
                                  <td dir="LTR">Copper hydroxide</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr height="60">
                                  <td height="60" dir="LTR" align="right">
                                    2433
                                  </td>
                                  <td dir="RTL" align="right">
                                    فوبيسى 44.2%WP
                                  </td>
                                  <td dir="LTR">
                                    Benalaxyl 6%
                                    <br />
                                    Cymoxa nil 3.2%
                                    <br />
                                    Folpe t 35%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2560
                                  </td>
                                  <td dir="RTL" align="right">
                                    فورت-برو 25%WP
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 5%
                                    <br />
                                    Dimethomorph20%
                                  </td>
                                  <td dir="RTL">80جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2015
                                  </td>
                                  <td dir="RTL" align="right">
                                    فولار ام زد 69%WP
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 9%
                                    <br />
                                    Mancoz eb 60%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2635
                                  </td>
                                  <td dir="RTL" align="right">
                                    فولى ستار 40%SC
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 20%
                                    <br />
                                    Fluazin am 20%
                                  </td>
                                  <td dir="RTL">300سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1271
                                  </td>
                                  <td dir="RTL" align="right">
                                    فوليوجولد 53.75%SC
                                  </td>
                                  <td dir="LTR">
                                    Chlorothalonil 50%
                                    <br />
                                    Metalaxyl<span>&nbsp; </span>M 3.75%
                                  </td>
                                  <td dir="RTL">300سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2045
                                  </td>
                                  <td dir="RTL" align="right">
                                    فيجى كلين 15%WG
                                  </td>
                                  <td dir="LTR">Benthiavalicarb-isopropyl</td>
                                  <td dir="RTL">50جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2702
                                  </td>
                                  <td dir="RTL" align="right">
                                    كاشان 20%SC
                                  </td>
                                  <td dir="LTR">Cyazofamid</td>
                                  <td dir="RTL">300سم مكعب/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2298
                                  </td>
                                  <td dir="RTL" align="right">
                                    كباكيت 85%WP
                                  </td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1839
                                  </td>
                                  <td dir="RTL" align="right">
                                    كبريوديو 11.2%EC
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 7.2%
                                    <br />
                                    Pyaclostr obin 4%
                                  </td>
                                  <td dir="RTL">250سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1071
                                  </td>
                                  <td dir="RTL" align="right">
                                    كيوربلاس 74.5%WP
                                  </td>
                                  <td dir="LTR">
                                    Copper oxychloride 59.5%
                                    <br />
                                    Metalax yl 15%
                                  </td>
                                  <td dir="RTL">150جم/100لتر ماء</td>
                                  <td dir="LTR">23</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3026
                                  </td>
                                  <td dir="RTL" align="right">
                                    كفروستار 25%SC
                                  </td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3288
                                  </td>
                                  <td dir="RTL" align="right">
                                    كلوستو 25%SC
                                  </td>
                                  <td dir="LTR">Pyraclostrobin</td>
                                  <td dir="RTL">60سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2079
                                  </td>
                                  <td dir="RTL" align="right">
                                    ليماى 20%SC
                                  </td>
                                  <td dir="LTR">Amisulbrom</td>
                                  <td dir="RTL">40سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1814
                                  </td>
                                  <td dir="RTL" align="right">
                                    مانفيل 75%WG
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">200جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1079
                                  </td>
                                  <td dir="RTL" align="right">
                                    مانكو 80%WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1410
                                  </td>
                                  <td dir="RTL" align="right">
                                    مانكو النصر 80%WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1277
                                  </td>
                                  <td dir="RTL" align="right">
                                    مانكوسيل بلاس 50%WP
                                  </td>
                                  <td dir="LTR">
                                    Copper oxychloride 35%
                                    <br />
                                    Metalax yl 15%
                                  </td>
                                  <td dir="RTL">150جم/100لتر ماء</td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2168
                                  </td>
                                  <td dir="RTL" align="right">
                                    مترودكس 80%WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1570
                                  </td>
                                  <td dir="RTL" align="right">
                                    مورفى 71.3%WG
                                  </td>
                                  <td dir="LTR">
                                    Dimethomorph 11.3%
                                    <br />
                                    Folpe t 60%
                                  </td>
                                  <td dir="RTL">800جم/فدان</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1437
                                  </td>
                                  <td dir="RTL" align="right">
                                    مونتورو 30%EC
                                  </td>
                                  <td dir="LTR">
                                    Difenoconazole 15%
                                    <br />
                                    Propicon azole 15%
                                  </td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2481
                                  </td>
                                  <td dir="RTL" align="right">
                                    ميتالمان 72%WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb 64%
                                    <br />
                                    Metala xyl 8%
                                  </td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2529
                                  </td>
                                  <td dir="RTL" align="right">
                                    ميكروستار 25%SC
                                  </td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1417
                                  </td>
                                  <td dir="RTL" align="right">
                                    ناندو 50%SC
                                  </td>
                                  <td dir="LTR">Fluazinam</td>
                                  <td dir="RTL">50سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1386
                                  </td>
                                  <td dir="RTL" align="right">
                                    نوفيكيور 70%WG
                                  </td>
                                  <td dir="LTR">Copper sulfate (tribasic)</td>
                                  <td dir="RTL">125جم/100لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3136
                                  </td>
                                  <td dir="RTL" align="right">
                                    نيلبات 50%WP
                                  </td>
                                  <td dir="LTR">Dimethomorph</td>
                                  <td dir="RTL">250جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2431
                                  </td>
                                  <td dir="RTL" align="right">
                                    هاى كوجين وان 52.5%WP
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil 30%
                                    <br />
                                    Famoxad one 22.5
                                  </td>
                                  <td dir="RTL">40جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr height="41">
                                  <td height="41" dir="LTR" align="right">
                                    2272
                                  </td>
                                  <td dir="RTL" align="right">
                                    وينر جولد 53.75%SC
                                  </td>
                                  <td dir="LTR">
                                    Chlorothalonil 50%
                                    <br />
                                    Metalaxyl M 3.75%
                                  </td>
                                  <td dir="RTL">300سم مكعب/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 11- نيماتودا التقرح*/}
                      <Collapse title="11- نيماتودا التقرح">
                        {/* <h2 className="details-page-title subTitle">
                          11- نيماتودا التقرح
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/14/nemo.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>وجود تقرحات على الجذور الثانوية</p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>بعد الزراعة بحوالي شهر</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند الزراعة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    377
                                  </td>
                                  <td dir="RTL" align="right">
                                    موكاب 10 % GR
                                  </td>
                                  <td dir="LTR">Ethoprophos</td>
                                  <td dir="RTL">30كجم/فدان</td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2628
                                  </td>
                                  <td dir="RTL" align="right">
                                    نيمافت 20 % EC
                                  </td>
                                  <td dir="LTR">Ethoprophos</td>
                                  <td dir="RTL">2لتر/فدان</td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1616
                                  </td>
                                  <td dir="RTL" align="right">
                                    تريفيجو 2 % SC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="LTR">2.5لتر/فدان</td>
                                  <td dir="LTR">45</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2986
                                  </td>
                                  <td dir="RTL" align="right">
                                    كفرو فوس 10% GR
                                  </td>
                                  <td dir="LTR">Ethoprophos</td>
                                  <td dir="RTL">30كجم/فدان</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr height="21">
                                  <td height="21" dir="LTR" align="right">
                                    2941
                                  </td>
                                  <td dir="RTL" align="right">
                                    فيليوم برايم SC % 40
                                  </td>
                                  <td dir="LTR">Fluopyram</td>
                                  <td dir="RTL">500سم مكعب/فدان</td>
                                  <td dir="LTR">70</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
