import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
// import { Button, Collapse, UncontrolledCollapse } from 'reactstrap';
import Collapse from './collapse/collapse';

function Index() {
  const data = {
    labels: ['2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'المواسم',
        data: [566.278, 445.991, 825.379, 354.279, 267.515],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };

  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [2191.93, 36498.1],
            offset: [5],
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
  };
  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/08/main.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  البصل
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    يوجد من البصل ثلاثة أنواع هي البصل الأبيض والأحمر وأضحى
                    اليوم نوع ثالث هو البصل الأبيض الناصع غير المصفر. يتوفر
                    البصل حالياً طازجاً، مجمداً، معلباً، مخللاً أو مجففاً. يتميز
                    البصل المصري بجودته العالية والقيمة الغذائية المهمة لصحة
                    الإنسان وتحتل مصر مرتبة متقدمة في تصدير البصل طبقا لتقارير
                    الحكومة المصرية .
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات البصل من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات البصل المصري مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%6.01</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات البصل المصري
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- التربس*/}
                      <Collapse title="1- التربس">
                        {/* <h2 className="details-page-title subTitle">
                          1- التربس
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/08/trbs.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                إصفرار النباتات في حالة الإصابات الشديدة مع ظهور
                                بقع فضية على أنصال الأوراق وتتقارب هذه البقع في
                                حالة شدة الإصابة حيث تتحول البقع إلى اللون
                                الرمادي فالبني ثم تجف - تشاهد الحوريات الصفراء
                                والبرتقالية في قلب النبات وكذلك الأفراد المجنحة
                                داكنة اللون
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p> بمجرد الإنبات</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وصول الأعداد إلى 10 حورية وحشرة كاملة / بادرة
                              من البادرات التي تم فحصها عشوائيا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2057
                                  </td>
                                  <td dir="RTL" align="right">
                                    بروف بلس 3.4% ME<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Emamectin benzoate<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    25<span>&nbsp;</span>سم مكعب / 100 لتر ماء
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2675
                                  </td>
                                  <td dir="RTL" align="right">
                                    ثياكلومكس 98% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Thiacloprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    50<span>&nbsp;</span>سم مكعب / 100 لتر ماء
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2552
                                  </td>
                                  <td dir="RTL" align="right">
                                    ديليجيت 25% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Spinetoram<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">50 جم / فدان</td>
                                  <td dir="LTR">1</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1329
                                  </td>
                                  <td dir="RTL" align="right">
                                    رادينت 12% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Spinetoram<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    120 سم مكعب /<span>&nbsp; </span>فدان
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2- البياض الزغبي*/}
                      <Collapse title="2- البياض الزغبي">
                        {/* <h2 className="details-page-title subTitle">
                          2- البياض الزغبي
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/08/byad.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                بقع بيضاوية أو مطاولة إسطوانية الشكل مختلفة
                                الحجم ذات لون أخضر شاحب - وتظهر جراثيم الفطر
                                الأرجوانية على سطح البقع - لا تقوى الشماريخ على
                                حمل النورات وتلتوي وتأخذ شكل رقبة الأوزة في
                                البصل المروي
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              جميع مراحل نمو النبات حسب الظروف البيئية السائدة
                              في مناطق الزراعة المختلفة
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p> عند ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1713
                                  </td>
                                  <td dir="RTL" align="right">
                                    اكروبات مانكوزيب 69% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Dimethomorph
                                    <span>&nbsp;</span>
                                    <br />
                                    Mancozeb<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1192
                                  </td>
                                  <td dir="RTL" align="right">
                                    اميستار توب 32.5% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Azoxystrobin<span>&nbsp; </span>
                                    <br />
                                    Difenoconazole <br />
                                  </td>
                                  <td dir="RTL">
                                    300 سم مكعب /<span>&nbsp; </span>فدان
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    743
                                  </td>
                                  <td dir="RTL" align="right">
                                    تازولين 72% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Mancozeb
                                    <span>&nbsp;</span>
                                    <br />
                                    Metalaxyl<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    763
                                  </td>
                                  <td dir="RTL" align="right">
                                    ريدوميل جولد بلاس 71.5% W
                                    <span>
                                      P<span>&nbsp;</span>
                                    </span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Copper oxychloride
                                    <span>&nbsp; </span>
                                    <br />
                                    Metalaxyl-M (Mefenoxam <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    200 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2250
                                  </td>
                                  <td dir="RTL" align="right">
                                    سيم - ميلديو 72% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Cymoxanil
                                    <span>&nbsp;</span>
                                    <br />
                                    Mancozeb <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    150 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1214
                                  </td>
                                  <td dir="RTL" align="right">
                                    شامب دي بي 57.6% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Copper hydroxide<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    180 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1271
                                  </td>
                                  <td dir="RTL" align="right">
                                    فوليو جولد 53.75% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Chlorothalonil
                                    <span>&nbsp; </span>
                                    <br />
                                    Metalaxyl-M (Mefenoxam <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">1 لتر / الفدان</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    304
                                  </td>
                                  <td dir="RTL" align="right">
                                    كوبوكس 84% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Copper oxychloride<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2259
                                  </td>
                                  <td dir="RTL" align="right">
                                    مولر 69% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Dimethomorph
                                    <span>&nbsp;</span>
                                    <br />
                                    Mancozeb <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">21</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3235
                                  </td>
                                  <td dir="RTL" align="right">
                                    فول كروز 72% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Chlorothalonil
                                    <span>&nbsp; </span>
                                    <br />
                                    Metalaxyl<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3113
                                  </td>
                                  <td dir="RTL" align="right">
                                    انيكس كومبى 32.5% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Azoxystrobin
                                    <span>&nbsp;</span>
                                    <br />
                                    Difenoconazole <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    50<span>&nbsp;</span>سم مكعب / 100 لتر ماء
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 3-  العفن الأبيض*/}
                      <Collapse title="3-  العفن الأبيض">
                        {/* <h2 className="details-page-title subTitle">
                          3-  العفن الأبيض
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/08/3fn.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                النباتات المصابة تكون في شكل بقع متناثرة بالحقل
                                وتظهر الأعراض في صورة إصفرار ثم ذبول و موت
                                الأوراق من قمة الورقة لأسفلها ومن الأوراق
                                الخارجية المسنة إلى الأوراق الداخلية الحديثة -
                                وتظهر على الجذور تحت سطح التربة أعفان وعند
                                اقتلاع النباتات المصابة يشاهد على الأبصال
                                المصابة نمو أبيض قطني و تتكون عليها أجسام حجرية
                                سوداء صغيرة في حجم رأس الدبوس
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              يبدأ ظهور أعراض الإصابة في أواخر شهر ديسمبر و
                              أوائل شهر يناير ويستمر حتى آخر شهر فبراير و أوائل
                              شهرمارس ثم يتوقف المرض بعد هذا التوقيت نتيجة
                              إرتفاع درجة الحرارة الغير ملائمة لحدوث وتطور المرض
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              غمس الشتلات قبل الزراعة بالمطهرات القطرية مع إجراء
                              الرش بهذه المطهرات بعد 6 و 12 أسبوع من الزراعة{' '}
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1715
                                  </td>
                                  <td dir="RTL" align="right">
                                    <span>&nbsp;</span>تراست 25% EW
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Tebuconazole
                                  </td>
                                  <td dir="RTL">
                                    25<span>&nbsp;</span>جم / 100 لتر ماء
                                    <span>&nbsp; </span>لتر ماء غمر الشتلات قبل
                                    الزراعه + الرش بمعدل 187.5سم مكعب / 100 لتر
                                    ماء مرتين بعد 1206 اسبوع من الزراعه
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2216
                                  </td>
                                  <td dir="RTL" align="right">
                                    تيبوزين 25% EW<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Tebuconazole<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    100 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2422
                                  </td>
                                  <td dir="RTL" align="right">
                                    سوبركور 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Tebuconazole<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    25<span>&nbsp;</span>جم / 100 لتر ماء
                                    <span>&nbsp; </span>لتر ماء غمر الشتلات قبل
                                    <span>
                                      الزراعه + الرش بمعدل 187.5سم مكعب / 100
                                      لتر ماء مرتين بعد 1206 اسبوع من الزراعه
                                      <span>&nbsp;</span>
                                    </span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    360
                                  </td>
                                  <td dir="RTL" align="right">
                                    فوليكور 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Tebuconazole<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    26<span>&nbsp;</span>جم / 100 لتر ماء
                                    <span>&nbsp; </span>لتر ماء غمر الشتلات قبل
                                    <span>
                                      الزراعه + الرش بمعدل 187.5سم مكعب / 100
                                      لتر ماء مرتين بعد 1206 اسبوع من الزراعه
                                      <span>&nbsp;</span>
                                    </span>
                                  </td>
                                  <td dir="LTR">28</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3200
                                  </td>
                                  <td dir="RTL" align="right">
                                    توب كور 25% EW<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Tebuconazole<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    25<span>&nbsp;</span>جم / 100 لتر ماء
                                    <span>&nbsp; </span>لتر ماء غمر الشتلات قبل
                                    <span>
                                      الزراعه + الرش بمعدل 187.5سم مكعب / 100
                                      لتر ماء مرتين بعد الزراعه ب 6 اسابيع ثم
                                      الرش بنفس الجرعه<span>&nbsp;</span>بعد
                                      الاولى ي 6 اسابيع<span>&nbsp;</span>
                                    </span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 4- اللطعة الأرجوانية*/}
                      <Collapse title="4- اللطعة الأرجوانية">
                        {/* <h2 className="details-page-title subTitle">
                          4- اللطعة الأرجوانية
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/08/lt3a.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهر الإصابة على الأوراق والشماريخ الزهرية في
                                صورة بقع بيضاوية أو مستديرة تأخذ شكل تقرحات
                                غائرة بها دوائر متداخلة في بعضها وذات وسط
                                ارجواني أو مسود وحافة صفراء باهتة وتجف الأنسجة
                                المصابة وتتحول إلى اللون القرمزي ثم البني
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              يظهر هذا المرض في أواخر شهر فبراير وأوائل شهر مارس
                              وغالبا ما يظهر هذا المرض مصاحبا لمرض البياض الزغبي
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              إجراء الرش بعد 45 - 60 يوم من الزراعة أو عند بداية
                              الإصابة أو توفر الظروف البيئية المناسبة
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2998
                                  </td>
                                  <td dir="RTL" align="right">
                                    اوبن-اكسترا 72% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Chlorothalonil<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250<span>&nbsp;</span>سم مكعب / 100 لتر ماء
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2380
                                  </td>
                                  <td dir="RTL" align="right">
                                    فلدوماكس 62.5% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Cyprodinil
                                    <span>&nbsp;</span>
                                    <br />
                                    Fludioxonil<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    50<span>&nbsp;</span>جم / 100 لتر ماء
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
