import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
// import { Button, Collapse, UncontrolledCollapse } from 'reactstrap';
import Collapse from './collapse/collapse';

function Index() {
  const data = {
    labels: ['2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'المواسم',
        data: [0, 0, 0, 0.849, 0.272],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };

  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [1, 2561.52],
            offset: [5],

            // tooltip: {
            //   callbacks: {
            //     label: function (context) {
            //       let label = context.label;
            //       let value = context.formattedValue;

            //       if (!label) label = 'Unknown';

            //       let sum = 0;
            //       let dataArr = context.chart.data.datasets[0].data;
            //       dataArr.map((data) => {
            //         sum += Number(data);
            //       });

            //       const x = value.replace(/[,]/g, '');
            //       let percentage = ((x * 100) / sum).toFixed(2) + '%';
            //       return label + ': ' + percentage;
            //     },
            //   },
            // },
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
        // datalabels: {
        //   formatter: (value, dnct1) => {
        //     let sum = 0;
        //     let dataArr = dnct1.chart.data.datasets[0].data;
        //     dataArr.map((data) => {
        //       sum += data;
        //     });
        //     let percentage = ((value * 100) / sum).toFixed(2) + '%';
        //     return percentage;
        //   },
        //   display: true,
        //   color: 'black',
        //   align: 'end',
        //   // rotation: [10, 20],
        //   padding: {
        //     right: 200,
        //   },
        //   textAlign: 'center',
        //   labels: {
        //     padding: { top: 10 },
        //     title: {
        //       font: {
        //         weight: 'bold',
        //       },
        //     },
        //     value: {
        //       color: 'green',
        //     },
        //   },
        //   // align: 'center',
        //   // anchor: 'right',
        //   // offset: [10, 6],
        //   // textAlign: 'end',
        //   // padding: {
        //   //   right: [5, 10],
        //   // },
        // },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
    // plugins: [ChartDataLabels],
  };
  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/06/main.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  الفلفل
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    ينتمي الفلفل إلي العائلة الباذنجنية ويستخدم الفلفل على نطاق
                    واسع في العديد من المأكولات . وتشتهر مصر بالفلفل الحار
                    فيستخدم كمحصول طازج او يتم تجفيفه ويتم إستخدامه كتوابل .
                    ويمتلك الفلفل قيمة غذائية عالية . ومع كل سنة يزداد حجم تصدير
                    مصر من الفلفل بأنواعه نظرا لجودته العالية .
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات الفلفل من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات الفلفل المصري مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%0.04</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات الفلفل المصري
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- التربس*/}
                      <Collapse title="1- التربس">
                        {/* <h2 className="details-page-title subTitle">
                          1- التربس
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/06/trbs.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تتغذى افراد التربس على الأوراق والبراعم والثمار
                                مسببة تبقعات بيضاء - فضية فاتحة تتحول هذه البقع
                                إلى اللون الأسود كما يسبب التربس سقوط الأزهار
                                وعدم تكونها
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>عند ظهور أعراض الإصابة</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بداية ظهور الأفراد على الأوراق والأزهار</p>
                          </li>
                          <li>
                            <h3 className="h5">إرشادات خاصه</h3>
                            <p>
                              النظافة الزراعية والتخلص من الحشائش مع استخدام
                              المصائد الزرقاء اللاصقة للكشف عن وجود الآفة
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1329</td>
                                  <td dir="RTL">
                                    رادينت 12% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Spinetoram<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    100 سم مكعب /<span>&nbsp; </span>فدان
                                  </td>
                                  <td dir="LTR">6</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2-الذبابة البيضاء*/}
                      <Collapse title="2-الذبابة البيضاء">
                        {/* <h2 className="details-page-title subTitle">
                          2-الذبابة البيضاء
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/06/zobaba.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                وجود الحشرات الكاملة على السطح السفلى للأوراق
                                وتظهر بقع صفراء عند بدء الإصابة ناتجة عن الثقب
                                والإمتصاص يتبعها إصفرار وتجعد الأوراق نتيجة
                                الإصابة الفيروسية - ومع تقدم الإصابة يحدث تقزم
                                للنباتات وخشونة الأوراق وسهولة تقصفها ويتبع ذلك
                                قلة المحصول - كما تظهر الندوة العسلية عند شدة
                                الإصابة.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              في العروة الصيفية المتأخرة وتشتد في العروة النيلية
                              بداية من شهر أغسطس وحتى شهر ديسمبر
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند إكتشاف الإصابة بالحوريات على الأوراق أو عند
                              إكتشاف عدد 2 حشرة كاملة/نبات في النباتات التي يتم
                              فحصها عشوائيا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1003
                                  </td>
                                  <td dir="RTL" align="right">
                                    اكتارا 25% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Thiamethoxam
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">80 جم / فدان</td>
                                  <td dir="LTR">9</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 3- المن  */}
                      <Collapse title="3- المن  ">
                        {/* <h2 className="details-page-title subTitle">
                          3- المن 
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/06/mn.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تجعد الأوراق والقمم النامية - ظهور بقع صفراء على
                                الأوراق ثم ذبولها - تشوه النباتات وخاصة القمم
                                النامية والأوراق الصغيرة السن - ظهور الأمراض
                                الفيروسية وأنتشارها
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>شهري مارس وابريل – شهري أغسطس وسبتمبر</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وجود متوسط من 1- 2 فرد للورقة في الأوراق التي
                              يتم فحصها عشوائيا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">إرشادات خاصة</h3>
                            <p>
                              تتم المعاملة رشا علي النباتات مع تغطية النباتات
                              المعاملة تغطية كاملة بسائل الرش
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2362
                                  </td>
                                  <td dir="RTL" align="right">
                                    سوبر توكس - 1 48% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Clothianidin
                                  </td>
                                  <td dir="RTL">
                                    200 سم مكعب /<span>&nbsp; </span>فدان
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1954
                                  </td>
                                  <td dir="RTL" align="right">
                                    تيدو 50% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Pymetrozine
                                  </td>
                                  <td dir="RTL">
                                    50 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 4- البياض الدقيقي*/}
                      <Collapse title="4- البياض الدقيقي">
                        {/* <h2 className="details-page-title subTitle">
                          4- البياض الدقيقي
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/06/byad.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                بقع صفراء اللون على السطح العلوي للورقة يقابلها
                                على السطح السفلى نموات زغبية بيضاء اللون - تتحول
                                الأوراق إلى اللون البني وتموت عند إشتداد الإصابة
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              في حالة توافر الظروف البيئية المناسبة لظهور
                              الإصابة
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بداية ظهور الأعراض</p>
                          </li>
                          <li>
                            <h3 className="h5">إرشادات خاصة</h3>
                            <p>
                              لابد أن يغطى محلول الرش السطحين العلوى والسفلى
                              للأوراق
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2564
                                  </td>
                                  <td dir="RTL" align="right">
                                    اتريو 80% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Tebuconazole<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    20 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">4</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1856
                                  </td>
                                  <td dir="RTL" align="right">
                                    اوبن 72% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Chlorothalonil<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2395
                                  </td>
                                  <td dir="RTL" align="right">
                                    باستيل 56% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Azoxystrobin
                                    <span>&nbsp;</span>
                                    <br />
                                    Chlorothalonil<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2613
                                  </td>
                                  <td dir="RTL" align="right">
                                    فابولوس 75% WP
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Chlorothalonil
                                    <span>&nbsp; </span>
                                    <br />
                                    Tebuconazole<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    75 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
