const months = {
  '01': 'يناير',
  '02': 'فبراير',
  '03': 'مارس',
  '04': 'إبريل',
  '05': 'مايو',
  '06': 'يونيو',
  '07': 'يوليو',
  '08': 'أغسطس',
  '09': 'سبتمبر',
  10: 'أكتوبر',
  11: 'نوفمبر',
  12: 'ديسمبر',
};

export { months };
