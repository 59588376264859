import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
// import { Button, Collapse, UncontrolledCollapse } from 'reactstrap';
import Collapse from './collapse/collapse';

function Index() {
  const data = {
    labels: ['2015', '2016', '2017', '2018', '2019'],
    datasets: [
      {
        label: 'المواسم',
        data: [91.036, 138.709, 162.115, 140.483, 512.096],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };

  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [0.94, 3909.138],
            offset: [5],
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
  };

  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/01/main.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  الرمان
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    هو فاكهة فائقة الفائدة لصحة الإنسان لإحتوائها على فيتامين ج
                    وحمض البونيك ومضادات الأكسدة ويدخل في تصنيع العصائر
                    والمكملات الغذائية يحظى محصول الرمان المصرى بأهمية بالغة على
                    مستوى التعاقدات الدولية بسبب ارتفاع جودته، وتنظيم العملية
                    التصديرية له . وساهم ارتفاع الطلب على الرمان المصرى فى رفع
                    أسعاره لمستويات قياسية، خصوصاً فى أسواق أوروبا وروسيا،
                    والدول العربية فى مقدمتها العراق . و طبقا لتقارير أكدت بأن
                    محافظة أسيوط هي أعلى محافظة من حيث التصدير للرمان في مصر .
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات الرمان من مصر
                    </h3>
                  </div>

                  <p>
                    حجم صادرات الرمان المصري
                    <Bar
                      data={data}
                      options={{
                        barPercentage: 0.4,
                        hoverBackgroundColor: '#071327',
                        scales: {
                          y: {
                            title: {
                              display: true,
                              text: 'الكمية بالأف طن',
                              padding: 5,
                              font: {
                                size: 20,
                              },
                            },
                          },
                        },
                      }}
                    />
                  </p>

                  {/* <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات الرمان المصري مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%0.02</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات الرمان المصري
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row> */}

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- المن في الرمان  */}
                      <Collapse title="1- المن في الرمان">
                        {/* <h2 className="details-page-title subTitle">
                          1- المن في الرمان 
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/01/mn.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                يصيب المن الأوراق والأفرع الغضة الحديثة فيؤدى
                                إلى تجعد وإنحناء حواف الأوراق لأسفل - إفراز مادة
                                عسلية تلتصق بها الأتربة وينمو عليها العفن الأسود
                                وباشتداد الإصابة يتغير لون الأوراق للون الأسود
                                وتضعف الثمرة وتكون ثمارا صغيرة الحجم
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p> من شهر ابريل حتى شهر سبتمبر</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p> عند بدء ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <p>
                              - إزالة الحشائش وإجراء التقليم الجيد والمناسب الذى
                              يسمح بالتهوية والاعتدال فى التسميد الأزوتى
                            </p>
                            <p>
                              - الرش بالزيوت بصفة دورية وكذلك الرش بالصابون
                              الزراعى لغسيل النباتات وإزالة اثار المن اول بأول
                              مع إستخدام المصائد اللاصقة
                            </p>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1091</td>
                                  <td dir="RTL">
                                    أوشين 20% SG <br />
                                  </td>
                                  <td dir="LTR">
                                    Dinotefuran
                                    <br />
                                  </td>
                                  <td dir="RTL">
                                    50 جم / 100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1003</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>اكتارا 25% WG
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Thiamethoxam
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    40 جم/ 100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2236</td>
                                  <td dir="RTL">
                                    كوبرا 20% SL <br />
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    100سم/ 100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2- العنكبوت الأحمر */}
                      <Collapse title="2- العنكبوت الأحمر">
                        {/* <h2 className="details-page-title subTitle">
                          2- العنكبوت الأحمر 
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/01/3enkbot.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهر بقع صفراء على الأوراق مستديرة وتتحول
                                الأوراق إلى اللون البرنزي ومع اشتداد الإصابة
                                تتحول الورقة إلى اللون البنى ومن أخطر الإصابات
                                إصابات الأزهار حيت تحدث الإصابة ذبولا وسقوطا
                                للزهرة مما يؤدى لعدم إتمام العقد.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>من شهر إبريل حتى شهر يوليو</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>تبدأ المكافحة في الشتاء</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <p>
                              - إزالة الحشائش وإجراء التقليم الجيد والمناسب الذى
                              يسمح بالتهوية والاعتدال فى التسميد الأزوتى
                            </p>
                            <p>
                              - رش الزيوت المعدنية والكبريت الميكرونى بصفة دورية
                              وكذلك الرش بالصابون الزراعى لغسيل النباتات
                            </p>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">174</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كزد اويل 95% EC
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Mineral oil
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    1.5 لتر/ 100لتر ماء ( رش شتوي )<br />
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1159</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>اورتس سوبر 5% EC
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Fenpyroximate
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    50 سم/ 100لتر ماء
                                    <br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 3- التربس */}
                      <Collapse title="3- التربس">
                        {/* <h2 className="details-page-title subTitle">
                          3- التربس
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/01/trbs.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تهاجم الأفرع الغضة والأوراق حديثة النمو وكذلك
                                الأزهار والعقد الصغيرة للثمار وتسبب تساقط
                                الأزهار وكذلك الثمار حديثة العقد كما تسبب تشوه
                                في القشرة الخارجية للثمار مما يقلل تسويقها ويؤدى
                                إلى تشققها عند زيادة حجمها
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>من شهر مارس حتى شهر يونيو</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p> عند بدء ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <p>
                              - نظافة الحقل والتخلص من الحشائش في داخل الحقول
                              والحقول المجاورة والإعتدال فى الرى والتسميد
                            </p>
                            <p>
                              - إستخدام المصائد الصفراء اللاصقة لتخفيف الإصابة.
                              الرش الدورى بالصابون البوتاسى بمعدل 1.5 لتر / 100
                              ماء
                            </p>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1091</td>
                                  <td dir="RTL">
                                    أوشين 20% SG <br />
                                  </td>
                                  <td dir="LTR">
                                    Dinotefuran
                                    <br />
                                  </td>
                                  <td dir="RTL">
                                    50 جم / 100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2236</td>
                                  <td dir="RTL">
                                    كوبرا 20% SL <br />
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    100سم/ 100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 4- ذبابة الرمان البيضاء  */}
                      <Collapse title="4- ذبابة الرمان البيضاء ">
                        {/* <h2 className="details-page-title subTitle">
                          4- ذبابة الرمان البيضاء 
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/01/zobaba.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                إصفرار وتجعد وإنحناء حواف الأوراق لأسفل. مشاهدة
                                الأطوار الكاملة للحشرة يصاحبها العفن الأسود. في
                                حالة شدة الإصابة توجد الأفراد على الثمار ويتكون
                                معها ندوة عسلية وتشوه الشكل الخارجي للثمرة. وصغر
                                حجمها وعدم نضجها
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>من شهر يوليو حتى شهر أكتوبر</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <p>
                              - نظافة الحقل والتخلص من الحشائش في داخل الحقول
                              والحقول المجاورة والإعتدال فى الرى والتسميد
                            </p>
                            <p>
                              - إستخدام المصائد الصفراء اللاصقة لتخفيف الإصابة.
                              الرش الدورى بالصابون البوتاسى بمعدل 1.5 لتر / 100
                              ماء
                            </p>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1091</td>
                                  <td dir="RTL">
                                    أوشين 20% SG <br />
                                  </td>
                                  <td dir="LTR">
                                    Dinotefuran
                                    <br />
                                  </td>
                                  <td dir="RTL">
                                    50 جم / 100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2236</td>
                                  <td dir="RTL">
                                    كوبرا 20% SL <br />
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    100سم/ 100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2124</td>
                                  <td dir="RTL">جنرال 10% EC</td>
                                  <td dir="LTR">Pyriproxyfen</td>
                                  <td dir="RTL">
                                    75 سم /100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 5- الحشرات القشرية والبق الدقيقى */}
                      <Collapse title="5- الحشرات القشرية والبق الدقيقى">
                        {/* <h2 className="details-page-title subTitle">
                          5- الحشرات القشرية والبق الدقيقى
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/01/twoinone.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تصيب الحشرات القشرية والبق الدقيقى الأوراق
                                والافرع والثمار فيحدث تشوه فى شكل الثمار ويصغر
                                حجمها - كذلك وجود بقع باهته حول أماكن إمتصاص
                                العصارة - إصفرار وذبول الأوراق وتساقطها
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>طوال العام وتقل خلال الشتاء</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <p>
                              - التخلص من الحشائش والإعتدال فى التسميد الأزوتى
                              وضرورة رعاية الأشجار وفحصها حتى بعد الجمع
                            </p>
                            <p>
                              - الاهتمام برش الزيوت المعدنية الشتوية على الأخشاب
                              بعد التقليم
                            </p>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">2124</td>
                                  <td dir="RTL">جنرال 10% EC</td>
                                  <td dir="LTR">Pyriproxyfen</td>
                                  <td dir="RTL">
                                    75 سم /100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">236</td>
                                  <td dir="RTL">مصرونا 85% مايونيز</td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Mineral oil
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    2 لتر/ 100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">174</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كزد اويل 95% EC
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Mineral oil
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    1.5لتر/ 100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1003</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>اكتارا 25% WG
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Thiamethoxam
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    25 جم/ 100 لتر ماء
                                    <br />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*6- حفار ساق الرمان */}
                      <Collapse title="6- حفار ساق الرمان">
                        {/* <h2 className="details-page-title subTitle">
                          6- حفار ساق الرمان
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/01/7afar.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهر الاعراض على هيئة وجود ثقوب على جذوع الأشجار
                                والأفرع الرئيسية مع ملاحظة خروج نشارة الخشب على
                                الأرض و الأفرع نتيجة قرض اليرقات محدثة ثقوبا
                                تمتد داخل الجذوع و الأفرع الرئيسية مما يعرض
                                الأفرع للكسر عند هبوب الرياح.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p> من شهر فبراير حتى شهر سبتمبر </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور الإصابة </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <p>
                              - بإدخال سلك داخل الثقب لقتل اليرقة داخل النفق ثم
                              اغلاقه
                            </p>
                            <p>
                              - تقليم الافرع الميته الجافة وتحرق بما فيها من
                              يرقات.او الحق بأحد المبيدات بواسطة الرشاشة الصعيرة
                            </p>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1822</td>
                                  <td dir="RTL">شوت 2.5% EC</td>
                                  <td dir="LTR">Deltamethrin</td>
                                  <td dir="RTL">200 سم/ 100لتر ماء</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*7- سوسة القلف*/}
                      <Collapse title="7- سوسة القلف">
                        {/* <h2 className="details-page-title subTitle">
                          7- سوسة القلف
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/01/sosa.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تتواجد اليرقات تحت قلف الشجرة ويحدث عفن فى مكان
                                الإصابة - إصفرار الأوراق ويحدث ذلك فى فرع او
                                أكثر وقد يحدث للشجرة كلها نتيجة إصابة الأشجار فى
                                منطقة التاج وقاعدة الساق والتى تعمل فصل للقشرة.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p> من فبراير حتى ديسمبر</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <p>
                              - التخلص من الحشائش والحفاظ على جذع الشجرة جافة مع
                              زراعة الاشجار على مصاطب
                            </p>
                            <p>
                              -عدم ترك جروح دون تغطيتها بمخلوط بوردو والزيوت
                              المعدنية خاصة بعد التقليم
                            </p>
                            <p>
                              - عدم إجراء أى تقليم او إزالة السرطانات من أغسطس
                              وحتى بداية ديسمبر
                            </p>
                            <p>
                              - أزالة القشور المصابة ورش الجذع فقط بأي من
                              المبيدات
                            </p>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1822</td>
                                  <td dir="RTL">شوت 2.5% EC</td>
                                  <td dir="LTR">Deltamethrin</td>
                                  <td dir="RTL">200 سم/ 100لتر ماء</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*8- دودة ثمار الرمان ( النبرة )*/}
                      <Collapse title="8- دودة ثمار الرمان ( النبرة )">
                        {/* <h2 className="details-page-title subTitle">
                          8- دودة ثمار الرمان ( النبرة )
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/01/doda.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                {' '}
                                وجود ثمار متساقطة تحت الاشجار- تحول منطقة
                                الاصابة الى اللون البني - خروج عصارة لزجة ذات
                                لون بني محمر من الثقب الذي تحدثه اليرٌقة عند
                                دخولها الى الثمرة - وجود ثقوب واضحة على الثمار
                                نتيجٌة دخول اليرٌقات - تلف جزء كبيرٌمن الثمرة
                                بسبب الإصابة بالفطرياٌت الرميةٌ وحشرات التخمر
                                مما يؤٌدي الى تعفن الثمرة بالكامل عند تقدم
                                الإصابة.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              بداية شهر مايو بعد سقوط البتلات حتى شهر سبتمبر
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وجود 5 أفراد على الورقة أو الثمرة من مجموعة
                              الأوراق أو الثمار التي يتم فحصها عشوائيا.
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <p>
                              - جمع الثمار المصابة والمتساقطة تحت الاشجار وتلك
                              المتبقيةٌ على الاشجار وإتلافها لأنها تعتبر مصدر
                              للإصابة الحديثٌة في الموسم القادم ويتم التخلص من
                              الثمار المصابة عن طريق حرقها في حفرة او وضعها في
                              كيس بلاستيك محكم القفل ورشها بأى مبيد داخل الكيس
                              ووضعها في الشمس لمدة كافية حتى تموت جميع اليرقات
                              داخل الثمار.
                            </p>
                            <p>
                              - جمع الثمار المصابة والمخلفات وفرمها في مفرمة ثم
                              وضعها في حفرة وتغطيتها بالتربة وتحويلها إلى
                              كومبوست يمكن الإستفادة منه في تسميد الأشجار
                            </p>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1822</td>
                                  <td dir="RTL">شوت 2.5% EC</td>
                                  <td dir="LTR">Deltamethrin</td>
                                  <td dir="RTL">200 سم/ 100لتر ماء</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1245</td>
                                  <td dir="RTL">دايبل دى اف 6.4% DF</td>
                                  <td dir="LTR">Bacillus thuringiensis </td>
                                  <td dir="RTL">200 جم/100لتر ماء</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1426</td>
                                  <td dir="RTL"> اكسلنت 1.9% EC</td>
                                  <td dir="LTR">Emamectin benzoate </td>
                                  <td dir="RTL">75 سم/ 100لتر ماء</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">609</td>
                                  <td dir="RTL">ماتش 5% EC</td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">40 سم/ 100 لتر ماء</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*9- النيماتودا*/}
                      <Collapse title="9- النيماتودا">
                        {/* <h2 className="details-page-title subTitle">
                          9- النيماتودا
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/01/nematoda.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تتطفل النيماتودا على جذور الأشجار فتؤدى إلى عجز
                                وتوقف النمو وتقزم الشجرة - كما يوجد أنواع تؤدى
                                إلى تكوين عقد جذرية مما يعيق إمتصاص الماء من
                                التربة وتكون بداية أعراضها إصفرار القمة النامية
                                للشجرة - تتواجد اليرقات تحت قلف الشجرة ويحدث عفن
                                فى مكان الإصابة - إصفرار الأوراق ويحدث ذلك فى
                                فرع او أكثر وقد يحدث للشجرة كلها نتيجة إصابة
                                الأشجار فى منطقة التاج وقاعدة الساق والتى تعمل
                                فصل للقشرة.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p> طول العام</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p> عند بدء ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <p>
                              - استخدام اسمدة عضوية متحللة وخالية من الأمراض
                              والنيماتودا
                            </p>
                            <p>
                              - عدم الإسراف في عملية الري وعمل مصطبة حول الشجرة
                            </p>
                            <p>
                              - التخلص من الحشائش والمخلفات النباتية مع الفحص
                              الدورى للجذور
                            </p>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">60</td>
                                  <td dir="RTL">نيماكور10% GR</td>
                                  <td dir="LTR">Fenamiphos</td>
                                  <td dir="RTL">25 كجم/ فدان</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1442</td>
                                  <td dir="RTL"> نيمافوس 40% EC</td>
                                  <td dir="LTR">Fenamiphos</td>
                                  <td dir="RTL">1 لتر/ 100لتر ماء</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">122</td>
                                  <td dir="RTL"> فايديت 24% SL</td>
                                  <td dir="LTR">Oxamyl</td>
                                  <td dir="RTL">1.5 لتر/ 100لتر ماء</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 10-  اعفان الجذور  */}
                      <Collapse title="10-  اعفان الجذور ">
                        {/* <h2 className="details-page-title subTitle">
                          10-  اعفان الجذور 
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/01/23fanelgzor.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                إصفرار الأوراق ثم جفافها وسقوطها وفي مراحل
                                متقدمة نشاهد ثمار جافة على الفروع - فى حالة شدة
                                الإصابة تجف الاشجار وتموت خلال بضعة شهور-عند قطع
                                الفرع الرئيسي أو الجذع يمكن أن نرى تحول الخشب
                                إلى اللون البني ومن ثم إلى الأسود - المرض يظهر
                                خاصة في التربة الطينية الثقيلة ويزداد حدته مع
                                إزدياد الرطوبة وقلة التهوية
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              يبدأ المرض في الظهور في فصل الربيع وبداية الصيف
                              عندما يزداد حمل الثمار على الاشجار
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p> عند بدء ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <p>- انتظام الرى</p>
                            <p>
                              - عدم زراعة المحاصيل الشتوية كالبرسيم خلال فترة
                              الشتاء
                            </p>
                            <p>- زراعة الأشجار على مصاطب.</p>
                            <p>
                              - إستخدام أسمدة عضوية متحللة وخالية من الأمراض
                              والنيماتودا
                            </p>
                            <p> - عدم الإسراف في عملية الري</p>
                            <p>
                              - التخلص من الحشائش والمخلفات النباتية مع الفحص
                              الدورى للجذور
                            </p>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1452</td>
                                  <td dir="RTL"> اكوتوب 85% WG</td>
                                  <td dir="LTR">
                                    <br />
                                    Thiophanate-methyl{' '}
                                  </td>
                                  <td dir="RTL">65 جم/ 100 لتر</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
