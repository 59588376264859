import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
// import { Button, Collapse, UncontrolledCollapse } from 'reactstrap';
import CollapseNo from './collapse/collapseNo';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

function Index() {
  const data = {
    labels: ['2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'المواسم',
        data: [1542111, 1563687, 1644210, 1711200, 1747714],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };

  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [6461208, 45540000],
            offset: [5],

            // tooltip: {
            //   callbacks: {
            //     label: function (context) {
            //       let label = context.label;
            //       let value = context.formattedValue;

            //       if (!label) label = 'Unknown';

            //       let sum = 0;
            //       let dataArr = context.chart.data.datasets[0].data;
            //       dataArr.map((data) => {
            //         sum += Number(data);
            //       });

            //       const x = value.replace(/[,]/g, '');
            //       let percentage = ((x * 100) / sum).toFixed(2) + '%';
            //       return label + ': ' + percentage;
            //     },
            //   },
            // },
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
        // datalabels: {
        //   formatter: (value, dnct1) => {
        //     let sum = 0;
        //     let dataArr = dnct1.chart.data.datasets[0].data;
        //     dataArr.map((data) => {
        //       sum += data;
        //     });
        //     let percentage = ((value * 100) / sum).toFixed(2) + '%';
        //     return percentage;
        //   },
        //   display: true,
        //   color: 'black',
        //   align: 'end',
        //   // rotation: [10, 20],
        //   padding: {
        //     right: 200,
        //   },
        //   textAlign: 'center',
        //   labels: {
        //     padding: { top: 10 },
        //     title: {
        //       font: {
        //         weight: 'bold',
        //       },
        //     },
        //     value: {
        //       color: 'green',
        //     },
        //   },
        //   // align: 'center',
        //   // anchor: 'right',
        //   // offset: [10, 6],
        //   // textAlign: 'end',
        //   // padding: {
        //   //   right: [5, 10],
        //   // },
        // },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
    // plugins: [ChartDataLabels],
  };
  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/00/00.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  التمر المصري
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    {' '}
                    لقد أصبحت مصر من أهم الدول في إنتاج التمور وزراعة النخيل
                    فأصبحت الأولى عالميا في هذا النوع من الإنتاج، واشتهرت
                    محافظات في مصر بإنتاج التمور عالية الجودة وتصديرها إلى
                    الخارج أيضا.حيث اتخذت الدولة المصرية عدة إجراءات وخطوات
                    ليكون التمر المصري منافسا في الخارج. وتدل الأرقام على تطور
                    مصر في التمور والنخيل، إذ تنتج 1.6 مليون طن سنويا من التمور
                    تحتل بها المركز الأول عالميا نبسبة تبلغ 21% من الإنتاج
                    العالمي حسبما جاء في أطلس نخيل البلح والتمور في مصر الذي
                    أصدرته منظمة فاو. ويتم التصدير من خلال 15 مليون نخلة معظمها
                    في محافظات الوادي الجديد، أسوان، الجيزة، الشرقية، البحيرة،
                    دمياط، مطروح، وشمال سيناء، ويتم التصدير إلى 63 دولة تتقدمها
                    إندونيسيا والمغرب وماليزيا وبنجلادش وتايلاند. وتنتج مصر 23
                    صنفا من التمور منها ما تؤكل ثمارها وهي طرية كالأمهات
                    والزغلول والحياني والسلماني وبنت عيشة ومنها الأصناف النصف
                    جافة التي لا تؤكل إلا بعد تجفيفها أو تصنيعها وأشهرها السيوي
                    والعجلاني.
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم إنتاج التمر من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة إنتاج التمر المصري مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%14.19</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://www.fao.org/faostat/en/#rankings/countries_by_commodity"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          منظمة الأغذية و الزراعة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم إنتاج التمر المصري
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginBlock: '1em' }}>
                    <iframe
                      src="https://www.youtube.com/embed/xCZ0H8Kv30A"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                      allowfullscreen
                      title="mahaseelmasr dates"
                      style={{ aspectRatio: '16 / 9', width: '100%' }}
                    ></iframe>
                  </Row>

                  <div className="blog-quote">
                    <h3 className="details-page-title">الأخبار</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- البق الدقيقى */}
                      <CollapseNo
                        title="1-  مصر تمتلك إمكانيات كبيرة فى إنتاج التمور"
                        url="https://www.youm7.com/story/2022/10/8/%D8%A7%D9%84%D8%B5%D9%86%D8%A7%D8%B9%D8%A9-%D9%85%D8%B5%D8%B1-%D8%AA%D9%85%D8%AA%D9%84%D9%83-%D8%A5%D9%85%D9%83%D8%A7%D9%86%D9%8A%D8%A7%D8%AA-%D9%83%D8%A8%D9%8A%D8%B1%D8%A9-%D9%81%D9%89-%D8%A5%D9%86%D8%AA%D8%A7%D8%AC-%D8%A7%D9%84%D8%AA%D9%85%D9%88%D8%B1-%D9%88%D8%AA%D8%AD%D8%AA%D9%84-%D8%A7%D9%84%D9%85%D8%B1%D8%AA%D8%A8%D8%A9/5933295"
                      />
                      <CollapseNo
                        title="2- انطلاق مهرجان القاهرة الدولى للتمور"
                        url="https://www.youm7.com/story/2023/3/3/%D8%A7%D9%86%D8%B7%D9%84%D8%A7%D9%82-%D9%85%D9%87%D8%B1%D8%AC%D8%A7%D9%86-%D8%A7%D9%84%D9%82%D8%A7%D9%87%D8%B1%D8%A9-%D8%A7%D9%84%D8%AF%D9%88%D9%84%D9%89-%D9%84%D9%84%D8%AA%D9%85%D9%88%D8%B1-%D9%81%D9%89-%D8%AD%D8%AF%D9%8A%D9%82%D8%A9-%D8%A7%D9%84%D8%A3%D9%88%D8%B1%D9%85%D8%A7%D9%86-%D8%B1%D8%A6%D9%8A%D8%B3-%D9%82%D8%B7%D8%A7%D8%B9/6101655"
                      />
                      <CollapseNo
                        title="3- محافظ مطروح يتابع ختام مهرجان التمور الدولى"
                        url="https://m.akhbarelyom.com/news/NewDetails/3653140/1/%D9%85%D8%AD%D8%A7%D9%81%D8%B8-%D9%85%D8%B7%D8%B1%D9%88%D8%AD-%D9%8A%D9%83%D8%B4%D9%81-%D9%83%D9%88%D8%A7%D9%84%D9%8A%D8%B3-%D8%A5%D9%82%D8%A7%D9%85%D8%A9-%D9%85%D9%87%D8%B1%D8%AC%D8%A7%D9%86-%D8%A7%D9%84%D8%AA"
                      />
                      <CollapseNo
                        title="4- «مهرجان التمور» .. أسعار تنافسية لمواجهة الغلاء"
                        url="https://www.almasryalyoum.com/news/details/2838240"
                      />
                      <CollapseNo
                        title="5- تعرف على حجم إنتاج مصر من التمور"
                        url="https://www.youm7.com/story/2022/12/7/%D8%AA%D8%B9%D8%B1%D9%81-%D8%B9%D9%84%D9%89-%D8%AD%D8%AC%D9%85-%D8%A5%D9%86%D8%AA%D8%A7%D8%AC-%D9%85%D8%B5%D8%B1-%D9%85%D9%86-%D8%A7%D9%84%D8%AA%D9%85%D9%88%D8%B1/5919282"
                      />
                      <CollapseNo
                        title="6- إنفوجراف| مصر الأولى عالميا في إنتاج التمور"
                        url="https://m.akhbarelyom.com/news/newdetails/4049049/1/%D8%A5%D9%86%D9%81%D9%88%D8%AC%D8%B1%D8%A7%D9%81-%D9%85%D8%B5%D8%B1-%D8%A7%D9%84%D8%A3%D9%88%D9%84%D9%89-%D8%B9%D8%A7%D9%84%D9%85%D9%8A%D8%A7-%D9%81%D9%8A-%D8%A5%D9%86%D8%AA%D8%A7%D8%AC-%D8%A7%D9%84%D8%AA%D9%85"
                      />
                      <CollapseNo
                        title="7- المهرجان الدولي السادس للتمور المصرية.. رعاية دولية وعشرات الهيئات والشركات"
                        url="https://www.foodtodayeg.com/Section-163/%D8%B5%D9%86%D8%A7%D8%B9%D8%A7%D8%AA-%D8%BA%D8%B0%D8%A7%D8%A6%D9%8A%D8%A9/%D8%A7%D9%84%D9%85%D9%87%D8%B1%D8%AC%D8%A7%D9%86-%D8%A7%D9%84%D8%AF%D9%88%D9%84%D9%8A-%D8%A7%D9%84%D8%B3%D8%A7%D8%AF%D8%B3-%D9%84%D9%84%D8%AA%D9%85%D9%88%D8%B1-%D8%A7%D9%84%D9%85%D8%B5%D8%B1%D9%8A%D8%A9-%D8%B1%D8%B9%D8%A7%D9%8A%D8%A9-%D8%AF%D9%88%D9%84%D9%8A%D8%A9-%D9%88%D8%B9%D8%B4%D8%B1%D8%A7%D8%AA-%D8%A7%D9%84%D9%87%D9%8A%D8%A6%D8%A7%D8%AA-%D9%88%D8%A7%D9%84%D8%B4%D8%B1%D9%83%D8%A7%D8%AA-18371"
                      />
                      <CollapseNo
                        title="8- انطلاق مهرجان القاهرة الدولى للتمور فى حديقة الأورمان"
                        url="https://www.youm7.com/story/2023/3/3/%D8%A7%D9%86%D8%B7%D9%84%D8%A7%D9%82-%D9%85%D9%87%D8%B1%D8%AC%D8%A7%D9%86-%D8%A7%D9%84%D9%82%D8%A7%D9%87%D8%B1%D8%A9-%D8%A7%D9%84%D8%AF%D9%88%D9%84%D9%89-%D9%84%D9%84%D8%AA%D9%85%D9%88%D8%B1-%D9%81%D9%89-%D8%AD%D8%AF%D9%8A%D9%82%D8%A9-%D8%A7%D9%84%D8%A3%D9%88%D8%B1%D9%85%D8%A7%D9%86-%D8%B1%D8%A6%D9%8A%D8%B3-%D9%82%D8%B7%D8%A7%D8%B9/6101655"
                      />
                      <CollapseNo
                        title="9- 3 وزراء في افتتاح المهرجان الثاني للتمور بالوادي الجديد"
                        url="https://agricultureegypt.com/News/39749/_3_%D9%88%D8%B2%D8%B1%D8%A7%D8%A1_%D9%81%D9%8A_%D8%A7%D9%81%D8%AA%D8%AA%D8%A7%D8%AD_%D8%A7%D9%84%D9%85%D9%87%D8%B1%D8%AC%D8%A7%D9%86_%D8%A7%D9%84%D8%AB%D8%A7%D9%86%D9%8A_%D9%84%D9%84%D8%AA%D9%85%D9%88%D8%B1_%D8%A8%D8%A7%D9%84%D9%88%D8%A7%D8%AF%D9%8A_%D8%A7%D9%84%D8%AC%D8%AF%D9%8A%D8%AF/"
                      />
                      <CollapseNo
                        title="10- أصناف التمور في مصر (مقال) "
                        url="http://www.vercon.sci.eg/indexUI/uploaded/toumour855/toumour.htm"
                      />
                      <CollapseNo
                        title="11- أكبر مزرعة إنتاج من نوعها بمساحة 40 ألف فدان (مقال) "
                        url="https://egy-map.com/project/%D8%A3%D9%83%D8%A8%D8%B1-%D9%85%D8%B2%D8%B1%D8%B9%D8%A9-%D9%84%D9%84%D8%AA%D9%85%D9%88%D8%B1-%D9%81%D9%8A-%D8%A7%D9%84%D8%B9%D8%A7%D9%84%D9%85#:~:text=%D9%88%D9%8A%D8%B3%D8%AA%D9%87%D8%AF%D9%81%20%D8%A7%D9%84%D9%85%D8%B4%D8%B1%D9%88%D8%B9%20%D8%B2%D8%B1%D8%A7%D8%B9%D8%A9%202.5%20%D9%85%D9%84%D9%8A%D9%88%D9%86,%D8%A7%D9%84%D9%85%D8%B7%D9%84%D9%88%D8%A8%D8%A9%20%D8%AA%D8%B5%D8%AF%D9%8A%D8%B1%D9%8A%D8%A7%20%D9%85%D9%86%D9%87%D8%A7%20%D8%A7%D9%84%D9%85%D8%AC%D8%AF%D9%88%D9%84%20%D9%88%D8%A7%D9%84%D8%A8%D8%B1%D8%AD%D9%89"
                      />
                      <CollapseNo
                        title="12- مصر الأعلى فى إنتاج التمور عالمياً (فيديو) "
                        url="https://www.youtube.com/watch?v=ICTkngbbEQA&feature=youtu.be"
                      />
                      <CollapseNo
                        title="13- صباح الخير يا مصر | مهرجان التمور يفتتح أبوابه للجمهور في حديقة الأورمان (فيديو) "
                        url="https://www.youtube.com/watch?v=8ixFI6bIQ_I&feature=youtu.be"
                      />
                      <CollapseNo
                        title="14- «السياحة» تروج لمهرجان التمور بنشر فيديو عن المعالم الأثرية بواحة سيوة "
                        url="https://m.akhbarelyom.com/news/NewDetails/3647686/1/-%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%AD%D8%A9-%D8%AA%D8%B1%D9%88%D8%AC-%D9%84%D9%85%D9%87%D8%B1%D8%AC%D8%A7%D9%86-%D8%A7%D9%84%D8%AA%D9%85%D9%88%D8%B1-%D8%A8%D9%86%D8%B4%D8%B1-%D9%81%D9%8A%D8%AF%D9%8A%D9%88-"
                      />
                      <CollapseNo
                        title="15- الصناعة: مصر تمتلك إمكانيات كبيرة فى إنتاج التمور وتحتل المرتبة الأولى عالمياً"
                        url="https://www.youm7.com/story/2022/10/8/%D8%A7%D9%84%D8%B5%D9%86%D8%A7%D8%B9%D8%A9-%D9%85%D8%B5%D8%B1-%D8%AA%D9%85%D8%AA%D9%84%D9%83-%D8%A5%D9%85%D9%83%D8%A7%D9%86%D9%8A%D8%A7%D8%AA-%D9%83%D8%A8%D9%8A%D8%B1%D8%A9-%D9%81%D9%89-%D8%A5%D9%86%D8%AA%D8%A7%D8%AC-%D8%A7%D9%84%D8%AA%D9%85%D9%88%D8%B1-%D9%88%D8%AA%D8%AD%D8%AA%D9%84-%D8%A7%D9%84%D9%85%D8%B1%D8%AA%D8%A8%D8%A9/5933295"
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
