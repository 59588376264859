import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
import Collapse from './collapse/collapse';

function Index() {
  const data = {
    labels: ['2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'المواسم',
        data: [130.301, 120.265, 154.207, 103.751, 107.303],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };

  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [615.83, 24534.57],
            offset: [5],
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
  };

  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/04/044.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  العنب
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    تنتشر زراعة العنب في مصر جغرافيا من الإسكندرية شمالا إلى
                    أسوان جنوبا ، خاصة محافظات البحيرة والمنيا والدقهلية أصبحت
                    المراكز الرئيسية لإنتاج العنب ، ويبدأ موسم قطف العنب في مصر
                    في مايو وينتهي في سبتمبر من كل عام ، ويعتبر العنب من أكثر
                    محاصيل الفاكهة انتشارا في جميع أنحاء البلاد ، وتبلغ المساحة
                    المزروعة في مصر من العنب حوالى 14 % من إجمالي المساحة
                    المزروعة بالفاكهة المختلفة حيث يعتبر المحصول الثانى من حيث
                    الأهمية والمساحة حيث يحتل المرتبة الثانية بعد الحمضيات وشهدت
                    مصر في السنوات الأخيرة زيادة ملحوظة في زراعة وتصدير عنب
                    المائدة ، حيث ارتفع الطلب العالمي على ثمارها عالية الجودة
                    بشكل ملحوظ وبحسب تقرير فإن محافظة البحيرة ومدينة السادات
                    تمثلان الغالبية العظمى من صادرات العنب إلى الاتحاد الأوروبي
                    الذي يعد أكبر سوق لصادرات مصر من هذا المحصول . حيث تستحوذ
                    محافظة البحيرةعلى حوالي 40 % من إجمالي المساحة المزروعة
                    للعنب في مصر و 18 %من إجمالي الإنتاج
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات العنب من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات العنب المصري مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%2.51</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات العنب المصري
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- البق الدقيقى */}
                      <Collapse title="1- البق الدقيقى">
                        {/* <h2 className="details-page-title subTitle">
                          1- البق الدقيقى
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/04/bqdqeq.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ظهور حشرات بيضاء اللون على الورق والقلف. ثم يصبح
                                القلف سائبا ويتم تقشيره بسهولة مع تواجد الأفراد
                                تحت القلف ثم تظهر الإصابة بالعفن الأسود الذي
                                ينمو على الندوة العسلية وتغطى السيقان باللون
                                الأسود.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              يتواجد في الشتاء مختفيا تحت القلف في منطقة الجذع
                              من أسفل وفى الربيع تظهر الأفراد على السيقان وتتجه
                              إلى العناقيد صيفا
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور أعراض الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="RTL">1820</td>
                                  <td dir="RTL">اجرى فليكس 18.56% SC</td>
                                  <td dir="RTL">
                                    Abamectin
                                    <br />
                                    Thiamethoxam
                                  </td>
                                  <td dir="RTL">40سم/100 لتر ماء</td>
                                  <td dir="RTL">28 أوراق وثمار</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1775</td>
                                  <td dir="RTL">ايمى باور 35% SC</td>
                                  <td dir="RTL">Imidacloprid</td>
                                  <td dir="RTL">75سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    7 أوراق <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1148</td>
                                  <td dir="RTL">بست 25% WP</td>
                                  <td dir="RTL">Imidacloprid</td>
                                  <td dir="RTL">100جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1757</td>
                                  <td dir="RTL">موفينتو 10% SC</td>
                                  <td dir="RTL">Spirotetramat</td>
                                  <td dir="RTL">80سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2352</td>
                                  <td dir="RTL">كلوزر 24% SC</td>
                                  <td dir="RTL">Sulfoxaflor</td>
                                  <td dir="RTL">250سم/فدان</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2-التربس*/}
                      <Collapse title="2- التربس">
                        {/* <h2 className="details-page-title subTitle">
                          2- التربس
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/04/trbs.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظاهر الإصابة وتوقيتها</h3>
                              <p>
                                تصيب الحشرات الكاملة والحوريات الثمار الحديثة
                                والأوراق وتمتص عصارتها خلال فترة الصيف بسبب
                                التفاف الأوراق على طول العرق الوسطى وذبول
                                الأوراق الحديثة وجفافها وتجعدها وسقوطها بعد شهر
                                يونيو مما يسبب جفاف العناقيد الحديثة وموتها
                                وظهور تشوهات شبكية بين العقد على الأفرع وظهور
                                تشوهات على الثمار لونها بني فاتح
                              </p>
                            </div>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وصول الاعداد إلى 10 حورية وحشرة كاملة/ ورقة من
                              الاوراق التي تم فحصها عشوائيا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="RTL">3083</td>
                                  <td dir="RTL">بالداريو 10% EW</td>
                                  <td dir="RTL">Bifenthrin</td>
                                  <td dir="RTL">100سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    22 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 3- ذبابة الفاكهة */}
                      <Collapse title="3- ذبابة الفاكهة">
                        {/* <h2 className="details-page-title subTitle">
                          3- ذبابة الفاكهة
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/04/zobabtelfakha3nb.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة و توقيتها</h3>
                              <p>
                                وجود ندب داكنة اللون على سطح الثمرة المصابة يخرج
                                منها افراز صمغی وتحول منطقة الوخزالي منظقة رخوة
                                متخمرة مسببا العفن وسقوط الثمار تصيب الثمار قرب
                                النضج حيث تتلف الثمار و تؤدى إلى تعفنها
                              </p>
                            </div>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور أعراض الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="RTL">1344</td>
                                  <td dir="RTL">كونسرف 0.024% CB</td>
                                  <td dir="RTL">Spinosad</td>
                                  <td dir="RTL">
                                    500سم/4 لتر ماء
                                    <br />
                                    (رش جزئى)
                                  </td>
                                  <td dir="RTL">1 يوم للأوراق والثمار</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 4-دودة ثمار العنب */}
                      <Collapse title="4-  دودة ثمار العنب">
                        {/* <h2 className="details-page-title subTitle">
                          4- دودة ثمار العنب
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/04/doda.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تقوم اليرقة بعمل مجموعة من الخيوط الحريرية تربط
                                بها ثمار العنب في جميع أطوار النمو (براعم زهرية
                                - ثمار غير تامة النضج - ثمار تامة النضج) وتصنع
                                بذلك كتلة من الثمار تتغذى من داخلها اليرقة.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              بداية من شهر أبريل وحتى شهر يوليو ولها ثلاثة
                              أجيال. جيل أول على البراعم الزهرية والثاني على
                              الثمار غير تامة النضج والجيل الثالث وهو أخطرها على
                              الثمار تامة النضج
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور أعراض الإصابة.</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1253</td>
                                  <td dir="RTL">افانت 15% EC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">25 سم / 100 لتر ماء</td>
                                  <td dir="RTL">
                                    7 اوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1219</td>
                                  <td dir="RTL">السيستين 48% SC</td>
                                  <td dir="LTR">Triflumuron</td>
                                  <td dir="RTL">26.25 سم / 100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3052</td>
                                  <td dir="RTL">اندوكسى 30% SC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">14سم / 100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3115</td>
                                  <td dir="RTL">ادفانتدج 15% SC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">175سم / فدان</td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2646</td>
                                  <td dir="RTL">اييزو 30% WG</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2308</td>
                                  <td dir="RTL">بينزافاست 2.3% EC</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">40سم / 100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1057</td>
                                  <td dir="RTL">تريسر 24% SC</td>
                                  <td dir="LTR">Spinosad</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>
                                    20 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1245</td>
                                  <td dir="RTL">دايبل دى اف 6.4% DF</td>
                                  <td dir="LTR">Bacillus thuringiensis</td>
                                  <td dir="RTL">300 جم / فدان</td>
                                  <td dir="RTL">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1329</td>
                                  <td dir="RTL">رادينت 12% SC</td>
                                  <td dir="LTR">Spinetoram</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>
                                    20 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    7 اوراق
                                    <br />
                                    <span>&nbsp;</span>5<span>&nbsp;</span>
                                    ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1052</td>
                                  <td dir="RTL">رنر 24% SC</td>
                                  <td dir="LTR">Methoxyfenozide</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>
                                    25 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">7 اوراق و ثمار</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1606</td>
                                  <td dir="RTL">سايمكس 5% EC</td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">40سم / 100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2109</td>
                                  <td dir="RTL">سيجاتوب 5% EC</td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">40سم / 100 لتر ماء</td>
                                  <td dir="RTL">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3229</td>
                                  <td dir="RTL">جلامى 24% SC</td>
                                  <td dir="LTR">Methoxyfenozide</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>
                                    25 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2227</td>
                                  <td dir="RTL">غلورى 5% EC</td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">40سم / 100 لتر ماء</td>
                                  <td dir="RTL">
                                    12 اوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1597</td>
                                  <td dir="RTL">فلاكس 15% SC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>
                                    25 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1753</td>
                                  <td dir="RTL">فوليام فليكسى 40% WG</td>
                                  <td dir="LTR">
                                    Chlorantraniliprole
                                    <br />
                                    Thiamethoxam
                                  </td>
                                  <td dir="RTL">80 جم / فدان</td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2493</td>
                                  <td dir="RTL">كاستلو 10% EC</td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">
                                    <span>&nbsp; </span>
                                    20 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2791</td>
                                  <td dir="RTL">كامفال 15% EC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>
                                    25 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2575</td>
                                  <td dir="RTL">كان كون 40% SC</td>
                                  <td dir="LTR">Triflumuron</td>
                                  <td dir="RTL">
                                    <span>&nbsp; </span>
                                    30 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2284</td>
                                  <td dir="RTL">ميتارو 30% WG</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">
                                    13.5 جم /<span>&nbsp; </span>
                                    100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2705</td>
                                  <td dir="RTL">يماسون 15% EC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>
                                    25 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 5-	العنكبوت الأحمر*/}
                      <Collapse title="5- العنكبوت الأحمر">
                        {/* <h2 className="details-page-title subTitle">
                         5-	العنكبوت الأحمر
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/04/3enkbot.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                وجود بقع صفراء على السطح العلوي للأوراق وبتقدم
                                الإصابة تتحول إلى اللون البني ثم تجف الأوراق
                                وتسقط
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              مع بداية ظهور الأوراق في مارس، أبريل وتزداد
                              الإصابة تدريجيا حتى أغسطس وسبتمبر
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وجود 5 أفراد على السطح السفلي للورقة من مجموعة
                              الأوراق التي يتم فحصها عشوائيا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="RTL">2624</td>
                                  <td dir="RTL">اكس مايت 24% SC</td>
                                  <td dir="RTL">Chlorfenapyr</td>
                                  <td dir="RTL">60سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1159</td>
                                  <td dir="RTL">اورتس سوبر 5% EC</td>
                                  <td dir="RTL">Fenpyroximate</td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">14 أوراق وثمار</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2349</td>
                                  <td dir="RTL">بروكار 5% EC</td>
                                  <td dir="RTL">Hexythiazox</td>
                                  <td dir="RTL">40 سم/100 لتر ماء</td>
                                  <td dir="RTL">14</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2405</td>
                                  <td dir="RTL">تروجولد 5% SC</td>
                                  <td dir="RTL">Fenpyroximate</td>
                                  <td dir="RTL">75سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1391</td>
                                  <td dir="RTL">تينام 1.8% EC</td>
                                  <td dir="RTL">Abamectin</td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    12 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2532</td>
                                  <td dir="RTL">سولو 24% SC</td>
                                  <td dir="RTL">Bifenazate</td>
                                  <td dir="RTL">70سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    7 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1420</td>
                                  <td dir="RTL">شالنجر سوبر 24% SC</td>
                                  <td dir="RTL">Chlorfenapyr</td>
                                  <td dir="RTL">60سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    21 وراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2122</td>
                                  <td dir="RTL">فانتى 24% SC</td>
                                  <td dir="RTL">Chlorfenapyr</td>
                                  <td dir="RTL">360سم/فدان</td>
                                  <td dir="RTL">7</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2166</td>
                                  <td dir="RTL">فلو اب 20.8% SC</td>
                                  <td dir="RTL">
                                    Abamectin
                                    <br />
                                    Clofentezine
                                  </td>
                                  <td dir="RTL">150سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2320</td>
                                  <td dir="RTL">كونكورد 24% SC</td>
                                  <td dir="RTL">Chlorfenapyr</td>
                                  <td dir="RTL">60سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1548</td>
                                  <td dir="RTL">ماجنبفيكو 5% EC</td>
                                  <td dir="RTL">Hexythiazox</td>
                                  <td dir="RTL">40سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    7 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3362</td>
                                  <td dir="RTL">كلوم سوبر 24% SC</td>
                                  <td dir="RTL">Chlorfenapyr</td>
                                  <td dir="RTL">60سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    20 اوراق
                                    <br />
                                    15 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3114</td>
                                  <td dir="RTL">داستر 5% EC</td>
                                  <td dir="RTL">Hexythiazox</td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    7 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 6-	اعفان الثمار */}
                      <Collapse title="6- اعفان الثمار">
                        {/* <h2 className="details-page-title subTitle">
                          6-	اعفان الثمار
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/04/23fansmar.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                توقف نمو الحبات وتشقق غشائها - ظهور نموات مختلفة
                                الألوان تبعا للمسبب - تهتك غشاء الحبة وخروج
                                العصارة الداخلية مع تغير لونها.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>من أول يونية إلى نهاية الموسم </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور أعراض الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="RTL">1083</td>
                                  <td dir="RTL">باندل 8% SC</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">125سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    1 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2046</td>
                                  <td dir="RTL">برولكتس 50% WG</td>
                                  <td dir="RTL">Fenpyrazmine</td>
                                  <td dir="RTL">50جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1123</td>
                                  <td dir="RTL">بيليز 38% WG</td>
                                  <td dir="RTL">
                                    Boscalid
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">50جم/100 لتر ماء</td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1718</td>
                                  <td dir="RTL">بيوكونترول WP 12% T34</td>
                                  <td dir="RTL">Trichoderma asperellum</td>
                                  <td dir="RTL">85جم/100 لتر ماء</td>
                                  <td dir="RTL">-</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1617</td>
                                  <td dir="RTL">تريل اف 52% WP</td>
                                  <td dir="RTL">
                                    Benalaxyl
                                    <br />
                                    Folpet
                                  </td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">28</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">938</td>
                                  <td dir="RTL">تيلدور 50% SC</td>
                                  <td dir="RTL">Fenhexamid</td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">10</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2610</td>
                                  <td dir="RTL">روكانو 50% WG</td>
                                  <td dir="RTL">Boscalid</td>
                                  <td dir="RTL">100جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3276</td>
                                  <td dir="RTL">رينت 80% WG</td>
                                  <td dir="RTL">
                                    Azoxystrobin
                                    <br />
                                    Dimethomorph
                                  </td>
                                  <td dir="RTL">35جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3317</td>
                                  <td dir="RTL">سيبروف 62% WG</td>
                                  <td dir="RTL">
                                    Cyprodinil
                                    <br />
                                    Fludioxonil
                                  </td>
                                  <td dir="RTL">50جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1004</td>
                                  <td dir="RTL">سويتش 62.5% WG</td>
                                  <td dir="RTL">
                                    Cyprodinil
                                    <br />
                                    Fludioxonil
                                  </td>
                                  <td dir="RTL">50جم/100 لتر ماء</td>
                                  <td dir="RTL">21</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2976</td>
                                  <td dir="RTL">
                                    سكويز 38% WG
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    Boscalid
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">50جم/100 لتر ماء</td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2970</td>
                                  <td dir="RTL"> سكالو 40% SC</td>
                                  <td dir="RTL">Pyrimethanil</td>
                                  <td dir="RTL">250سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2464</td>
                                  <td dir="RTL">فيناميكس 50% SC</td>
                                  <td dir="RTL">Fenhexamid</td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1631</td>
                                  <td dir="RTL">مايستك 20% WP</td>
                                  <td dir="RTL">Pyrimethanil</td>
                                  <td dir="RTL">300سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3218</td>
                                  <td dir="RTL">ماغنوم 33.4% WG</td>
                                  <td dir="RTL">
                                    Boscalid
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">75جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    14 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1605</td>
                                  <td dir="RTL">كونترول 70% WG</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">100جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1796</td>
                                  <td dir="RTL">كانتوس 50% WG</td>
                                  <td dir="RTL">Boscalid</td>
                                  <td dir="RTL">100جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3378</td>
                                  <td dir="RTL">نيو - اكس 50% WG</td>
                                  <td dir="RTL">Boscalid</td>
                                  <td dir="RTL">100جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    12 ثمار
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*7-	البياض الدقيقي*/}
                      <Collapse title="7- البياض الدقيقي">
                        {/* <h2 className="details-page-title subTitle">
                         7-	البياض الدقيقي
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/04/buaddqeq.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                مسحوق أبيض دقيقي على جميع الأجزاء المصابة (أوراق
                                - أزهار - ثمار).
                              </p>
                            </div>
                          </li>

                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>عند خروج الأوراق والأزهار وعقد الثمار.</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور أعراض الإصابة.</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="RTL">603</td>
                                  <td dir="RTL">اتش-سلفر80% WP</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">5</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2744</td>
                                  <td dir="RTL">ارفيكا 30% SC</td>
                                  <td dir="RTL">
                                    Boscalid
                                    <br />
                                    Kresoxim-methyl
                                  </td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1674</td>
                                  <td dir="RTL">اسبت 25% EC</td>
                                  <td dir="RTL">Triadimenol</td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />1 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2730</td>
                                  <td dir="RTL">افوسان جولد 70% WP</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">80جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1610</td>
                                  <td dir="RTL">اكتاميل 70% WP</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">80جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1452</td>
                                  <td dir="RTL">اكوتوب 85% WG</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">65جم/100 لتر ماء</td>
                                  <td dir="RTL">14</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">980</td>
                                  <td dir="RTL">اكودال 80% WG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">200جم/100 لتر ماء</td>
                                  <td dir="RTL">7</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2260</td>
                                  <td dir="RTL">الترازول 10% EC</td>
                                  <td dir="RTL">Penconazole</td>
                                  <td dir="RTL">15سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1710</td>
                                  <td dir="RTL">اميستو25% SC</td>
                                  <td dir="RTL">Azoxystrobin</td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2058</td>
                                  <td dir="RTL">اندار 5% EW</td>
                                  <td dir="RTL">Fenbuconazole</td>
                                  <td dir="RTL">60سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1250</td>
                                  <td dir="RTL">انسف 80% WG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">200جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2186</td>
                                  <td dir="RTL">اونيل 25% EC</td>
                                  <td dir="RTL">Triadimenol</td>
                                  <td dir="RTL">35سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3032</td>
                                  <td dir="RTL">ارمر 70% WP</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">65جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    14 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2965</td>
                                  <td dir="RTL">اورورا 70% WP</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">80جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    14 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3331</td>
                                  <td dir="RTL">اوليمو 80% WDG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3060</td>
                                  <td dir="RTL">بروبى ماس 25% EC</td>
                                  <td dir="RTL">Propiconazole</td>
                                  <td dir="RTL">15سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    14 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1083</td>
                                  <td dir="RTL">باندل 8% SC</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">125سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    1 اوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2085</td>
                                  <td dir="RTL">بروجراب 25% EC</td>
                                  <td dir="RTL">Propiconazole</td>
                                  <td dir="RTL">15سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1885</td>
                                  <td dir="RTL">بروزين 70% WP</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">80جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2923</td>
                                  <td dir="RTL">برو سالفو 80% WDG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    7 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1761</td>
                                  <td dir="RTL">بلنت زول 10% EC</td>
                                  <td dir="RTL">Penconazole</td>
                                  <td dir="RTL">10سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1867</td>
                                  <td dir="RTL">بنرماكس 70% WP</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">80جم/100 لتر ماء</td>
                                  <td dir="RTL">7</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1621</td>
                                  <td dir="RTL">بوينت ماس 10% EC</td>
                                  <td dir="RTL">Penconazole</td>
                                  <td dir="RTL">10سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">946</td>
                                  <td dir="RTL">بيللكيوت 40% WP</td>
                                  <td dir="RTL">
                                    Iminoctadine tris (albesilate)
                                  </td>
                                  <td dir="RTL">50جم/100 لتر ماء</td>
                                  <td dir="RTL">5</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1123</td>
                                  <td dir="RTL">بيليز 38% WG</td>
                                  <td dir="RTL">
                                    Boscalid
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">50جم/100 لتر ماء</td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2521</td>
                                  <td dir="RTL">بينوزد 25% EC</td>
                                  <td dir="RTL">Propiconazole</td>
                                  <td dir="RTL">15سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2521</td>
                                  <td dir="RTL">بينوزد 25% EC</td>
                                  <td dir="RTL">Propiconazole</td>
                                  <td dir="RTL">15سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3165</td>
                                  <td dir="RTL">بوتانيل كينج 12.5% EC</td>
                                  <td dir="RTL">Myclobutanil</td>
                                  <td dir="RTL">250سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3322</td>
                                  <td dir="RTL">بيريسكا 38% WG</td>
                                  <td dir="RTL">
                                    Boscalid
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">50جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3267</td>
                                  <td dir="RTL">توب لاين 32.5% SC</td>
                                  <td dir="RTL">
                                    Azoxystrobin
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">75سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3087</td>
                                  <td dir="RTL">تاجو توب 70% WP</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">80جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    14 أوراق <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1300</td>
                                  <td dir="RTL">تاليندو 20% EC</td>
                                  <td dir="RTL">Proquinazid</td>
                                  <td dir="RTL">20سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    18 أوراق
                                    <br />
                                    14 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2906</td>
                                  <td dir="RTL">جرننز 50% WG</td>
                                  <td dir="RTL">Trifloxystrobin</td>
                                  <td dir="RTL">20جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    14 أوراق
                                    <br />5 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2423</td>
                                  <td dir="RTL">دايوفان 80% WP</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2400</td>
                                  <td dir="RTL">دلتاماكس 25% EC</td>
                                  <td dir="RTL">Propiconazole</td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2645</td>
                                  <td dir="RTL">دلتا دوم 25% EC</td>
                                  <td dir="RTL">Difenoconazole</td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">583</td>
                                  <td dir="RTL">دومارك 10% EC</td>
                                  <td dir="RTL">Tetraconazole</td>
                                  <td dir="RTL">40سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    11 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2461</td>
                                  <td dir="RTL"> ترينول 25% EC</td>
                                  <td dir="RTL">Triadimenol</td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">10</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">295</td>
                                  <td dir="RTL">توباس 10% EC</td>
                                  <td dir="RTL">Penconazole</td>
                                  <td dir="RTL">10سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1320</td>
                                  <td dir="RTL">تيولين 71.76% SC</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">100سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2614</td>
                                  <td dir="RTL">ثيروفان 80% WG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    3 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1322</td>
                                  <td dir="RTL">ثيوفان 80% WG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1024</td>
                                  <td dir="RTL">ثيوفيت جيت 80% WG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    3 أوراق <br />2 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2579</td>
                                  <td dir="RTL">جاتين 5% EC</td>
                                  <td dir="RTL">Flutianil</td>
                                  <td dir="RTL">20سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2419</td>
                                  <td dir="RTL">ديبوستار توب 32.5% SC</td>
                                  <td dir="RTL">
                                    Azoxystrobin
                                    <br />
                                    Difenoconazol
                                  </td>
                                  <td dir="RTL">75سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2796</td>
                                  <td dir="RTL">ديبزول 30% EC</td>
                                  <td dir="RTL">
                                    Difenoconazole
                                    <br />
                                    Propiconazole
                                  </td>
                                  <td dir="RTL">40سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2125</td>
                                  <td dir="RTL">ديفيد 60% WG</td>
                                  <td dir="RTL">
                                    Metiram
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">100جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1642</td>
                                  <td dir="RTL">ديماك 25% EC</td>
                                  <td dir="RTL">Difenoconazole</td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">21</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2208</td>
                                  <td dir="RTL">دينالى 9% DC</td>
                                  <td dir="RTL">
                                    {' '}
                                    Cyflufenamid
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">250سم/فدان</td>
                                  <td dir="RTL">
                                    21 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1265</td>
                                  <td dir="RTL">ريتريب 5% EW</td>
                                  <td dir="RTL">Cyflufenamid</td>
                                  <td dir="RTL">15سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2720</td>
                                  <td dir="RTL">روا ستار برو 32.5% SC</td>
                                  <td dir="RTL">
                                    Azoxystrobin
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">75سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1950</td>
                                  <td dir="RTL">سوريل ميكرونى كزد 70% WP</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    3 أوراق <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1763</td>
                                  <td dir="RTL">سوفرڤت 80% SC</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">200سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1104</td>
                                  <td dir="RTL">سولجرين 80% WG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">5</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1827</td>
                                  <td dir="RTL">سولفان كزد 70% SC</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">150سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    3 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2313</td>
                                  <td dir="RTL">سولى سلفر 80% WG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2841</td>
                                  <td dir="RTL">سيرفو 50% WDG</td>
                                  <td dir="RTL">Kresoxim-methyl</td>
                                  <td dir="RTL">15جم/100 لتر ماء</td>
                                  <td dir="RTL">14</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3144</td>
                                  <td dir="RTL">سيف اويل 0.03% EC</td>
                                  <td dir="RTL">Azadirachtin</td>
                                  <td dir="RTL">250سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    1 اوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1380</td>
                                  <td dir="RTL">سيسثين 24% EC</td>
                                  <td dir="RTL">Myclobutanil</td>
                                  <td dir="RTL">17سم/100 لتر ماء</td>
                                  <td dir="RTL">14</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3307</td>
                                  <td dir="RTL">سلطانا 80% WDG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3011</td>
                                  <td dir="RTL">شوتير 30% SC</td>
                                  <td dir="RTL">
                                    Boscalid
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1552</td>
                                  <td dir="RTL">صن توب النصر 70% WP</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">80جم/100 لتر ماء</td>
                                  <td dir="RTL">17</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2514</td>
                                  <td dir="RTL">صن فليكس 80% WDG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2925</td>
                                  <td dir="RTL">فريمور 25% SC</td>
                                  <td dir="RTL">Flutriafol</td>
                                  <td dir="RTL">45سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />5 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1026</td>
                                  <td dir="RTL">فلنت 50% WG</td>
                                  <td dir="RTL">Trifloxystrobin</td>
                                  <td dir="RTL">20جم/100 لتر ماء</td>
                                  <td dir="RTL">35</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2589</td>
                                  <td dir="RTL">فلوبين 60% WG</td>
                                  <td dir="RTL">
                                    {' '}
                                    Metiram
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">100جم/100 لتر ماء</td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1076</td>
                                  <td dir="RTL">فنجيكور 70% WP</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">100جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />8 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1615</td>
                                  <td dir="RTL">فيت 25% EC</td>
                                  <td dir="RTL">Propiconazole</td>
                                  <td dir="RTL">10سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1754</td>
                                  <td dir="RTL">فيفاندو 50% SC</td>
                                  <td dir="RTL">Metrafenone</td>
                                  <td dir="RTL">20سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    21 أوراق <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">521</td>
                                  <td dir="RTL">فيكترا 10% SC</td>
                                  <td dir="RTL">Bromuconazole</td>
                                  <td dir="RTL">30سم/100 لتر ماء</td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2883</td>
                                  <td dir="RTL">كازو 80% WDG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">3</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1171</td>
                                  <td dir="RTL">كبريو توب 60% WG</td>
                                  <td dir="RTL">
                                    Metiram
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">100جم/100 لتر ماء</td>
                                  <td dir="RTL">35</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1319</td>
                                  <td dir="RTL">كراون 25% EC</td>
                                  <td dir="RTL">Propiconazole</td>
                                  <td dir="RTL">15سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1097</td>
                                  <td dir="RTL">كوليز 30% SC</td>
                                  <td dir="RTL">
                                    Boscalid
                                    <br />
                                    Kresoxim-methyl
                                  </td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">10</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1506</td>
                                  <td dir="RTL">كومبينكس 70% WP</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">80جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">540</td>
                                  <td dir="RTL">كومولوس اس 80% WG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">10</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1793</td>
                                  <td dir="RTL">كونسيت 25% SC</td>
                                  <td dir="RTL">Flutriafol</td>
                                  <td dir="RTL">45سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2131</td>
                                  <td dir="RTL">مافن 18.7% WG</td>
                                  <td dir="RTL">
                                    Dimethomorph
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">100جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    21 أوراق
                                    <br />
                                    15 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2229</td>
                                  <td dir="RTL">ماى دراجون 25% EC</td>
                                  <td dir="RTL">Myclobutanil</td>
                                  <td dir="RTL">130سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    21 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2375</td>
                                  <td dir="RTL">مايكوسام 25% EC</td>
                                  <td dir="RTL">Propiconazole</td>
                                  <td dir="RTL">15سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1699</td>
                                  <td dir="RTL">ميتادور 25% EC</td>
                                  <td dir="RTL">Propiconazole</td>
                                  <td dir="RTL">15سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1374</td>
                                  <td dir="RTL">ميكروباجن 80% WP</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">597</td>
                                  <td dir="RTL">ميكروثيول سبيشيال 80% WG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">7</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1368</td>
                                  <td dir="RTL">ميكروسلفر 80% WG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1897</td>
                                  <td dir="RTL">ميكروفيت - كزد 80% WP</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    3 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2569</td>
                                  <td dir="RTL">ميكرونايت - اس 80% WP</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">500جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1507</td>
                                  <td dir="RTL">ميلفيت 80% WDG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1560</td>
                                  <td dir="RTL">ناتيفو 75% WG</td>
                                  <td dir="RTL">
                                    Tebuconazole
                                    <br />
                                    Trifloxystrobin
                                  </td>
                                  <td dir="RTL">125جم/فدان</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1421</td>
                                  <td dir="RTL">نيلبو 12.5% EC</td>
                                  <td dir="RTL">Myclobutanil</td>
                                  <td dir="RTL">40سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    12 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2762</td>
                                  <td dir="RTL">
                                    ويلز 38% WG<span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    Boscalid
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1554</td>
                                  <td dir="RTL">هاى فيت 80% WDG</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">250جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    4 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1023</td>
                                  <td dir="RTL">هستا70% WP</td>
                                  <td dir="RTL">Thiophanate-methyl</td>
                                  <td dir="RTL">65جم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    10 أوراق
                                    <br />8 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2591</td>
                                  <td dir="RTL">هوت شوت 30% EC</td>
                                  <td dir="RTL">
                                    Difenoconazole
                                    <br />
                                    Propiconazole
                                  </td>
                                  <td dir="RTL">50سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />7 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1266</td>
                                  <td dir="RTL">هيليوسوفر 70% SC</td>
                                  <td dir="RTL">Sulfur</td>
                                  <td dir="RTL">125سم/100 لتر ماء</td>
                                  <td dir="RTL">
                                    5 أوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*8-	البياض الزغبي*/}
                      <Collapse title="8- البياض الزغبي">
                        {/* <h2 className="details-page-title subTitle">
                          8-	البياض الزغبي
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/04/byadzo8by.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                بقع صفراء على السطح العلوي للأوراق وظهور نمو
                                زغبى على السطح السفلي
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>من النصف الثاني من مايو إلى نهاية الموسم.</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور أعراض الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1792</td>
                                  <td dir="RTL">ازوستار 25% SC</td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    12 اوراق
                                    <br />3<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1516</td>
                                  <td dir="RTL">افدال باكيروكس 87%WP</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    300 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />6<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1756</td>
                                  <td dir="RTL">افدال ماكو ام 45 80% WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />3<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">546</td>
                                  <td dir="RTL">اكروبات نحاس 73.2% WP</td>
                                  <td dir="LTR">
                                    Copper oxychloride
                                    <br />
                                    Dimethomorph
                                  </td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    20 اوراق
                                    <br />1<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2987</td>
                                  <td dir="RTL">اتش ار - ازومورف 70% WG</td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Dimethomorph
                                  </td>
                                  <td dir="RTL">
                                    50 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    20 اوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1357</td>
                                  <td dir="RTL">اكسترا 25% SC</td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">698</td>
                                  <td dir="RTL">اكواجن برو 52.5% WG</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Famoxadone
                                  </td>
                                  <td dir="RTL">
                                    30 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">25</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1027</td>
                                  <td dir="RTL">اميستار 25% SC</td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    6 اوراق
                                    <br />6 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">744</td>
                                  <td dir="RTL">انادول 80% WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2039</td>
                                  <td dir="RTL">اناكوب ل 69% SC</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>200 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />3<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1035</td>
                                  <td dir="RTL">اندكس 77% WP</td>
                                  <td dir="LTR">Copper hydroxide</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">20</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1525</td>
                                  <td dir="RTL">اندوفيل ام - 45 80% WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />3<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1900</td>
                                  <td dir="RTL">ايرون 48.9% WG</td>
                                  <td dir="LTR">
                                    Copper hydroxide
                                    <br />
                                    Copper oxychloride
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1451</td>
                                  <td dir="RTL">باترول 46% SC</td>
                                  <td dir="LTR">Copper hydroxide</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>200 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />5<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2921</td>
                                  <td dir="RTL">بادجيت 25% WP</td>
                                  <td dir="LTR">Metalaxyl</td>
                                  <td dir="RTL">
                                    100 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2681</td>
                                  <td dir="RTL">بروموت 72.2% SL</td>
                                  <td dir="LTR">Propamocarb hydrochloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1142</td>
                                  <td dir="RTL">بلوجيت 85% WG</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">20</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1258</td>
                                  <td dir="RTL">بوردو كافارو 74.81% WP</td>
                                  <td dir="LTR">Bordeaux mixture</td>
                                  <td dir="RTL">
                                    300 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">19</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2999</td>
                                  <td dir="RTL">بارانيكس 72% WP</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3130</td>
                                  <td dir="RTL">بروفيلر 71.11% WG</td>
                                  <td dir="LTR">
                                    Fluopicolide
                                    <br />
                                    Fosetyl-Aluminium
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3244</td>
                                  <td dir="RTL">تراست ستار 25% SC</td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    14 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1416</td>
                                  <td dir="RTL">تراست كوبر 85% WP</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />6<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">410</td>
                                  <td dir="RTL">ترايدكس سوبر 75% WG</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    200 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">10</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1617</td>
                                  <td dir="RTL">تريل اف 52% WP</td>
                                  <td dir="RTL">
                                    Benalaxyl
                                    <br />
                                    Folpet
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">84</td>
                                  <td dir="RTL">جالبين مانكوزيب 58% WP</td>
                                  <td dir="RTL">
                                    Benalaxyl
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">14</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2378</td>
                                  <td dir="RTL">جانجر 50% WP</td>
                                  <td dir="RTL">
                                    Fosetyl-Aluminium
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    7 اوراق
                                    <br />3 ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1752</td>
                                  <td dir="RTL">دايام 80% WP</td>
                                  <td dir="RTL">Mancozeb</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />3<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">189</td>
                                  <td dir="RTL">دايثين إم 45 - 80% WP</td>
                                  <td dir="RTL">Mancozeb</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2174</td>
                                  <td dir="RTL">دايروف 50% WDG</td>
                                  <td dir="RTL">Dimethomorph</td>
                                  <td dir="RTL">
                                    50 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />3<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">735</td>
                                  <td dir="RTL">دايكوزيب 80% WP</td>
                                  <td dir="RTL">Mancozeb</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">12</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2817</td>
                                  <td dir="RTL">دل كب جولد 23.50% SL</td>
                                  <td dir="RTL">Copper Sulfate Pentahydrate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2881</td>
                                  <td dir="RTL">ديمسترو 70% WG</td>
                                  <td dir="RTL">
                                    Azoxystrobin
                                    <br />
                                    Dimethomorph
                                  </td>
                                  <td dir="RTL">
                                    50 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">7</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1327</td>
                                  <td dir="RTL">روكسيل بلس 68.9% WP</td>
                                  <td dir="RTL">
                                    Copper hydroxide
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    21 اوراق
                                    <br />
                                    12<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1062</td>
                                  <td dir="RTL">رولكس 50% WP</td>
                                  <td dir="RTL">
                                    Copper oxychloride
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2477</td>
                                  <td dir="RTL">روميل 72% WP</td>
                                  <td dir="RTL">
                                    Mancozeb
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    200 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3350</td>
                                  <td dir="RTL">زو اسنروبين 25% SC</td>
                                  <td dir="RTL">Azoxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2512</td>
                                  <td dir="RTL">ساند بيلاور 72% WP</td>
                                  <td dir="RTL">
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1585</td>
                                  <td dir="RTL">ساندفلاور 72% WP</td>
                                  <td dir="RTL">
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1439</td>
                                  <td dir="RTL">ساندكيور 72% WP</td>
                                  <td dir="RTL">
                                    Mancozeb
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    7 اوراق
                                    <br />3<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2261</td>
                                  <td dir="RTL">سبيدول 52.5% DF</td>
                                  <td dir="RTL">
                                    Cymoxanil
                                    <br />
                                    Famoxadone
                                  </td>
                                  <td dir="RTL">
                                    40 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1256</td>
                                  <td dir="RTL">ستار كوبر 50% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    14 اوراق
                                    <br />1<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1903</td>
                                  <td dir="RTL">سمارت كوبر 85% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    300 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">21</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1422</td>
                                  <td dir="RTL">سيكيكو 25.5% WP</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>Bordeaux mixture
                                    <br />
                                    Cymoxanil
                                  </td>
                                  <td dir="RTL">
                                    300 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    21 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2760</td>
                                  <td dir="RTL">سيمو زد 72% WP</td>
                                  <td dir="RTL">
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3118</td>
                                  <td dir="RTL">ستروكيل 25% SC</td>
                                  <td dir="RTL">Azoxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />5<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">375</td>
                                  <td dir="RTL">شامبيون77% WP</td>
                                  <td dir="RTL">Copper hydroxide</td>
                                  <td dir="RTL">
                                    180 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">23</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1698</td>
                                  <td dir="RTL">فانتك ام 69% WP</td>
                                  <td dir="RTL">
                                    Benalaxyl-M
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1231</td>
                                  <td dir="RTL">فانجي كب 62% WDG</td>
                                  <td dir="RTL">Copper hydroxide</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">21</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">623</td>
                                  <td dir="RTL">فلورام 60.06% SC</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>300 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">20</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1261</td>
                                  <td dir="RTL">فوسترول 53.6% SL</td>
                                  <td dir="RTL">Phosphorous acids salts</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">-</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1517</td>
                                  <td dir="RTL">فولار 73% WP</td>
                                  <td dir="RTL">
                                    Copper oxychloride
                                    <br />
                                    Dimethomorph
                                  </td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    12 اوراق
                                    <br />3<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1694</td>
                                  <td dir="RTL">كابتان الترا80% WG</td>
                                  <td dir="RTL">Captan</td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    21 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2356</td>
                                  <td dir="RTL">كابكت 40% WP</td>
                                  <td dir="RTL">
                                    Copper oxychloride
                                    <br />
                                    Cymoxanil
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2853</td>
                                  <td dir="RTL">كاليو 75% WG</td>
                                  <td dir="RTL">Mancozeb</td>
                                  <td dir="RTL">
                                    200 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">10</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2936</td>
                                  <td dir="RTL">كاتشر 61.4% WDG</td>
                                  <td dir="RTL">Copper hydroxide</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2619</td>
                                  <td dir="RTL">كايرو زاد 73.15% WG</td>
                                  <td dir="RTL">
                                    Copper oxychloride
                                    <br />
                                    Cymoxanil
                                  </td>
                                  <td dir="RTL">
                                    200 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2926</td>
                                  <td dir="RTL">كب كير 85% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1901</td>
                                  <td dir="RTL">كبتوكس 85% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    14 اوراق
                                    <br />
                                    14<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1232</td>
                                  <td dir="RTL">كبروماك 85% WDG</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">19</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1839</td>
                                  <td dir="RTL">كبريو ديو 11.2% EC</td>
                                  <td dir="RTL">
                                    Dimethomorph
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>200 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">28</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1082</td>
                                  <td dir="RTL">كرانش 25.63% SP</td>
                                  <td dir="RTL">Copper sulfate</td>
                                  <td dir="RTL">
                                    125 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    12 اوراق
                                    <br />
                                    16<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2266</td>
                                  <td dir="RTL">كوبراكس 77% WP</td>
                                  <td dir="RTL">Copper hydroxide</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    14 اوراق
                                    <br />5<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2411</td>
                                  <td dir="RTL">كوبر - زد 85% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    300 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />3<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">610</td>
                                  <td dir="RTL">كوبراريخ 84% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    300 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">19</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1108</td>
                                  <td dir="RTL">كوبرازين 58.8% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    200 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">20</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">602</td>
                                  <td dir="RTL">كوبرال 84.3% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    300 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">21</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1089</td>
                                  <td dir="RTL">كوبرهسيد 77% WP</td>
                                  <td dir="RTL">Copper hydroxide</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">21</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">307</td>
                                  <td dir="RTL">كوبروكسات 34.5% FL</td>
                                  <td dir="RTL">Copper sulfate tribasic</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>300 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />9<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3359</td>
                                  <td dir="RTL">كبرتن سوبر 91.3% WP</td>
                                  <td dir="RTL">
                                    Bordeaux mixture
                                    <br />
                                    Cymoxanil
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2996</td>
                                  <td dir="RTL">كونيكام 18.7% WDG</td>
                                  <td dir="RTL">
                                    Dimethomorph
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">
                                    100 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">624</td>
                                  <td dir="RTL">كوبروكفارو 86.04% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    300 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">20</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2515</td>
                                  <td dir="RTL">كوست 77% WP</td>
                                  <td dir="RTL">Copper hydroxide</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1096</td>
                                  <td dir="RTL">كيرزيت أر 73.15% WP</td>
                                  <td dir="RTL">
                                    Copper oxychloride
                                    <br />
                                    Cymoxanil
                                  </td>
                                  <td dir="RTL">
                                    200 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">920</td>
                                  <td dir="RTL">كيرنوكس 85% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    300 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    21 اوراق
                                    <br />
                                    21<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1008</td>
                                  <td dir="RTL">كيو-كوبر 84.04% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    300 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    21 اوراق
                                    <br />6<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1071</td>
                                  <td dir="RTL">كيور- بلاس 74.5% WP</td>
                                  <td dir="RTL">
                                    Copper oxychloride
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">21</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2814</td>
                                  <td dir="RTL">لاند كيور ميكس 72% WP</td>
                                  <td dir="RTL">
                                    Mancozeb
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1410</td>
                                  <td dir="RTL">مانكو النصر 80% WP</td>
                                  <td dir="RTL">Mancozeb</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1568</td>
                                  <td dir="RTL">مانكودكس ام 80% WP</td>
                                  <td dir="RTL">Mancozeb</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2446</td>
                                  <td dir="RTL">مانكولاكس 70% WP</td>
                                  <td dir="RTL">
                                    Mancozeb
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    200 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2101</td>
                                  <td dir="RTL">مانوميتا 72% WP</td>
                                  <td dir="RTL">
                                    Mancozeb
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1707</td>
                                  <td dir="RTL">مانيكس 72% WP</td>
                                  <td dir="RTL">
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2898</td>
                                  <td dir="RTL">موبيدو 50% WP</td>
                                  <td dir="RTL">Dimethomorph</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1543</td>
                                  <td dir="RTL">موبيستار 25% SC</td>
                                  <td dir="RTL">Azoxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2761</td>
                                  <td dir="RTL">ميجال 80% WG</td>
                                  <td dir="RTL">Azoxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>16 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    10 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2139</td>
                                  <td dir="RTL">ميسن تربل 49.2% WP</td>
                                  <td dir="RTL">
                                    Benalaxyl
                                    <br />
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />
                                    10<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1021</td>
                                  <td dir="RTL">ميلور- كو 74% WP</td>
                                  <td dir="RTL">
                                    Copper oxychloride
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    150 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">20</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1578</td>
                                  <td dir="RTL">نوفاكبر 85% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1889</td>
                                  <td dir="RTL">نوفوستار 30% WG</td>
                                  <td dir="RTL">
                                    Azoxystrobin
                                    <br />
                                    Dimethomorph
                                  </td>
                                  <td dir="RTL">
                                    50 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />7<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1386</td>
                                  <td dir="RTL">نوفيكيور 70% WG</td>
                                  <td dir="RTL">Copper sulfate tribasic</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    19 اوراق
                                    <br />5<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1566</td>
                                  <td dir="RTL">هاى كوبر 85% WP</td>
                                  <td dir="RTL">Copper oxychloride</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />3<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1153</td>
                                  <td dir="RTL">هيدروكوب 77%WP</td>
                                  <td dir="RTL">Copper hydroxide</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">20</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1660</td>
                                  <td dir="RTL">هيدروكيو 77% WP</td>
                                  <td dir="RTL">Copper hydroxide</td>
                                  <td dir="RTL">
                                    250 جم /<span>&nbsp; </span>100 لتر ماء
                                  </td>
                                  <td dir="RTL">
                                    15 اوراق
                                    <br />6<span>&nbsp; </span>ثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1274</td>
                                  <td dir="RTL">هيليوكوفر 62% SC</td>
                                  <td dir="RTL">Copper hydroxide</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>125 سم / 100 لتر ماء
                                  </td>
                                  <td dir="RTL">21</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*9-	نيماتودا تعقد الجذور*/}
                      <Collapse title="9- نيماتودا تعقد الجذور">
                        {/* <h2 className="details-page-title subTitle">
                          9-	نيماتودا تعقد الجذور
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/04/nematoda.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>عقد على الجذور و اصفرار المجموع الخضرى</p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>من طور الشتلات حتى نهاية الموسم.</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              أي أعداد في بداية الموسم في التربة تعتبر خطيرة
                              وتستحق العلاج.
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="RTL">2117</td>
                                  <td dir="RTL">اوكزانيم 24% SL</td>
                                  <td dir="RTL">Oxamyl</td>
                                  <td dir="RTL">5لتر/فدان (رشتين)</td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3371</td>
                                  <td dir="RTL">اوكسافورس 24% SL</td>
                                  <td dir="RTL">Oxamyl</td>
                                  <td dir="RTL">5لتر/فدان (رشتين)</td>
                                  <td dir="RTL">
                                    60 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3375</td>
                                  <td dir="RTL">اوكسفام 24% SL</td>
                                  <td dir="RTL">Oxamyl</td>
                                  <td dir="RTL">5لتر/فدان (رشتين)</td>
                                  <td dir="RTL">
                                    60 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3377</td>
                                  <td dir="RTL">بارافوس 40% EC</td>
                                  <td dir="RTL">Fenamiphos</td>
                                  <td dir="RTL">6لتر/فدان (رشتين)</td>
                                  <td dir="RTL">
                                    60 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3387</td>
                                  <td dir="RTL">برومايت 24% SL</td>
                                  <td dir="RTL">Oxamyl</td>
                                  <td dir="RTL">5لتر/فدان (رشتين)</td>
                                  <td dir="RTL">
                                    60 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1616</td>
                                  <td dir="RTL">تيرفيجو 2% SC</td>
                                  <td dir="RTL">Abamectin</td>
                                  <td dir="RTL">3لتر/فدان</td>
                                  <td dir="RTL">
                                    3 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1677</td>
                                  <td dir="RTL">جوبيتر اكس 10% GR</td>
                                  <td dir="RTL">Fosthiazate</td>
                                  <td dir="RTL">12.5كجم/فدان</td>
                                  <td dir="RTL">
                                    45 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1099</td>
                                  <td dir="RTL">راجبى 20% CS</td>
                                  <td dir="RTL">Cadusafos</td>
                                  <td dir="RTL">
                                    5لتر/فدان<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    60 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2148</td>
                                  <td dir="RTL">دايفيت 40% EC</td>
                                  <td dir="RTL">Fenamiphos</td>
                                  <td dir="RTL">
                                    6لتر/فدان<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">15</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1868</td>
                                  <td dir="RTL">فايبر ماكس 10% GR</td>
                                  <td dir="RTL">Ethoprophos</td>
                                  <td dir="RTL">40كجم/فدان</td>
                                  <td dir="RTL">80</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1370</td>
                                  <td dir="RTL">فيتكس 24% SL</td>
                                  <td dir="RTL">Oxamyl</td>
                                  <td dir="RTL">5لتر/فدان (رشتين)</td>
                                  <td dir="RTL">-</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2373</td>
                                  <td dir="RTL">كرينكل 75% EC</td>
                                  <td dir="RTL">Fosthiazate</td>
                                  <td dir="RTL">
                                    2.5لتر/فدان (رشتين)<span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    60 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">2074</td>
                                  <td dir="RTL">نيمازيت 10%G</td>
                                  <td dir="RTL">Fosthiazate</td>
                                  <td dir="RTL">12.5كجم/فدان</td>
                                  <td dir="RTL">30</td>
                                </tr>
                                <tr>
                                  <td dir="RTL">1442</td>
                                  <td dir="RTL">نيمافوس 40% EC</td>
                                  <td dir="RTL">Fenamiphos</td>
                                  <td dir="RTL">
                                    2.5لتر/فدان (رشتين)<span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    60 أوراق
                                    <br />
                                    وثمار
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="RTL">3372</td>
                                  <td dir="RTL">منتوفوس 40% EC</td>
                                  <td dir="RTL">Fenamiphos</td>
                                  <td dir="RTL">6لتر/فدان (رشتين)</td>
                                  <td dir="RTL">
                                    15 أوراق
                                    <br />
                                    10 ثمار
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
