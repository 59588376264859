import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
// import { Button, Collapse, UncontrolledCollapse } from 'reactstrap';
import Collapse from './collapse/collapse';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

function Index() {
  const data = {
    labels: ['2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'المواسم',
        data: [1537.06, 1833.368, 2002.418, 948.718, 929.675],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };

  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [7251.236, 81275.562],
            offset: [5],

            // tooltip: {
            //   callbacks: {
            //     label: function (context) {
            //       let label = context.label;
            //       let value = context.formattedValue;

            //       if (!label) label = 'Unknown';

            //       let sum = 0;
            //       let dataArr = context.chart.data.datasets[0].data;
            //       dataArr.map((data) => {
            //         sum += Number(data);
            //       });

            //       const x = value.replace(/[,]/g, '');
            //       let percentage = ((x * 100) / sum).toFixed(2) + '%';
            //       return label + ': ' + percentage;
            //     },
            //   },
            // },
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
        // datalabels: {
        //   formatter: (value, dnct1) => {
        //     let sum = 0;
        //     let dataArr = dnct1.chart.data.datasets[0].data;
        //     dataArr.map((data) => {
        //       sum += data;
        //     });
        //     let percentage = ((value * 100) / sum).toFixed(2) + '%';
        //     return percentage;
        //   },
        //   display: true,
        //   color: 'black',
        //   align: 'end',
        //   // rotation: [10, 20],
        //   padding: {
        //     right: 200,
        //   },
        //   textAlign: 'center',
        //   labels: {
        //     padding: { top: 10 },
        //     title: {
        //       font: {
        //         weight: 'bold',
        //       },
        //     },
        //     value: {
        //       color: 'green',
        //     },
        //   },
        //   // align: 'center',
        //   // anchor: 'right',
        //   // offset: [10, 6],
        //   // textAlign: 'end',
        //   // padding: {
        //   //   right: [5, 10],
        //   // },
        // },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
    // plugins: [ChartDataLabels],
  };
  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/02/0555.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  الموالح
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    إن الموالح المصرية تفوقت علي كل منافسيها من حيث التصدير إلي
                    دول العالم فتخطت اسبانيا التي كانت تحتل المرتبة الأولى
                    وتفوقت علي جنوب افريقيا و تحتلّ الموالح المرتبة الأولى في
                    الصادرات الزراعية المصرية بحوالي 1.4 مليون طن، موضحا أن
                    التصدير يتم طبقًا للاشتراطات والمواصفات الفنية للدول
                    المستوردة، والطلب يتزايد على الصادرات الزراعية المصرية رغم
                    انتشار فيروس كورونا في العالم، مما يؤكد جودة منتجاتنا
                    الزراعية وأن حجم الصادرات الزراعية سوف يصل إلي 5.5 مليون طن
                    وهي نفس كميات العام الماضى.
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات الموالح من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات الموالح المصرية مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%8.92</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات الموالح المصرية
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- البق الدقيقى */}
                      <Collapse title="1- البق الدقيقى">
                        {/* <h2 className="details-page-title subTitle">
                          1- البق الدقيقى
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/Mealybug.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                وجود الحشرات الكاملة والحوريات - نمو العفن
                                الأسود - وجود النمل الذي يتغذى على الندوة
                                العسلية التي تفرزها الحشرات.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تظهر الحشرات الكاملة طوال العام. معظم أنواع البق
                              الدقيقى محتمى في الشتاء في شقوق القلف أو على
                              الجذور ثم تزحف في الربيع نحو النموات الجديدة.
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند إصابة 5% من عدد أشجار الحديقة مع الأخذ في
                              الاعتبار نسبة الأشجار المصابة إلى السليمة كذلك نفس
                              النسبة لعدد الأوراق المصابة بالشجرة الواحدة.
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1003</td>
                                  <td dir="RTL">
                                    اكتارا<span>&nbsp; </span>WG% 25
                                  </td>
                                  <td dir="LTR">Thiamethoxam</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">32</td>
                                </tr>

                                <tr>
                                  <td dir="LTR">2285</td>
                                  <td>
                                    رومكتين جولد SC %32.8
                                    <span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Abamectin 2.8 %<br />
                                    <span>&nbsp;</span>Imidacloprid 30%
                                  </td>
                                  <td>
                                    <span>&nbsp;&nbsp; </span>85 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td>10</td>
                                </tr>

                                <tr>
                                  <td dir="LTR">2719</td>
                                  <td dir="RTL">سنابي WG% 25</td>
                                  <td dir="LTR">Thiamethoxam</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>

                                <tr>
                                  <td dir="LTR">2366</td>
                                  <td dir="RTL">فابيان WG% 25</td>
                                  <td dir="LTR">Thiamethoxam</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2- الحشرات القشرية*/}
                      <Collapse title="2- الحشرات القشرية">
                        {/* <h2 className="details-page-title subTitle">
                          2- الحشرات القشرية
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/aonidiella.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ووجود الحوريات والحشرات الكاملة وأكياس البيض على
                                الأفرع والأوراق وتتجمع الحشرات على امتداد العروق
                                الوسطى خاصة السطح السفلي - نموفطر العفن الأسود
                                على الأوراق والثمار - وجود النمل الذي يتغذى على
                                الإفرازات العسلية التي تفرزها الحشرة بغزارة -
                                اصفرار الأوراق،ذبولها عند الإصابة الشديدة صغر
                                حجم الثمار المتكونة وتشوهها.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تظهر طوال العام ولها 4-5 أجيال في السنة تصيب
                              المواح خاصة الليمون البلدى .
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وجود إصابة على 5% من عدد الأشجار التي يتم
                              فحصها عشوائيا بالحديقة.
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">538</td>
                                  <td dir="RTL">ابلوود 25% SC</td>
                                  <td>Buprofezin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    600 سم مكعب/ فدان
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">176</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>
                                    البوليوم 80% مايونيز
                                  </td>
                                  <td>Mineral oil</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    2.5 لتر /100 لتر ماء (رش شتوى)
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>

                                <tr>
                                  <td dir="LTR">1465</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>اميدان 50% WP
                                  </td>
                                  <td dir="LTR">Phosmet</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>140 جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3096</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>شينوك بلس 35% SC
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">75 سم مكعب/100 لتر ماء</td>
                                  <td dir="LTR">4</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2381</td>
                                  <td dir="RTL">بروفى 10% EC</td>
                                  <td dir="LTR">Pyriproxyfen</td>
                                  <td dir="RTL">50 سم مكعب/100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1727</td>
                                  <td dir="RTL">بروكسيمو 10% EC</td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Pyriproxyfen
                                  </td>
                                  <td dir="RTL">50 سم مكعب/100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1148</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>بست 25% WP
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>100 جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1767</td>
                                  <td dir="RTL">تايجر 97% EC</td>
                                  <td dir="LTR">Mineral oil</td>
                                  <td dir="RTL">
                                    1.5 لتر /100 لتر ماء<span>&nbsp;</span>(رش
                                    صيفى)
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2175</td>
                                  <td dir="RTL">ترانسفورم 50% WG</td>
                                  <td dir="LTR">Sulfoxaflor</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>125 جم/ فدان
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2364</td>
                                  <td dir="RTL">جريندو 10% EC</td>
                                  <td dir="LTR">Pyriproxyfen</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>50 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1963</td>
                                  <td dir="RTL">جلستير 10% EC</td>
                                  <td dir="LTR">Pyriproxyfen</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>50 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1039</td>
                                  <td dir="RTL">دايفر 97% EC</td>
                                  <td dir="LTR">Mineral oil</td>
                                  <td dir="RTL">
                                    1.5 لتر /100 لتر ماء<span>&nbsp;</span>(رش
                                    صيفى)
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">89</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سوبر رويال 95% EC
                                  </td>
                                  <td dir="LTR">Mineral oil</td>
                                  <td dir="RTL">
                                    1.5 لتر /100 لتر ماء<span>&nbsp;</span>(رش
                                    صيفى)
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">237</td>
                                  <td dir="RTL">سوبر مصرونا 94% EC</td>
                                  <td dir="LTR">Mineral oil</td>
                                  <td dir="RTL">
                                    1.5 لتر /100 لتر ماء<span>&nbsp;</span>(رش
                                    صيفى)
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1402</td>
                                  <td dir="RTL">شينوك 35% SC</td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>75 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">174</td>
                                  <td dir="RTL">كزد اويل 95% EC</td>
                                  <td dir="LTR">Mineral oil</td>
                                  <td dir="RTL">
                                    1.5 لتر /100 لتر ماء<span>&nbsp;</span>(رش
                                    صيفى)
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1486</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كونفيدور 20% OD
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>100 سم مكعب/100
                                    لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3355</td>
                                  <td dir="RTL">كانجى 10% EC</td>
                                  <td dir="LTR">Pyriproxyfen</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>50 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">236</td>
                                  <td dir="RTL">مصرونا 85% مايونيز</td>
                                  <td dir="LTR">Mineral oil</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>2.5 لتر /100 لتر
                                    ماء (رش شتوى)
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1757</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>موفينتو 10% SC
                                  </td>
                                  <td dir="LTR">Spirotetramat</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>40 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2596</td>
                                  <td dir="RTL">نصر فيزين 25% SC</td>
                                  <td dir="LTR">Buprofezin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>150 سم مكعب/100
                                    لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 3- ذبابة الفاكهة */}
                      <Collapse title="3- ذبابة الفاكهة">
                        {/* <h2 className="details-page-title subTitle">
                          3- ذبابة الفاكهة
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/js.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ظهور وخزات على جسم مكعب الثمرة مع تغير في لون
                                المنطقة المحيطة بالوخزات ولين داخل الثمرة نتيجة
                                لتجول اليرقات وبالضغط عليها يخرج السائل منها
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              عند ظهور إصابات بالثمار. ويشاهد تساقط للثمار حول
                              الشجرة.
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              في شهري أكتوبر ونوفمبر ويجب التخلص من الثمار
                              المتساقطة بالدفن أو الحرق فورا .
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">3053</td>
                                  <td dir="RTL">اكسيريل 10% SE</td>
                                  <td dir="LTR">Cyantraniliprole</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp;</span>
                                    75 سم مكعب/20 لتر ماء +5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3272</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>
                                    افدال كيميدون 40% EC
                                  </td>
                                  <td dir="LTR">Dimethoate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    75 سم مكعب/20 لتر ماء +5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">738</td>
                                  <td dir="RTL">دايمتوكس 40% EC</td>
                                  <td dir="LTR">Dimethoate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    75 سم مكعب/20 لتر ماء +5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">151</td>
                                  <td dir="RTL">سايدون / كيمينوفا 40% EC</td>
                                  <td dir="LTR">Dimethoate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    75 سم مكعب/20 لتر ماء +5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1344</td>
                                  <td dir="RTL">كونسرف 0.024% CB</td>
                                  <td dir="LTR">Spinosad</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    500 سم مكعب/10لتر ماء
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">1</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">585</td>
                                  <td dir="RTL">ملاتوكس 57% EC</td>
                                  <td dir="LTR">Malathion</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    100 سم مكعب/20 لتر ماء +5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1080</td>
                                  <td dir="RTL">ملاتوكس 50% WP</td>
                                  <td dir="LTR">Malathion</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    100 جم/20 لتر ماء +5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1340</td>
                                  <td dir="RTL">ملاثين 57% EC</td>
                                  <td dir="LTR">Malathion</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    100 سم مكعب/20 لتر ماء +5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1474</td>
                                  <td dir="RTL">نصر ثويت 40% EC</td>
                                  <td dir="LTR">Dimethoate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    100 سم مكعب/20 لتر ماء +5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2027</td>
                                  <td dir="RTL">
                                    نصر لاثيون / كيمينوفا 57% EC
                                  </td>
                                  <td dir="LTR">Malathion</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    100 سم مكعب/20 لتر ماء +5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3100</td>
                                  <td dir="RTL">كوجى بلس 40% EC</td>
                                  <td dir="LTR">Dimethoate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>
                                    75 سم مكعب/20 لتر ماء +5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 4- صانعات الأنفاق */}
                      <Collapse title="4- صانعات الأنفاق">
                        {/* <h2 className="details-page-title subTitle">
                          4- صانعات الأنفاق
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/citrus.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                وجود الأنفاق على أي من سطحي الورقة نتيجة تغذية
                                اليرقات بين بشرتي الورقة والإصابة الشديدة تؤدى
                                إلى جفاف الأوراق وقد تصاب السيقان الغضة حديثة
                                النمو وتظهر فيها الأنفاق بوضوح
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              الإصابة طوال العام ويرتبط وجودها بوجود النموات
                              الغضة الحديثة وكذلك تكثر في المشتل أكثر منها في
                              الأشجار المستديمة.
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور الإصابة.</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1691</td>
                                  <td dir="RTL">اجريميك جولد 8.4% SC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>15 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1978</td>
                                  <td dir="RTL">اكوابريمو35% SC</td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>75 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1251</td>
                                  <td dir="RTL">ديمكتين 1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>25 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">11</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">174</td>
                                  <td dir="RTL">كزد اويل 95% EC</td>
                                  <td dir="LTR">Mineral oil</td>
                                  <td dir="RTL">
                                    1.5 لتر /100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 5- اکاروس الموالح البني */}
                      <Collapse title="5- اکاروس الموالح البني">
                        {/* <h2 className="details-page-title subTitle">
                          5- اکاروس الموالح البني
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/citrus-rust.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ظهور بقع صفراء – على السطح العلوي للأوراق -
                                تتحول إلى اللون البني وتجف وتسقط. وتقل نسبة
                                الكلورفيل في الثمار الخضراء نتيجة امتصاص العصارة
                                وبالتالي تظهر الثمرة بلون باهت وناعم الملمس. أما
                                الثمار الصفراء فتظهر عليها بقع بنية اللون
                                بازدياد الإصابة.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تبدأ الإصابة في مارس وتزداد في يوليو حتى فبراير
                              التالي
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وجود 5 أفراد فأكثر على الورقة من مجموعة
                              الأوراق التي يتم فحصها عشوائيا.
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">2794</td>
                                  <td dir="RTL">اجريفوكس 5% SC</td>
                                  <td dir="LTR">Fenpyroximate</td>
                                  <td dir="RTL">
                                    50سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2717</td>
                                  <td dir="RTL">
                                    افينكس5% EW<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Hexythiazox</td>
                                  <td dir="RTL">
                                    40سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">514</td>
                                  <td dir="RTL">
                                    اورتس<span>&nbsp; </span>5% SC
                                  </td>
                                  <td dir="LTR">Fenpyroximate</td>
                                  <td dir="RTL">
                                    50سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3019</td>
                                  <td dir="RTL">ابنتاروس 5% SC</td>
                                  <td dir="LTR">Fenpyroximate</td>
                                  <td dir="RTL">
                                    50سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2661</td>
                                  <td dir="RTL">اورانشي 10% EC</td>
                                  <td dir="LTR">Hexythiazox</td>
                                  <td dir="RTL">
                                    20سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1672</td>
                                  <td dir="RTL">بيومكتين 5% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    20سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2025</td>
                                  <td dir="RTL">
                                    دمبر 55% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Fenbutatin oxide</td>
                                  <td dir="RTL">
                                    100سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2776</td>
                                  <td dir="RTL">
                                    روتكس 5% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Fenpyroximate</td>
                                  <td dir="RTL">
                                    50سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3037</td>
                                  <td dir="RTL">
                                    ناترال 55% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Fenbutatin oxide</td>
                                  <td dir="RTL">
                                    100سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1548</td>
                                  <td dir="RTL">
                                    ماجنيفيكو 5% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Hexythiazox</td>
                                  <td dir="RTL">
                                    40سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2203</td>
                                  <td dir="RTL">هاى بوينت 10% EC</td>
                                  <td dir="LTR">
                                    Abamectin 1%
                                    <br />
                                    Hexythiazox 9%
                                  </td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*6- عفن السرة */}
                      <Collapse title="6- عفن السرة">
                        {/* <h2 className="details-page-title subTitle">
                          6- عفن السرة
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/3fnelsora.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                إسوداد في منطقة السرة وعند شق الثمرة طوليا يشاهد
                                إمتداد اللون الأسود.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>إبتداء من يونيو.</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>رش وقائي إعتبارا من أول مايو.</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">2113</td>
                                  <td dir="RTL">ديبرو 30% EC</td>
                                  <td dir="LTR">
                                    Difenoconazole 15 %<br />
                                    Propiconazole 15 %
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>40 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2315</td>
                                  <td dir="RTL">كرافت 30% EC</td>
                                  <td dir="LTR">
                                    Difenoconazole 15 %<br />
                                    Propiconazole 15 %
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>40 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1437</td>
                                  <td dir="RTL">مونتورو 30% EC</td>
                                  <td dir="LTR">
                                    Difenoconazole 15 %<br />
                                    Propiconazole 15 %
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>40 سم مكعب/100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1274</td>
                                  <td dir="RTL">هيليوكوفر 62% SC</td>
                                  <td dir="LTR">Copper hydroxide</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp; </span>125 سم مكعب/100
                                    لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*7- اکاروس الموالح الاحمر*/}
                      <Collapse title="7- اکاروس الموالح الاحمر">
                        {/* <h2 className="details-page-title subTitle">
                          7- اکاروس الموالح الاحمر
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/FrutoZ.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                يتواجد هذا النوع على السطح السفلى للأوراق ويسبب
                                إصفرارها ثم ينتقل الى الثمار ويسبب جفافها حدوث
                                تشققات للثمار وتتحول إلى اللون البنى القاتم .
                                ظهور البقع الفضية الباهتة الغائرة (اليسار).
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>مارس إلى أغسطس</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بداية ظهور الإصابة.</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1748</td>
                                  <td dir="RTL">اجنر20% SC</td>
                                  <td dir="LTR">
                                    Abamectin 2%
                                    <br />
                                    Spirodiclofen 18%
                                  </td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1159</td>
                                  <td dir="RTL">اورتس سوبر 5% EC</td>
                                  <td dir="LTR">Fenpyroximate</td>
                                  <td dir="RTL">
                                    50سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*8- اكاروس صدأ الموالح*/}
                      <Collapse title="8- اكاروس صدأ الموالح">
                        {/* <h2 className="details-page-title subTitle">
                          8- اكاروس صدأ الموالح
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/sada22.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ظهور بقع صدئية اللون على السطح السفلي للأوراق
                                يبدأ ظهورها من حافة الورقة ثم بإزدياد الإصابة
                                تعم سطح الورقة السفلي، أما على الثمار فتظهر على
                                أحد جوانبها بقع صدئية اللون صغيرة تبدأ في
                                الزيادة حتى تأخذ شكلا صدئيا حول وسط الثمرة وفي
                                حالة شدة الإصابة تأخذ شكلا صدئيا يعم كل الثمرة
                                وفى حالة الليمون يظهر لونا فضيا على الأوراق
                                والثمار.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تبدأ الإصابة في منتصف شهر مايو وتزداد في يوليو
                              وأغسطس وتقل في شهر أكتوبر، وقد تظهر الإصابة مبكرة
                              جدا إبتداء من شهر يناير.
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وجود 5 أفراد على الورقة أو الثمرة من مجموعة
                              الأوراق أو الثمار التي يتم فحصها عشوائيا.
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">3062</td>
                                  <td dir="RTL">اكروزان 3.2% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">30سم مكعب /100لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1150</td>
                                  <td dir="RTL">انفيدور 24% SC</td>
                                  <td dir="LTR">Spirodiclofen</td>
                                  <td dir="RTL">30سم مكعب /100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1159</td>
                                  <td dir="RTL">اورتس سوبر 5% EC</td>
                                  <td dir="LTR">Fenpyroximate</td>
                                  <td dir="RTL">100سم مكعب /100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3066</td>
                                  <td dir="RTL">بيراميدال 20% WP</td>
                                  <td dir="LTR">Pyridaben</td>
                                  <td dir="RTL">50جم/100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">975</td>
                                  <td dir="RTL">رومكتين 1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">553</td>
                                  <td dir="RTL">
                                    سانميت 20% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Pyridaben</td>
                                  <td dir="RTL">50جم/100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2833</td>
                                  <td dir="RTL">
                                    سبايدر جولد 5% ME<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">20سم مكعب /100لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1990</td>
                                  <td dir="RTL">سترو جارد 15% EC</td>
                                  <td dir="LTR">Pyridaben</td>
                                  <td dir="RTL">200سم مكعب /100لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1959</td>
                                  <td dir="RTL">سم مكعباش 24% SC</td>
                                  <td dir="LTR">Spirodiclofen</td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3133</td>
                                  <td dir="RTL">
                                    سبيريدور 24% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Spirodiclofen</td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3220</td>
                                  <td dir="RTL">
                                    سبيسترا 24% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Spirodiclofen</td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2051</td>
                                  <td dir="RTL">سينو اس 5% SC</td>
                                  <td dir="LTR">Fenpyroximate</td>
                                  <td dir="RTL">100سم مكعب /100لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3107</td>
                                  <td dir="RTL">
                                    ديكلوفين 24% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Spirodiclofen</td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">466</td>
                                  <td dir="RTL">
                                    فير تيميك 1.8% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>

                                <tr>
                                  <td dir="LTR">1400</td>
                                  <td dir="RTL">فيرمكس1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">9</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3058</td>
                                  <td dir="RTL">فاست ماكس بلص 5.4% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    15سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1986</td>
                                  <td dir="RTL">
                                    كونكور24% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Spirodiclofen</td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2297</td>
                                  <td dir="RTL">ليستوميد 24% SC</td>
                                  <td dir="LTR">Spirodiclofen</td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2374</td>
                                  <td dir="RTL">مستر جرين 1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2790</td>
                                  <td dir="RTL">وادنور 24% SC</td>
                                  <td dir="LTR">Spirodiclofen</td>
                                  <td dir="RTL">
                                    30سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1809</td>
                                  <td dir="RTL">هاى كين 5.4% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    15سم مكعب /100لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*9- أعفان ثمار الموالح*/}
                      <Collapse title="9- أعفان ثمار الموالح">
                        {/* <h2 className="details-page-title subTitle">
                          9- أعفان ثمار الموالح
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/PPT.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهر الإصابة على هيئة بقع سوداء علي سطح الثمرة و
                                خاصة نهاية الثمرة. كما يمكن ان تظهر في صورة بقع
                                لينة مائية (طريه) على قشرة الثمرة في موضع
                                الإصابة ثم تتسع هذه البقع بسرعة ويظهر على سطحها
                                نمو فطرى أبيض يتبعه ظهور لون أخضر زيتوني أو أزرق
                                رمادي او لون بني و هو عبارة عن جراثيم الفطريات
                                المسببة للعفن
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              يظهر هذا المرض في الحقل في مرحلة نضج الثمار كما
                              يظهر في المخزن في حالة التخزين تحت ظروف تخزين سيئة
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند بدء ظهور الإصابة في الحقل او بعد الجمع والفرز
                              قبل التخزين.
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1273</td>
                                  <td dir="RTL">تكتو 50% SC</td>
                                  <td dir="LTR">Thiabendazole</td>
                                  <td dir="RTL">
                                    900سم مكعب /100لتر ماء (بعد الجمع )
                                  </td>
                                  <td dir="LTR">2</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 10- التصمغ */}
                      <Collapse title="10- التصمغ">
                        {/* <h2 className="details-page-title subTitle">
                          10- التصمغ
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/TSB8.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                وجود بقع صمغية علي جزع الشجرة لونها بني ذات
                                رائحة مميزة.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>قد تظهر الإصابه فى أي وقت من السنة.</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور الإصابة.</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">45</td>
                                  <td dir="RTL">الييت 80%WP</td>
                                  <td dir="LTR">
                                    <br />
                                    Fosetyl-Aluminium
                                  </td>
                                  <td dir="RTL">
                                    250جم/100لتر ماء (رش)
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">45</td>
                                  <td dir="RTL">الييت 80%WP</td>
                                  <td dir="LTR">
                                    <br />
                                    Fosetyl-Aluminium
                                  </td>
                                  <td dir="RTL">
                                    1كجم/فدان (دهان)
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1553</td>
                                  <td dir="RTL">تيكو 80% WG</td>
                                  <td dir="LTR">
                                    <br />
                                    Fosetyl-Aluminium
                                  </td>
                                  <td dir="RTL">
                                    1كجم/فدان (دهان)
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3326</td>
                                  <td dir="RTL">جيفا ستيل 80%WP</td>
                                  <td dir="LTR">
                                    <br />
                                    Fosetyl-Aluminium
                                  </td>
                                  <td dir="RTL">250 جم/فدان (رش)</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1919</td>
                                  <td dir="RTL">
                                    فاستر توب 80% WP
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    <br />
                                    Fosetyl-Aluminium
                                  </td>
                                  <td dir="RTL">1كجم/فدان (دهان)</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2217</td>
                                  <td dir="RTL">
                                    كاتنجا اكسبريس 80% WP
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    <br />
                                    Fosetyl-Aluminium
                                  </td>
                                  <td dir="RTL">
                                    250جم/100لتر ماء (رش)
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2535</td>
                                  <td dir="RTL">
                                    ميكرفين 80% WP
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    <br />
                                    Fosetyl-Aluminium
                                  </td>
                                  <td dir="RTL">1كجم/فدان (دهان)</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2107</td>
                                  <td dir="RTL">
                                    هكتور 80% WP
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    <br />
                                    Fosetyl-Aluminium
                                  </td>
                                  <td dir="RTL">150جم/100لتر ماء (رش)</td>
                                  <td dir="LTR">3</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 11- الأشنات */}
                      <Collapse title="11- الأشنات">
                        {/* <h2 className="details-page-title subTitle">
                          11- الاشنات
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/ashnat.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                نموات حرشفية مختلفة الألوان (أو صدفية تميل إلى
                                اللون الأصفر المخضر) علي الأفرع والنموات الحديثة
                                تزداد بزيادة الرطوبة.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>في أي وقت من السنة.</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور الإصابة.</p>
                          </li>
                          <li>
                            <h3 className="h5">إرشادات خاصة</h3>
                            <p>
                              ترش النباتات بمجرد ظهور الإصابة ويوقف الرش قبل
                              الحصاد بفترة كافية.
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1571</td>
                                  <td dir="RTL">اسيكوب 85% WP</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL" width="201">
                                    300 جم/ 100 لتر ماء
                                  </td>
                                  <td dir="RTL">10 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1035</td>
                                  <td dir="RTL">اندكس 77% WP</td>
                                  <td dir="LTR">Copper hydroxide</td>
                                  <td dir="RTL" width="201">
                                    350 جم/ 100 لتر ماء
                                  </td>
                                  <td dir="RTL">15 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2323</td>
                                  <td dir="RTL">دوجربيكر 85% WP</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL" width="201">
                                    300 جم/ 100 لتر ماء
                                  </td>
                                  <td dir="RTL">21 يوم</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 12- نيماتودا */}
                      <Collapse title="12-  نيماتودا التدهور البطئ (الغرث الحديث)">
                        {/* <h2 className="details-page-title subTitle">
                          12- نيماتودا
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/7des.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                موت الأفرع الطرفية العلوية – تسلخات على المجموع
                                الجذري – سهولة فصل منطقة القشرة في الجذور عن
                                الحزمة الوعائية – التصاق حبيبات التربة بالجذور
                                الثانوية المغذية.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تظهر الاعراض علي عدد محدود من اشجار الموالح ثم
                              تنتشر الاصابة و تشمل معظم الاشجار بعد عده سنوات
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند تواجد 4000 فرد في 250 جم تربة في بداية الموسم
                              (فبراير ومارس).
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1865</td>
                                  <td dir="RTL">نيماكور 40% EC</td>
                                  <td dir="LTR">
                                    Fenamiphos<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">4 لتر/ فدان (رشتين)</td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">60</td>
                                  <td dir="RTL">نيماكور10% GR</td>
                                  <td dir="LTR">Fenamiphos</td>
                                  <td dir="RTL">25 كجم/ فدان</td>
                                  <td dir="LTR">-</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 13- نيماتودا */}
                      <Collapse title="13-  نيماتودا التدهور البطئ (الشجر المثمر)">
                        {/* <h2 className="details-page-title subTitle">
                          13- نيماتودا
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/02/shgr.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                موت الأفرع الطرفية – تسلخات على المجموع الجذري –
                                سهولة فصل منطقة القشرة في الجذور عن الحزمة
                                الوعائية – التصاق حبيبات التربة بالجذور الثانوية
                                المغذية.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              لا تظهر الأعراض علي أشجار الموالح إلا بعد مرور عدة
                              سنوات من إصابتها على شكل موت الأطراف وجفاف الأفرع
                              العلوية.
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند تواجد 4000 فرد في 250 جم تربة في بداية الموسم
                              (فبراير ومارس).
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1894</td>
                                  <td dir="RTL">جافلين 40% EC</td>
                                  <td dir="LTR">Fenamiphos</td>
                                  <td dir="RTL">3 لتر/ فدان</td>
                                  <td dir="RTL">60 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3020</td>
                                  <td dir="RTL">نيمالاكسى 10% GR</td>
                                  <td dir="LTR">Fosthiazate</td>
                                  <td dir="LTR">12.5 كجم/ فدان</td>
                                  <td dir="RTL">7 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">594</td>
                                  <td dir="RTL">راجبى 10% G</td>
                                  <td dir="LTR">Cadusafos</td>
                                  <td dir="RTL">24 كجم/ فدان</td>
                                  <td dir="RTL">100 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2536</td>
                                  <td dir="RTL">سمارت - ان 40% EC</td>
                                  <td dir="LTR">Ethoprophos</td>
                                  <td dir="RTL">5 لتر/ فدان (رشتين)</td>
                                  <td dir="RTL">35 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">122</td>
                                  <td dir="RTL">فايديت 24% SL</td>
                                  <td dir="LTR">Oxamyl</td>
                                  <td dir="RTL">4 لتر/ فدان (رشتين)</td>
                                  <td dir="RTL">0 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2470</td>
                                  <td dir="RTL">فور نيما 24% SL</td>
                                  <td dir="LTR">Oxamyl</td>
                                  <td dir="RTL">4 لتر/ فدان (رشتين)</td>
                                  <td dir="RTL">60 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2373</td>
                                  <td dir="RTL">كرينكل 75% EC</td>
                                  <td dir="LTR">Fosthiazate</td>
                                  <td dir="RTL">2 لتر/ فدان</td>
                                  <td dir="RTL">80 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3274</td>
                                  <td dir="RTL">توداكيم 24% SL</td>
                                  <td dir="LTR">Oxamyl</td>
                                  <td dir="RTL">3 لتر/ فدان (رشتين)</td>
                                  <td dir="RTL">60 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1442</td>
                                  <td dir="RTL">نيمافوس 40% EC</td>
                                  <td dir="LTR">Fenamiphos</td>
                                  <td dir="RTL">3 لتر/ فدان</td>
                                  <td dir="RTL">150 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2587</td>
                                  <td dir="RTL">نالمس 40% EC</td>
                                  <td dir="LTR">Fenamiphos</td>
                                  <td dir="RTL">3 لتر/ فدان (رشتين)</td>
                                  <td dir="RTL">35 يوم</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3001</td>
                                  <td dir="RTL">لافيدا 24% SL</td>
                                  <td dir="LTR">Oxamyl</td>
                                  <td dir="RTL">4 لتر/ فدان (رشتين)</td>
                                  <td dir="RTL">7 يوم</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
