import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
// import { Button, Collapse, UncontrolledCollapse } from 'reactstrap';
import Collapse from './collapse/collapse';

function Index() {
  const data = {
    labels: ['2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'المواسم',
        data: [26.428, 17.184, 25.692, 9.024, 5.534],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };

  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [83.86, 10008.471],
            offset: [5],
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
    // plugins: [ChartDataLabels],
  };
  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/10/main.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  الخوخ
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    الخوخ ينتمي إلى عائلة الورديات وهي نفس عائلة البرقوق،
                    والمشمش، والكرز، واللوز، ويتميز بمجموعة من الفوائد الصحية
                    مثل تحسين الهضم، والوقاية من أمراض القلب والسرطان، فضلاً عن
                    فوائده الجمالية كترطيب البشرة وغير ذلك ويعود أصل الخوخ إلى
                    شمال غرب الصين، ولكنه يزرع الآن في جميع أنحاء العالم خاصة
                    المناطق ذات المناخ الدافئ . وتحتل مصر المركز الحادى عشر ضمن
                    البلاد السبعة عشر التى تنتج
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات الخوخ من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات الخوخ المصري مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%0.84</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات الخوخ المصري
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- المـن*/}
                      <Collapse title="1- المـن">
                        {/* <h2 className="details-page-title subTitle">
                          1- المـن
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/10/mn.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                يبدأ ظهور الإصابة مع ظهور النموات الحديثة
                                للأوراق وخاصة الطرفية منها مسببة تجعدها
                                والتفافها - الحشرات تفرز الندوة العسلية مما
                                يساعد على تراكم الأتربة على الأوراق فيقل التمثيل
                                الضوئي
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>خلال شهر مارس</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند بداية ظهور الأفراد على النباتات في شهر مارس
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1954</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>تيدو 50% WG
                                  </td>
                                  <td class="xl76" dir="LTR">
                                    Pymetrozine
                                  </td>
                                  <td class="xl69" dir="RTL">
                                    20جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2- العنكبوت الأحمر*/}
                      <Collapse title="2- العنكبوت الأحمر">
                        {/* <h2 className="details-page-title subTitle">
                          2- العنكبوت الأحمر
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/10/3enkbot.png"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                بقع صفراء باهتة على السطح العلوي للأوراق وحول
                                العروق الوسطية وبتقدم الإصابة يحدث أصفرار شامل
                                للأوراق والجفاف والسقوط
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              حسب الظروف البيئية في منطقة الزراعات خلال شهر مارس
                              وشهر إبريل وشهر مايو
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند وجود 5 أفراد فأكثر على الورقة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td dir="LTR">3278</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>اسينابير بلس 24% SC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Chlorfenapyr
                                  </td>
                                  <td dir="RTL">60 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2912</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>بستبير 36% SC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Chlorfenapyr
                                  </td>
                                  <td dir="RTL">40 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1850</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سبيرو 24% SC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Spirodiclofen
                                  </td>
                                  <td dir="RTL">30 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1420</td>
                                  <td dir="RTL">شالنجر سوبر 24% SC</td>
                                  <td dir="LTR" align="left">
                                    Chlorfenapyr
                                  </td>
                                  <td dir="RTL">60 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1673</td>
                                  <td dir="RTL">صن فنبير 24% SC</td>
                                  <td dir="LTR" align="left">
                                    Chlorfenapyr
                                  </td>
                                  <td dir="RTL">60 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2836</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كلوروفين 36% SC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Chlorfenapyr
                                  </td>
                                  <td dir="RTL">50 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2203</td>
                                  <td dir="RTL">هاى بوينت 10% EC</td>
                                  <td dir="LTR" align="left">
                                    Abamectin
                                    <br />
                                    Hexythiazox
                                  </td>
                                  <td dir="RTL">25 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 3- البياض الدقيقي */}
                      <Collapse title="3- البياض الدقيقي ">
                        {/* <h2 className="details-page-title subTitle">
                          3- البياض الدقيقي
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/10/byad.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                نموات بيضاء مسحوقية على جميع أجزاء النبات فوق
                                سطح الأرض (أوراق - أزهار)
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              عند بدء خروج النموات الخضرية والأزهار وعقد الثمار
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور أعراض الاصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">3104</td>
                                  <td dir="RTL" width="156">
                                    <span>&nbsp;</span>انفيكتوس 16% WP
                                  </td>
                                  <td dir="LTR" align="left">
                                    Dithianon
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">150جم/100 لرتماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2961</td>
                                  <td dir="RTL">بوست 30% SC</td>
                                  <td dir="LTR" align="left">
                                    Pyraclostrobin
                                    <br />
                                    Thiophanate-methyl
                                  </td>
                                  <td dir="RTL">50 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3384</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>باشفول 38% WG
                                  </td>
                                  <td dir="LTR" align="left">
                                    Boscalid
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">
                                    50جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2918</td>
                                  <td dir="RTL">
                                    <span>&nbsp;&nbsp;</span>ترولز 25% EW
                                  </td>
                                  <td dir="LTR" align="left">
                                    Tebuconazole
                                  </td>
                                  <td dir="RTL">50 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2150</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>فنجابرو 45% EW
                                  </td>
                                  <td dir="LTR" align="left">
                                    prochloraz
                                  </td>
                                  <td dir="RTL">75 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2735</td>
                                  <td dir="RTL">كلوب 50% WP</td>
                                  <td dir="LTR" align="left">
                                    Difenoconazole Thiophanate-methyl
                                  </td>
                                  <td dir="RTL">
                                    60 جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1581</td>
                                  <td dir="RTL">ليدر 45 %EC</td>
                                  <td dir="LTR" align="left">
                                    prochloraz
                                  </td>
                                  <td dir="RTL">75 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2761</td>
                                  <td dir="RTL">ميجال 45 %W</td>
                                  <td dir="LTR" align="left">
                                    Azoxystrobin
                                  </td>
                                  <td dir="RTL">
                                    16 جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3378</td>
                                  <td dir="RTL">نيو إكس 50 %WG</td>
                                  <td dir="LTR" align="left">
                                    Boscalid
                                  </td>
                                  <td dir="RTL">
                                    100 جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">60</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 4- صدأ الخوخ*/}
                      <Collapse title="4- صدأ الخوخ">
                        {/* <h2 className="details-page-title subTitle">
                          4- صدأ الخوخ
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/10/sda2.png"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهر الأعراض علي هيئة بقع صفراء علي السطح العلوي
                                للورقة يقابلها علي السطح السفلي لون برتقالي
                                عبارة عن تراكمات لجراثيم الفطر التي تتحول إلي
                                اللون البني الداكن مع تطور الاصابه
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>خلال الفترة من شهر مارس إلى شهر إبريل</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>بدايه من شهر مارس</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">2562</td>
                                  <td dir="RTL">دايفورا 40%SC</td>
                                  <td dir="LTR" align="left">
                                    Cyproconazole
                                  </td>
                                  <td dir="RTL">
                                    30 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1604</td>
                                  <td dir="RTL">مايتونيل 25% EC</td>
                                  <td dir="LTR" align="left">
                                    Myclobutanil
                                  </td>
                                  <td dir="RTL">
                                    25 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                      {/* 5- تجعد أوراق الخوخ*/}
                      <Collapse title="5- تجعد أوراق الخوخ">
                        {/* <h2 className="details-page-title subTitle">
                          5- تجعد أوراق الخوخ
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/10/tg3od.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ظهور مناطق داكنة اللون جلدية المظهر متفرقة أكثر
                                سمكا من باقي المناطق على الأوراق وتصبح الورقة
                                متموجة
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>من منتصف مارس</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور أعراض الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1781</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>شامبيون 77% WP
                                  </td>
                                  <td dir="LTR" align="left">
                                    Copper hydroxide
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>180جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
