import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
// import { Button, Collapse, UncontrolledCollapse } from 'reactstrap';
import Collapse from './collapse/collapse';

function Index() {
  const data = {
    labels: ['2014', '2015', '2016', '2017', '2018'],
    datasets: [
      {
        label: 'المواسم',
        data: [61.726, 65.896, 51.547, 23.921, 27.22],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };
  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [203.09, 10792.645],
            offset: [5],
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
    // plugins: [ChartDataLabels],
  };
  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/07/main.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  المانجو
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    تنتشر أشجار المانجو في مصر في الأراضي الخصبة ذات التربة
                    الصفراء وجيدة المياه في الجنوب كما تزرع الأنواع المميزة منها
                    في الإسماعيلية والشرقية والبحيرة ولذا تلقب المانجو بملك
                    الفواكة . وتؤكل المانجو طازجة أو تستخدم كعصير وهي غنية
                    بفيتامين أ، ج وزرعت أشجار المانجو أول مرة قبل 4000 سنة في
                    الهند وأرخبيل الملايو، ونقلها المستكشفون الأوروبيون في
                    القرنين الثامن عشر والتاسع عشر الميلادي إلى مناطق مدارية
                    أخرى، وتزرع الآن في مناطق عديدة، مثل البرازيل، والهند،
                    والمكسيك، والفلبين، والولايات المتحدة وتايلاند.وتعتبر شجرة
                    المانجو دائمة الخضرة، وقد يصل طولها إلى 20 مترا، ولها أوراق
                    طويلة وأزهار ذات لون أبيض وبنفسجي، وتنمو ثمار المانجو من قاع
                    مبايض الزهرات، ويكتمل نموها بعد خمسة أشهر من تكون الأزهار.
                    إن صادرات مصر من المانجو واعدة، خاصة بعد فتح السوق الصينية،
                    مؤكدًا أن العام الماضي شهد تصدير 50 ألف و590 طنا. والدول
                    التى تستورد المانجو من مصر تأتى فى مقدمتها السعودية بمتوسط
                    13 ألف طن، تليها الأردن 6 آلاف طن، وروسيا 3500 طن ثم كل من
                    الإمارات والكويت والبحرين بنحو 3000 طن لكل دولة وأخيرا لبنان
                    1200 طن، كما تم التصدير إلى أكثر من 50 دولة وأهم أسواق
                    التصدير، هي أوروبا والشرق الأوسط وآسيا وروسيا.و المانجو من
                    أكثر المحاصيل مساحة في مصر، وترتيبها الثالث بعد الموالح
                    والعنب، كما أنها رابع أكبر محصول تصديري من الفواكه بعد
                    الموالح والعنب والرمان.
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات المانجو من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات المانجو المصرية مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%1.88</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات المانجو المصرية
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- البق الدقيقى */}
                      <Collapse title="1- البق الدقيقى">
                        {/* <h2 className="details-page-title subTitle">
                          1- البق الدقيقى
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/07/Mealybug.png"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                وجود الحشرات مغطاه بإفرازات شمعية بيضاء- نمو
                                الفطر الأسود تواجد النمل الذي يتغذى على الإفراز
                                العسلي للحشرات.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              الإصابة طوال العام وتقل في فصل الشتاء حيث تختفى
                              الأطوار المتوسطة البالغة في الشقوق في المناطق
                              السفلي من الشجرة.
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند إصابة حوالي 5 % من عدد الأشجار وتؤخذ في
                              الإعتبار نسبة الأوراق .
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">236</td>
                                  <td dir="RTL">مصرونا 85% مايونيز</td>
                                  <td dir="LTR">Mineral Oil</td>
                                  <td dir="RTL">
                                    2 لتر / 100 لتر ماء ( رش شتوى)
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2- الحشرات القشرية*/}
                      <Collapse title="2- الحشرات القشرية">
                        {/* <h2 className="details-page-title subTitle">
                          2- الحشرات القشرية
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/07/scabies.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تقوم الإناث والحوريات بإمتصاص العصارة من الورقة
                                مما يؤدى إلى ظهور اللون الأصفر بالأوراق -
                                بالنسبة للحشرات القشرية االرخوة يكون مظهر
                                الإصابة مصحوبا بفطر العفن الأسود الذي ينمو على
                                الندوة العسلية التي تفرزها الحشرات.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تتواجد الإصابة طوال العام وتشتد من يونيو حتى
                              أغسطس.
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند بلوغ الإصابة للأوراق بالشجرة الواحدة 10% وعند
                              إصابة 10% من الأشجار بالحديقة من مجموعة الأوراق أو
                              الأشجار التي يتم فحصها عشوائيا.
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td dir="LTR">3318</td>
                                  <td dir="RTL">أوراكيل 20% SP</td>
                                  <td dir="LTR">Dinotefuran</td>
                                  <td dir="RTL">
                                    100 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1767</td>
                                  <td dir="RTL">تايجر 97% EC</td>
                                  <td dir="LTR">Mineral Oil</td>
                                  <td dir="RTL">
                                    1.5 لتر / 100 لتر ماء (رش صيفي)
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1039</td>
                                  <td dir="RTL">دايفر 97% EC</td>
                                  <td dir="LTR">Mineral Oil</td>
                                  <td dir="RTL">
                                    1.5 لتر / 100 لتر ماء (رش صيفي)
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">89</td>
                                  <td dir="RTL">سوبر رويال 95% EC</td>
                                  <td dir="LTR">Mineral Oil</td>
                                  <td dir="RTL">
                                    1.5 لتر / 100 لتر ماء (رش صيفي)
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3294</td>
                                  <td dir="RTL">سينامون أويل 97% EC</td>
                                  <td dir="LTR">Mineral Oil</td>
                                  <td dir="RTL">
                                    1.5 لتر / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">236</td>
                                  <td dir="RTL">مصرونا 85% مايونيز</td>
                                  <td dir="LTR">Mineral Oil</td>
                                  <td dir="RTL">
                                    2.5 لتر / 100 لتر ماء (رش شتوى)
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 3- ذبابة الفاكهة */}
                      <Collapse title="3- ذبابة الفاكهة">
                        {/* <h2 className="details-page-title subTitle">
                          3- ذبابة الفاكهة
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/07/fruitfly.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ظهور وخزات في جسم الثمرة مع سائل صمغي في الثمار
                                الغير تامة النضج ولين في منطقة الوخز وخروج
                                العصارة بالضغط عليها.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>في شهري يوليو وأغسطس</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">إرشادات خاصة</h3>
                            <p>
                              يجب التخلص من الثمار المتساقطة بالدفن أو الحرق
                              فورا.
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">738</td>
                                  <td dir="RTL">دايمتوكس 40% EC</td>
                                  <td dir="LTR">Dimethoate</td>
                                  <td dir="RTL">
                                    75 سم مكعب / 20 لتر ماء + 5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">83</td>
                                  <td dir="RTL">روجر-ل 40% EC</td>
                                  <td dir="LTR">Dimethoate</td>
                                  <td dir="RTL">
                                    75 سم مكعب / 20 لتر ماء + 5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">585</td>
                                  <td dir="RTL">ملاتوكس 57% EC</td>
                                  <td dir="LTR">Malathion</td>
                                  <td dir="RTL">
                                    100 سم مكعب / 20 لتر ماء + 5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1080</td>
                                  <td dir="RTL">ملاتوكس 50% WP</td>
                                  <td dir="LTR">Malathion</td>
                                  <td dir="RTL">
                                    100 سم مكعب / 20 لتر ماء + 5% مادة جاذبة
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 4-  البياض الدقيقي */}
                      <Collapse title="4- البياض الدقيقي ">
                        {/* <h2 className="details-page-title subTitle">
                          4- البياض الدقيقي
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/07/byaddqeq.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                مسحوق أبيض دقيقي على الأوراق الحديثة والشماريخ
                                الزهرية والثمار الصغيرة يؤدى إلى موت الأنسجة
                                المصابة وتلونها باللون البنى .
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>منذ خروج الأوراق والشماريخ الحديثة.</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء الإصابة.</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">603</td>
                                  <td dir="RTL">اتش سلفر 80% WP</td>
                                  <td dir="LTR">Sulfer</td>
                                  <td dir="RTL">
                                    250 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1781</td>
                                  <td dir="RTL">اسكودا 25% SC</td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">
                                    45 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2585</td>
                                  <td dir="RTL">إليمينات 30% SC</td>
                                  <td dir="LTR">
                                    Azoxystrobin 12%
                                    <br />
                                    Difenoconazole 18%
                                  </td>
                                  <td dir="RTL">
                                    50 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2220</td>
                                  <td dir="RTL">انتاليا 25% EC</td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">
                                    50 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1825</td>
                                  <td dir="RTL">ايكوبرو 25% EC</td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    20 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">20</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3314</td>
                                  <td dir="RTL">
                                    اكواميثيل 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Thiophanate-methyl</td>
                                  <td dir="RTL">
                                    60 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">90</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2112</td>
                                  <td dir="RTL">بروبيماكس 25% EC</td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    15 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">19</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2631</td>
                                  <td dir="RTL">
                                    بروبيكا 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    15 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2891</td>
                                  <td dir="RTL">بيستول 70% WP</td>
                                  <td dir="LTR">Thiophanate-methyl</td>
                                  <td dir="RTL">
                                    60 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1862</td>
                                  <td dir="RTL">بيلا رتوب إم 70% WP</td>
                                  <td dir="LTR">Thiophanate-methyl</td>
                                  <td dir="RTL">
                                    60 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1438</td>
                                  <td dir="RTL">بينازول 10% EC</td>
                                  <td dir="LTR">Penconazole</td>
                                  <td dir="RTL">
                                    25 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1088</td>
                                  <td dir="RTL">
                                    بيوزيد 2.5% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Trichoderma album</td>
                                  <td dir="RTL">
                                    250 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">20</td>
                                  <td dir="RTL">تلت 25% EC</td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    15 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">19</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2897</td>
                                  <td dir="RTL">تلتك سوبر 25% EC</td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    20 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">247</td>
                                  <td dir="RTL">
                                    توبسين إم 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Thiophanate-methyl</td>
                                  <td dir="RTL">
                                    60 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1931</td>
                                  <td dir="RTL">
                                    تومى 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Thiophanate-methyl</td>
                                  <td dir="RTL">
                                    60 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2737</td>
                                  <td dir="RTL">
                                    توبست 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Thiophanate-methyl</td>
                                  <td dir="RTL">
                                    65 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2043</td>
                                  <td dir="RTL">تيليوزد 25% EC</td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    15 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1549</td>
                                  <td dir="RTL">
                                    ثيوفكس 80% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Sulfer</td>
                                  <td dir="RTL">
                                    250 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1024</td>
                                  <td dir="RTL">
                                    ثيوفيت جيت 80% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Sulfer</td>
                                  <td dir="RTL">
                                    250 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2800</td>
                                  <td dir="RTL">
                                    دوفيكس 50% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin 20%
                                    <br />
                                    Tebuconazole 30%
                                  </td>
                                  <td dir="RTL">
                                    25 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1731</td>
                                  <td dir="RTL">ديمكس 70% WP</td>
                                  <td dir="LTR">Thiophanate-methyl</td>
                                  <td dir="RTL">
                                    65 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr height="60">
                                  <td height="60" dir="LTR">
                                    2098
                                  </td>
                                  <td dir="RTL">دينوكسي 32.5 % SC</td>
                                  <td dir="LTR">
                                    Azoxystrobin 20%
                                    <br />
                                    Difenoconazole 12.5%
                                  </td>
                                  <td dir="RTL">
                                    60 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2823</td>
                                  <td dir="RTL">رنتوب 70% WP</td>
                                  <td dir="LTR">Thiophanate-methyl</td>
                                  <td dir="RTL">
                                    60 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1823</td>
                                  <td dir="RTL">
                                    زول 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    15 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3142</td>
                                  <td dir="RTL">
                                    زول يونج 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    15 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">120</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2584</td>
                                  <td dir="RTL">
                                    سان جولد 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">
                                    50 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2167</td>
                                  <td dir="RTL">
                                    ساوبولو 80% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Sulfer</td>
                                  <td dir="RTL">
                                    250 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1950</td>
                                  <td dir="RTL">سوريل ميكرونى كزد 70% WP</td>
                                  <td dir="LTR">Sulfer</td>
                                  <td dir="RTL">
                                    250 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1380</td>
                                  <td dir="RTL">سيسثين 24% EC</td>
                                  <td dir="LTR">Myclobutanil</td>
                                  <td dir="RTL">
                                    22 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3204</td>
                                  <td dir="RTL">
                                    ستونش 40% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Myclobutanil</td>
                                  <td dir="RTL">
                                    15 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">90</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2183</td>
                                  <td dir="RTL">فاجن 25% EC</td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    15 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">19</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">521</td>
                                  <td dir="RTL">
                                    فيكترا 10% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Bromuconazole</td>
                                  <td dir="RTL">
                                    40 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">20</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3148</td>
                                  <td dir="RTL">
                                    فاست برو 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Thiophanate-methyl</td>
                                  <td dir="RTL">
                                    60 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1319</td>
                                  <td dir="RTL">
                                    كراون 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    15 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1023</td>
                                  <td dir="RTL">
                                    هستا 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Thiophanate-methyl</td>
                                  <td dir="RTL">
                                    65 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2230</td>
                                  <td dir="RTL">
                                    يورو زولكس 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    17 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2328</td>
                                  <td dir="RTL">براكس 25% EC</td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    17 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">20</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1619</td>
                                  <td dir="RTL">نصر زول 25% EC</td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    15 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">20</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1546</td>
                                  <td dir="RTL">
                                    بيلزول 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Propiconazole</td>
                                  <td dir="RTL">
                                    15 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3056</td>
                                  <td dir="RTL">جامورا 75% WG</td>
                                  <td dir="LTR">
                                    Trifloxystrobin 25%
                                    <br />
                                    Tebuconazole 50% <br />
                                  </td>
                                  <td dir="RTL">
                                    25 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">120</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 5- لفحة الأزهار )الأنثراكنوز(*/}
                      <Collapse title="5- لفحة الأزهار (الأنثراكنوز)">
                        {/* <h2 className="details-page-title subTitle">
                          5- لفحة الأزهار )الأنثراكنوز(
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/07/zahra.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ذبول الشماريخ الزهرية وموت الأزهار لمصابة
                                وتلونها باللون البني ثم الأسود.
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>منذ أوائل ابريل.</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور الإصابة.</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1781</td>
                                  <td dir="RTL">اسكودا 25% SC</td>
                                  <td dir="LTR">
                                    Azoxystrobin<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>45 سم مكعب / 100 لتر ماء
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">298</td>
                                  <td dir="RTL">
                                    بولير ام ( دى اف ) 80% DF<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Metiram</td>
                                  <td dir="RTL">
                                    400 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3104</td>
                                  <td dir="RTL">
                                    أنفيكتوس 16% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Dithianon 12%
                                    <br />
                                    Pyraclostrobin 4%
                                  </td>
                                  <td dir="RTL">
                                    150 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">30</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1560</td>
                                  <td dir="RTL">ناتيفو 75% WG</td>
                                  <td dir="LTR">
                                    Trifloxystrobin 25%
                                    <br />
                                    Tebuconazole 50% <br />
                                  </td>
                                  <td dir="RTL">
                                    30 جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">945</td>
                                  <td dir="RTL">
                                    سكور 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">
                                    50 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">30</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">640</td>
                                  <td dir="RTL">
                                    كوسيد (2000) 53.8% DF<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Copper hydroxide</td>
                                  <td dir="RTL">
                                    300جم / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">21</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2574</td>
                                  <td dir="RTL">
                                    نصر ستروبين 23% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
