import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
// import { Button, Collapse, UncontrolledCollapse } from 'reactstrap';
import Collapse from './collapse/collapse';

function Index() {
  const data = {
    labels: ['2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'المواسم',
        data: [59.966, 108.638, 161.671, 25.31, 13.589],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };

  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [369, 41726.69],
            offset: [5],

            // tooltip: {
            //   callbacks: {
            //     label: function (context) {
            //       let label = context.label;
            //       let value = context.formattedValue;

            //       if (!label) label = 'Unknown';

            //       let sum = 0;
            //       let dataArr = context.chart.data.datasets[0].data;
            //       dataArr.map((data) => {
            //         sum += Number(data);
            //       });

            //       const x = value.replace(/[,]/g, '');
            //       let percentage = ((x * 100) / sum).toFixed(2) + '%';
            //       return label + ': ' + percentage;
            //     },
            //   },
            // },
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
        // datalabels: {
        //   formatter: (value, dnct1) => {
        //     let sum = 0;
        //     let dataArr = dnct1.chart.data.datasets[0].data;
        //     dataArr.map((data) => {
        //       sum += data;
        //     });
        //     let percentage = ((value * 100) / sum).toFixed(2) + '%';
        //     return percentage;
        //   },
        //   display: true,
        //   color: 'black',
        //   align: 'end',
        //   // rotation: [10, 20],
        //   padding: {
        //     right: 200,
        //   },
        //   textAlign: 'center',
        //   labels: {
        //     padding: { top: 10 },
        //     title: {
        //       font: {
        //         weight: 'bold',
        //       },
        //     },
        //     value: {
        //       color: 'green',
        //     },
        //   },
        //   // align: 'center',
        //   // anchor: 'right',
        //   // offset: [10, 6],
        //   // textAlign: 'end',
        //   // padding: {
        //   //   right: [5, 10],
        //   // },
        // },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
    // plugins: [ChartDataLabels],
  };
  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/09/main.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  الطماطم
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    تعد الطماطم من أهم المحاصيل التصدرية في مصر . وتعتبر مصرمن
                    أهم الدول المنتجة للطماطم ، حيث تحتل المركز الخامس على مستوى
                    العالم في إنتاج الطماطم ، لهذا يتم تصدير الطماطم المصرية لكل
                    دول العالم وفق شروط تصدير الحاصلات الزراعية الموضوعة من قبل
                    الحجرالزراعي المصري . وتتم زراعة الطماطم في ثلاثة عروات "
                    العروة الصيفية والعروة النيلية والعروة الشتوية " . وتعد
                    محافظة الأقصر من أكثرالمحافظات زراعة للطماطم في مصر.
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات الطماطم من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات الطماطم المصرية مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%0.88</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات الطماطم المصرية
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- الدودة القارضة*/}
                      <Collapse title="1- الدودة القارضة">
                        {/* <h2 className="details-page-title subTitle">
                          1- الدودة القارضة
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/qarda.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تشتد الإصابة في العروة الشتوية وفي الربيع -
                                ويظهر قرض تام في سوق النباتات عند مستوى سطح
                                التربة - يحدث موت للنباتات المصابة خاصة في طور
                                البادرة وتشاهد اليرقات المتكورة أسفل النباتات
                                المصابة وعادة تكون الإصابة في بؤر من الحقل
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>أثناء طور البادرة وحتى تتخشب السوق</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr height="39">
                                  <td height="39" dir="LTR">
                                    845
                                  </td>
                                  <td dir="RTL">
                                    سوبر ألفا 10% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Alpha-Cypermethrin<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250م مكعب / فدان ( طعم سام )
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1680</td>
                                  <td dir="RTL">
                                    كفروثرين 2.5% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Deltamethrin<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250م مكعب / فدان ( طعم سام )
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">60</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2-الذبابة البيضاء*/}
                      <Collapse title="2-الذبابة البيضاء">
                        {/* <h2 className="details-page-title subTitle">
                          2-الذبابة البيضاء
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/zobabAbyd.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تتمثل الأضرار المباشرة في تجعد الأوراق وإصفرارها
                                وذبول وضعف عام عند شدة الإصابة - ظهور ندوة عسلية
                                في حالة زيادة التعداد - ظهور أعراض المرض
                                الفيروسي TYLC بعد نقل الشتلات للأرض المستديمة
                                بمدة تتراوح بين 30.20 يوما تبعا لدرجات الحرارة
                                وتتمثل في تجعد والتفاف الأوراق وإصفرارها وتقزم
                                النباتات وتشوهها وقلة الإزهار والعقد وصغر حجم
                                الثماروعدم نضجها وإنخفاض حاد في المحصول
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              إبتداء من شهر مايو وحتى آخرشهرنوفمبر في المشاتل
                              والحقول
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              يجب منع الحشرات الكاملة من إصابة المشاتل تماما.
                              ويجب الإستمرار في مكافحة الحشرة في الحقول وخاصة
                              بعد نقل الشتلات ولمدة 45 يوما على الأقل وذلك
                              لمكافحة إنتشار فيروس تجعد والتفاف واصفرار أوراق
                              الطماطم
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td dir="LTR">1820</td>
                                  <td dir="RTL">
                                    اجرى فليكس 18.56% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Abamectin
                                    <span>&nbsp;</span>
                                    <br />
                                    Thiamethoxam<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    240م مكعب / فدان<span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2472</td>
                                  <td dir="RTL">
                                    اس النصر 20% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">25 جم / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2412</td>
                                  <td dir="RTL">
                                    اسيتا اكس 20% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">25 جم / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2813</td>
                                  <td dir="RTL">
                                    اسيتاكيل 20% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">25 جم / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1311</td>
                                  <td dir="RTL">
                                    اسيتامور 20% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">25 جم / 100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">555</td>
                                  <td dir="RTL">
                                    افيسكت اس 50% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    thiocyclam hydrogen oxalate
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">500جم / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1991</td>
                                  <td dir="RTL">
                                    افينيو 70% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Imidacloprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">120 حم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2591</td>
                                  <td dir="RTL">
                                    الفادور 70% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Imidacloprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">200جم / فدان</td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1003</td>
                                  <td dir="RTL">
                                    اكتارا 25% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Thiamethoxam<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    350جم / فدان ( معامله تربيه )
                                  </td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2548</td>
                                  <td dir="RTL">
                                    اكسبلان 20% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="RTL">25 جم / 100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1434</td>
                                  <td dir="RTL">
                                    اميدامكس 70% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Imidacloprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">30 جم / 100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2440</td>
                                  <td dir="RTL">
                                    انفينيتى 5% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Etofenprox<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">175 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1999</td>
                                  <td dir="RTL">
                                    اودكس 70% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">50 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1091</td>
                                  <td dir="RTL">
                                    اوشين 20% SG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Dinotefuran<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">125 جم / 100 لتر ماء</td>
                                  <td dir="LTR">1</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2458</td>
                                  <td dir="RTL">
                                    اويكوس 3.2% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Azadirachtin<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">100 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">1</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1263</td>
                                  <td dir="RTL">
                                    ايماكسي 35% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1339</td>
                                  <td dir="RTL">
                                    ايميدازد 20% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Imidacloprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">125 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3139</td>
                                  <td dir="RTL">
                                    اسيتاكام 20% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">25 جم / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3158</td>
                                  <td dir="RTL">
                                    ايسانس 10% EW<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Etofenprox
                                    <span>&nbsp;</span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">187.5 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3177</td>
                                  <td dir="RTL">
                                    اسيتا جولد 20% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">25 جم / 100 لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3061</td>
                                  <td dir="RTL">
                                    انوفر 50% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Pymetrozine<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="RTL">125 جم / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3227</td>
                                  <td dir="RTL">يكاتو 35% SC</td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3081</td>
                                  <td dir="RTL">
                                    بلسيفا ستار 60% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Cyantraniliprole
                                    <span>&nbsp; </span>
                                    <br />
                                    Pymetrozine <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    210 جم / فدان<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1688</td>
                                  <td dir="RTL">
                                    بريمو 10% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Etofenprox<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">187.5 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1148</td>
                                  <td dir="RTL">
                                    بست 25% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Imidacloprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1028</td>
                                  <td dir="RTL">بستدور 25% WP</td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>100 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">30</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2379</td>
                                  <td dir="RTL">
                                    بل جرين 20% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2618</td>
                                  <td dir="RTL">بلانتا 10% Ec</td>
                                  <td dir="LTR">Pyriproxyfen</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1663</td>
                                  <td dir="RTL">بلاش 48% SC</td>
                                  <td dir="LTR">Thiacloprid</td>
                                  <td dir="RTL">120 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2385</td>
                                  <td dir="RTL">
                                    بنيفيا 10% OD<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Cyantraniliprole<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2056</td>
                                  <td dir="RTL">بيلوت 25% WG</td>
                                  <td dir="LTR">Thiamethoxam</td>
                                  <td dir="RTL">80 جم/ فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1625</td>
                                  <td dir="RTL">
                                    بيليكسام 25% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Thiamethoxam<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>30 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3043</td>
                                  <td dir="RTL">
                                    بايدور 70% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Imidacloprid
                                    <span>&nbsp;</span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">120 جم / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3072</td>
                                  <td dir="RTL">
                                    توراس 35% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Imidacloprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">200 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">513</td>
                                  <td dir="RTL">تريبون 30% EC</td>
                                  <td dir="LTR">Etofenprox</td>
                                  <td dir="RTL">250 سم مكعب / فدان</td>
                                  <td dir="LTR">1</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3028</td>
                                  <td dir="RTL">
                                    سيلفادو 20% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3361</td>
                                  <td dir="RTL">
                                    سترونجر كيلر 35% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Imidacloprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1235</td>
                                  <td dir="RTL">
                                    تشيس 50% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Pymetrozine<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">240 جم / فدان</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2006</td>
                                  <td dir="RTL">
                                    تلفاست 20% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2545</td>
                                  <td dir="RTL">
                                    تولان 20% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3116</td>
                                  <td dir="RTL">لافا 20% SP</td>
                                  <td dir="LTR">
                                    Acetamiprid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2092</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>جات فاست 12% SC
                                  </td>
                                  <td dir="LTR">
                                    Abamectin
                                    <br />
                                    Thiamethoxam
                                  </td>
                                  <td dir="RTL">200 سم مكعب / فدان</td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1928</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>راوتر - اكس 70% WG`
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>30 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1832</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>رينوفا 25% WG
                                  </td>
                                  <td dir="LTR">Thiamethoxam</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2907</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سوبريد 20% SP
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2223</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سيزاماك 20% SP
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2746</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>فورتين 20% WG
                                  </td>
                                  <td dir="LTR">Dinotefuran</td>
                                  <td dir="RTL">125 جم / 100 لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1221</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>
                                    <br />
                                    فولى 20% SP
                                    <br />
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2095</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>فى سترو 35% SC
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2611</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>فينول 20% SP
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2621</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>فيتارا 25% WG
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Thiamethoxam
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>30 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1149</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كاليبسو 48% SC
                                  </td>
                                  <td dir="LTR">Thiacloprid</td>
                                  <td dir="RTL">120 سم مكعب / فدان</td>
                                  <td dir="LTR">4</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1776</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كذابلان 20% SP
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2352</td>
                                  <td dir="RTL">كلوزر 24% SC</td>
                                  <td dir="LTR">Sulfoxaflor</td>
                                  <td dir="RTL">
                                    100 جم / فدان<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2236</td>
                                  <td dir="RTL">كوبرا 20% SL</td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    100 جم / فدان<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1267</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كوماندو 35% SC
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1577</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كوندوبريد 35% SC
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Imidacloprid
                                  </td>
                                  <td dir="RTL">120 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1486</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كونفيدور 20% OD
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Imidacloprid
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1647</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>ليكس 25% WG
                                  </td>
                                  <td dir="LTR">Thiamethoxam</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2640</td>
                                  <td dir="RTL">ماجنوك70% WG</td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Imidacloprid
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>70جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2859</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كامارو 5% EC
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2465</td>
                                  <td dir="RTL">موسبيلان 20% SG</td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">959</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>موسبيلان 20% SP
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">1</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1757</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>موفينتو 10% SC
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Spirotetramat
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2662</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>ميرك سوبر 70% WG
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Imidacloprid
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>30 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2688</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>ميدال 25% WG
                                  </td>
                                  <td dir="LTR">Thiamethoxam</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3186</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>مولان 70% WG
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">50 جم / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2880</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>هاى بيكسي 70% WG
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">120 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3013</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>هنك 25% SC
                                  </td>
                                  <td dir="LTR">Buprofezin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">4</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2235</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>وارنت 70% WG
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2414</td>
                                  <td dir="RTL">ياك35% Sc</td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3094</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>فيندكس 30% WP
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">100 جم / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3075</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سنايبر عالمية 35.5% SC
                                  </td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3328</td>
                                  <td dir="RTL">أجربريايد 70% Wg</td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>30 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3334</td>
                                  <td dir="RTL">كريبست 35% SC</td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">75 سم مكعب / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3291</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سيفانتو برايم 20% SL
                                  </td>
                                  <td dir="LTR">flupyradifurone</td>
                                  <td dir="RTL">240 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3319</td>
                                  <td dir="RTL">كافالو 20% SP</td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3343</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سباسرايد 40% SP
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 3- المـن*/}
                      <Collapse title="3- المـن">
                        {/* <h2 className="details-page-title subTitle">
                          3- المـن
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/mn.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تنتشر الأفراد من مختلف أعمار الآفة على السطح
                                السفلي للأوراق وتتغذى بامتصاص العصارة وعند
                                اشتداد الإصابة تظهر الندوة العسلية التي
                                ينموعليها فطر العفن الأسود
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              من نهاية شهر مارس حتى شهر مايو وخلال شهري أغسطس
                              وسبتمبر
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عندما تصل الإصابة إلى 1-2 فرد/نبات من النباتات
                              التي يتم فحصها عشوائيا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">3199</td>
                                  <td dir="RTL">ايكا 20% SP</td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2506</td>
                                  <td dir="RTL">بوتيغون 20% SP</td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3166</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>ستار بريد 20% SP
                                  </td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3193</td>
                                  <td dir="RTL">رينوكيد 50% WG</td>
                                  <td dir="LTR">Pirimicarb</td>
                                  <td dir="RTL">200 حم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1221</td>
                                  <td dir="RTL">فولى20% SP</td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1066</td>
                                  <td dir="RTL">كونفيديت 35% SC</td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3282</td>
                                  <td dir="RTL">كوندور فاست 35% SC</td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1260</td>
                                  <td dir="RTL">ماليت 35% SC</td>
                                  <td dir="LTR">Imidacloprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 4- حشرة توتا ابسليوتا*/}
                      <Collapse title="4- حشرة توتا ابسليوتا">
                        {/* <h2 className="details-page-title subTitle">
                          4- حشرة توتا ابسليوتا
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/tota.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تتغذى اليرقات صانعة أنفاقا في الأوراق وفي الثمار
                                مسببه تلفها وتتعذر اليرقات في التربة
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              في العروة الصيفية بداية من شهر إبريل وفي العروة
                              النيلية بداية من شهر سبتمبر
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند وصول أعداد الأنفاق 3.2 نفق في الورقة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1364</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>اجري 50% WG
                                  </td>
                                  <td dir="LTR">Bacillus thuringiensis</td>
                                  <td dir="RTL">500 جم/ فدان</td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1253</td>
                                  <td dir="RTL">أفانت 15% EC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">100 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1109</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>افيرم 5% SG
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">120 جم / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1426</td>
                                  <td dir="RTL">اكسلنت 9.1% EC</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">300 سم مكعب / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1394</td>
                                  <td dir="RTL">اكيو 10%EC</td>
                                  <td dir="LTR">Novaluron</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1829</td>
                                  <td dir="RTL">الفريدى24% SC</td>
                                  <td dir="LTR">Metaflumizone</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>100 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2480</td>
                                  <td dir="RTL">اندوبرميم30/%WG</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>15 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1884</td>
                                  <td dir="RTL">امياست 5% SG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">120 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2639</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>فوليام فليكسى 30% SC
                                  </td>
                                  <td dir="LTR">
                                    Chlorantraniliprole
                                    <br />
                                    Thiamethoxam
                                  </td>
                                  <td dir="RTL">120 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1753</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>فوليام فليكسى 40% WG
                                  </td>
                                  <td dir="LTR">
                                    Chlorantraniliprole
                                    <br />
                                    Thiamethoxam
                                  </td>
                                  <td dir="RTL">80 جم / فدان</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1388</td>
                                  <td dir="RTL">تاكومى 20%WG</td>
                                  <td dir="LTR">Flubendiamide</td>
                                  <td dir="RTL">50 جم / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1910</td>
                                  <td dir="RTL">تريجارد 75% WP</td>
                                  <td dir="LTR">Cyromazine</td>
                                  <td dir="RTL">50 جم / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2396</td>
                                  <td dir="RTL">توبست رايد 40% SP</td>
                                  <td dir="LTR">Acetamiprid</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1245</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>دايبل دى اف 6.4% DF
                                  </td>
                                  <td dir="LTR">Bacillus thuringiensis</td>
                                  <td dir="RTL">400 جم / فدان</td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1226</td>
                                  <td dir="RTL">دميريون 10% EC</td>
                                  <td dir="LTR">Hexaflumuron</td>
                                  <td dir="RTL">200 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1329</td>
                                  <td dir="RTL">رادينت 12% SC</td>
                                  <td dir="LTR">Spinetoram</td>
                                  <td dir="RTL">100 سم مكعب / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2188</td>
                                  <td dir="RTL">كام يرون 10% EC</td>
                                  <td dir="LTR"></td>
                                  <td dir="RTL">200 سم مكعب / فدان</td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1404</td>
                                  <td dir="RTL">كوراجن 20% SC</td>
                                  <td dir="LTR">Chlorantraniliprole</td>
                                  <td dir="RTL">60 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2325</td>
                                  <td dir="RTL">ليفوكيم 5% EC</td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>80 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3071</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>انرتاسبت 24%SC
                                  </td>
                                  <td dir="LTR">Metaflumizone</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>100 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3034</td>
                                  <td dir="RTL">توتا كارب 5.14%SC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">100 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3212</td>
                                  <td dir="RTL">تكنو اندوكس 15%SC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 5- دودة ورق القطن*/}
                      <Collapse title="5- دودة ورق القطن">
                        {/* <h2 className="details-page-title subTitle">
                          5- دودة ورق القطن
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/dodtelqotn.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهر التغذية على بشرة السطح السفلي للأوراق في
                                مساحات دائرية بواسطة الفقس الحديث - في حالة
                                الإصابة باليرقات الكبيرة تقرض الأوراق مباشرة
                                وتعمل ثقوبا وتتغذى في بعض الأحيان على الثمار
                                والقمم النامية
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p> طوال العام في المشاتل وفي الأرض المستديمة</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">2689</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>ابسولين 5% ME
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">75 سم مكعب / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2406</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>ابهولد 36% SC
                                  </td>
                                  <td dir="LTR">
                                    Methoxyfenozide
                                    <br />
                                    Spinetoram
                                  </td>
                                  <td dir="RTL">125 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1109</td>
                                  <td dir="RTL">أفريم 5% SG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1426</td>
                                  <td dir="RTL">اكسلنت 1.9% EC</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">250 سم مكعب / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2911</td>
                                  <td dir="RTL">أالاسكا 7. 5% WG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2549</td>
                                  <td dir="RTL">الفانت 20% SL</td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">1.25 لتر / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1524</td>
                                  <td dir="RTL">اليكتور 2% EC</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2893</td>
                                  <td dir="RTL">امباكو 5.7% WG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2011</td>
                                  <td dir="RTL">أندروس 7. 5% WG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2442</td>
                                  <td dir="RTL">اندروس إل 1.9% EC</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">250 سم مكعب / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2531</td>
                                  <td dir="RTL">ايفوكت 507% WG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1587</td>
                                  <td dir="RTL">اميازوات 2.15% EC</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">150 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1884</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>ايماست 5% SG
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2387</td>
                                  <td dir="RTL">اميى- ماينر5.7% WG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1237</td>
                                  <td dir="RTL">باشا 1.9% EC</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">250 سم مكعب / فدان</td>
                                  <td dir="LTR">9</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1629</td>
                                  <td dir="RTL">برواكت 5% SG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / 100 لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3150</td>
                                  <td dir="RTL">مادكو نفريم 5% SG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3161</td>
                                  <td dir="RTL">راديسون 5.7 %SG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2238</td>
                                  <td dir="RTL">بيست كليم 5 % WG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3342</td>
                                  <td dir="RTL">بوتريو 5.7 %SG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1180</td>
                                  <td dir="RTL">بيليو 50% EC</td>
                                  <td dir="LTR">Pyridaly</td>
                                  <td dir="RTL">100 سم مكعب / فدان</td>
                                  <td dir="LTR">2</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2909</td>
                                  <td dir="RTL">بينزو 5.7% WG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1388</td>
                                  <td dir="RTL">تاكومى 20% WG</td>
                                  <td dir="LTR">Flubendiamide</td>
                                  <td dir="RTL">100 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2770</td>
                                  <td dir="RTL">تريتادور 5.7% WG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1057</td>
                                  <td dir="RTL">تريسر 24% SC</td>
                                  <td dir="LTR">Spinosad</td>
                                  <td dir="RTL">
                                    50 سم مكعب / فدان ( فقس حديث )
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1740</td>
                                  <td dir="RTL">جراند 5% EC</td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1668</td>
                                  <td dir="RTL">جولدبني 90% SP</td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2541</td>
                                  <td dir="RTL">جيتو 90% SP</td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1245</td>
                                  <td dir="RTL">دايبل دى إف 6.4%DF</td>
                                  <td dir="LTR">Bacillus thuringiensis</td>
                                  <td dir="RTL">200 جم / فدان</td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1837</td>
                                  <td dir="RTL">دبليوباس 8 %WP</td>
                                  <td dir="LTR">Bacillus thuringiensis</td>
                                  <td dir="RTL">200 جم / فدان</td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1636</td>
                                  <td dir="RTL">ديراكوميل 90% SP</td>
                                  <td dir="LTR">Methomy</td>
                                  <td dir="RTL">300 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1302</td>
                                  <td dir="RTL">ديفلوريت 25% WP</td>
                                  <td dir="LTR">Diflubenzuron</td>
                                  <td dir="RTL">70 جم / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1675</td>
                                  <td dir="RTL">رايدير 15% SC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>26.5 سم مكعب / 100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1052</td>
                                  <td dir="RTL">رنر 15% SC</td>
                                  <td dir="LTR">Methoxyfenozide</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>37.5 سم مكعب / 100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">1</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1732</td>
                                  <td dir="RTL">روكسى 10% EC</td>
                                  <td dir="LTR">Novaluron</td>
                                  <td dir="RTL">200 سم مكعب / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2997</td>
                                  <td dir="RTL">رالى 5.7% WG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1583</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سبيدو 5.7% WG
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">80 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2916</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سوليم 7. 5% WG
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3002</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سيرفاكليم 7. 5% SG
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1303</td>
                                  <td dir="RTL">فلوكسيت 10% DC</td>
                                  <td dir="LTR">Flufenoxuron</td>
                                  <td dir="RTL">200 سم مكعب / فدان</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1098</td>
                                  <td dir="RTL">فريتو 5% SC</td>
                                  <td dir="LTR">Chromafenozide</td>
                                  <td dir="RTL">400 سم مكعب / فدان</td>
                                  <td dir="LTR">2</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2824</td>
                                  <td dir="RTL">كاتر إى زد 24% SC</td>
                                  <td dir="LTR">Methoxyfenozide</td>
                                  <td dir="RTL">150 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2345</td>
                                  <td dir="RTL">كاربا- ورم 90% SP</td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2672</td>
                                  <td dir="RTL">كاربودوكس15%SC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>26.5 سم مكعب / 100 لتر
                                    ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2859</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كامارو 5 %EG
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1816</td>
                                  <td dir="RTL">كامرى 5% SG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2785</td>
                                  <td dir="RTL">كفروسيل 5% EC</td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">160 سم مكعب / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1404</td>
                                  <td dir="RTL">كوراجن 20% SC</td>
                                  <td dir="LTR">Chlorantraniliprole</td>
                                  <td dir="RTL">60 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1807</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>كونتك 90% SP
                                  </td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">531</td>
                                  <td dir="RTL">كويك 90% SP</td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1572</td>
                                  <td dir="RTL">كينج كيم 7.5% WG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">30 جم / فدان</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3382</td>
                                  <td dir="RTL">رى اكت 5.7% WG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1018</td>
                                  <td dir="RTL">لانيت 25% WP</td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">1.08 كم / فدان</td>
                                  <td dir="LTR">1</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">114</td>
                                  <td dir="RTL">لانيت 90% SP</td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1333</td>
                                  <td dir="RTL">لينتون 90% SP</td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300 جم / فدان</td>
                                  <td dir="LTR">13</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">609</td>
                                  <td dir="RTL">ماتش 5% EC</td>
                                  <td dir="LTR">Lufenuron</td>
                                  <td dir="RTL">160 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2920</td>
                                  <td dir="RTL">مارسا 24% SC</td>
                                  <td dir="LTR">Methoxyfenozide</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1556</td>
                                  <td dir="RTL">مينوكليم 5%W</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">60 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2427</td>
                                  <td dir="RTL">نوفو 10%D</td>
                                  <td dir="LTR">Flufenoxuron</td>
                                  <td dir="RTL">200 سم مكعب / فدان</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1061</td>
                                  <td dir="RTL">هويانج 90% S</td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300 جم / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1875</td>
                                  <td dir="RTL">وانت 90% SP</td>
                                  <td dir="LTR">Methomyl</td>
                                  <td dir="RTL">300 جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3183</td>
                                  <td dir="RTL">تونشى 15 %SC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td></td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 6- دودة اللوز الأمريكية*/}
                      <Collapse title="6- دودة اللوز الأمريكية">
                        {/* <h2 className="details-page-title subTitle">
                          6- دودة اللوز الأمريكية
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/dodtElloz.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تتلف اليرقات أعضاء التكاثر في النباتات مثل
                                البراعم والأزهار- تتميز الإصابة على الثمار بوجود
                                فوهة الثقوب دائرية الشكل.
                                <br />
                                تفضل اليرقة ثمار الطماطم الخضراء غير الناضجة
                                وتتغذى عند إتصال العنق بالثمرة حيث يظهر مقدم جسم
                                اليرقة داخل الثمرة ومؤخرالجسم خارجها مع وجود
                                فضلات على فوهة مدخل النفق مما يؤدى إلى تعفن
                                الثمار وتلفها
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>عند بداية عقد الثمار وبداية ظهور الإصابة</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بداية ظهورأعراض الإصابة </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1329</td>
                                  <td dir="RTL">رادينت 12% SC</td>
                                  <td dir="LTR">Spinetoram</td>
                                  <td dir="RTL">80 سم مكعب / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 7- دیدان ثمار الطماطم*/}
                      <Collapse title="7- دیدان ثمار الطماطم">
                        {/* <h2 className="details-page-title subTitle">
                          7- دیدان ثمار الطماطم
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/dedansmar.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>تلف في الثمار وتقوب نتيجة لتغذية اليرقات</p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>خلال فترة الإثمار</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بداية ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1109</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>افيرم 5% SG
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">80 جم/ فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1394</td>
                                  <td dir="RTL">اكيو 10% EC</td>
                                  <td dir="LTR">Novaluron</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1301</td>
                                  <td dir="RTL">امربيور 5.0% EC</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>80 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1237</td>
                                  <td dir="RTL">باشا 9.1% EC</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">250 سم مكعب / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2731</td>
                                  <td dir="RTL">بريفورم 5% SG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1960</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>بينى 9% SC
                                  </td>
                                  <td dir="LTR">
                                    Emamectin benzoate
                                    <br />
                                    Indoxacarb
                                  </td>
                                  <td dir="RTL">150 جم / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1628</td>
                                  <td dir="RTL">توماجارد 7.5 %SG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2277</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سريندر 5 %SG
                                  </td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">120 جم / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1613</td>
                                  <td dir="RTL">فانتاج 5.14% SC</td>
                                  <td dir="LTR">Indoxacarb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">9</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1493</td>
                                  <td dir="RTL">كلازول 2 %EC</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>30 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1441</td>
                                  <td dir="RTL">هيبريون 5 %SG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">80 جم / فدان</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1882</td>
                                  <td dir="RTL">دلتاالمي5 %EG</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">320 سم مكعب / فدان</td>
                                  <td dir="LTR">10</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 8- صانعات الأنفاق (جنس ليرومايزا)*/}
                      <Collapse title="8- صانعات الأنفاق (جنس ليرومايزا)">
                        {/* <h2 className="details-page-title subTitle">
                          8- صانعات الأنفاق (جنس ليرومايزا)
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/san3at.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تتغذى اليرقات بين بشرتي الورقة مكونة أنفاقا
                                متعرجة رقيقة تتسع تدريجيا مكونة في نهايتها بقع
                                خالية من النسيج الورقي ذات لون شفاف يتحول إلى
                                اللون البني نتيجة لموت خلايا البشرة
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>شهور: مارس - أبريل - مايو - أغسطس - سبتمبر</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وصول أعداد الأنفاق إلى 2 3 نفق/ ورقة من
                              الأوراق التي يتم فحصها عشوائيا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">555</td>
                                  <td dir="RTL">أفيسكت إس50% SP</td>
                                  <td dir="LTR">
                                    Thiocyclam hydrogen oxalate
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">500 جم / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2580</td>
                                  <td dir="RTL">ايتاميك 108% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">200 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1458</td>
                                  <td dir="RTL">تريفاب 75% WP</td>
                                  <td dir="LTR">Cyromazine</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>15جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">584</td>
                                  <td dir="RTL">فابكومك 1.8 %EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">6</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 9-العنكبوت الأحمر*/}
                      <Collapse title="9-العنكبوت الأحمر">
                        {/* <h2 className="details-page-title subTitle">
                          9-العنكبوت الأحمر
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/3ekbota7mr.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تتغذى أفراد العنكبوت الأحمر على السطح السفلى
                                للأوراق حيث يظهر على الأوراق من السطح العلوى بقع
                                برونزية اللون تتسع وتلتحم ويتحول لون الورقة إلى
                                اللون البنى نتيجة موت خلايا الورقة ويميز الإصابة
                                من أسفل وجود خيوط عنكبوتية تتحرك عليها أفراد
                                العنكبوت وعند إشتداد الإصابة تظهر الأعراض على
                                الثمار
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p> خلال شهر يوليو</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وصول متوسط أعداد العنكبوت إلى عدد 7 أفراد على
                              الورقة من الأوراق التي يتم فحصها عشوائيا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">2332</td>
                                  <td dir="RTL">ابانتن سوبر 5 % Me</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1691</td>
                                  <td dir="RTL">اجرمييك جولد 8.4 % SC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">60 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2554</td>
                                  <td dir="RTL">اسيميك سوبر 8.4% SC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">40 سم مكعب / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1363</td>
                                  <td dir="RTL">اكراميت 48% SC</td>
                                  <td dir="LTR">Bifenazate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>35 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2476</td>
                                  <td dir="RTL">اكسكلار 50 %Sc</td>
                                  <td dir="LTR">Clofentezine</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3137</td>
                                  <td dir="RTL">ريومكتني 5 %ME</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1426</td>
                                  <td dir="RTL">اكسلنت1.9% EC</td>
                                  <td dir="LTR">Emamectin benzoate</td>
                                  <td dir="RTL">70 سم مكعب / فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1215</td>
                                  <td dir="RTL">وبريون 24%SC</td>
                                  <td dir="LTR">Spiromesifen</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2241</td>
                                  <td dir="RTL">ايزومكتين1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2456</td>
                                  <td dir="RTL">ايفركين1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1582</td>
                                  <td dir="RTL">بيربولورد 25% SC</td>
                                  <td dir="LTR">Buprofezin</td>
                                  <td dir="RTL">400 سم مكعب / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1672</td>
                                  <td dir="RTL">بيومكتين5% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1233</td>
                                  <td dir="RTL">جولد1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1956</td>
                                  <td dir="RTL">دلتاكير10% EC</td>
                                  <td dir="LTR">Hexythiazox</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1179</td>
                                  <td dir="RTL">دلميت 7.5% SC</td>
                                  <td dir="LTR">Sulfur</td>
                                  <td dir="RTL">
                                    1 لتر<span>&nbsp; </span>/ فدان
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1930</td>
                                  <td dir="RTL">ديفا1.8% EW</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2838</td>
                                  <td dir="RTL">راش24 %SC</td>
                                  <td dir="LTR">Spiromesifen</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1497</td>
                                  <td dir="RTL">زورو 3.6% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2773</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سبيريميكس24%SC
                                  </td>
                                  <td dir="LTR">Spiromesifen</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1365</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سوبركين 1.8% EC
                                  </td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2803</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سولانو 25% WP
                                  </td>
                                  <td dir="LTR">Buprofezin</td>
                                  <td dir="RTL">400 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2532</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سولو 24% SC
                                  </td>
                                  <td dir="LTR">Bifenazate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>80 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2340</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>شارك 24% SC
                                  </td>
                                  <td dir="LTR">Chlorfenapyr</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>60 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2282</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>شاريد 36% SC
                                  </td>
                                  <td dir="LTR">Chlorfenapyr</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2151</td>
                                  <td dir="RTL">فارم أكتني1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1550</td>
                                  <td dir="RTL">فاليو 1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2122</td>
                                  <td dir="RTL">فانتى 24% SC</td>
                                  <td dir="LTR">Chlorfenapyr</td>
                                  <td dir="RTL">400 سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1925</td>
                                  <td dir="RTL">فيجو 5% CS</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">466</td>
                                  <td dir="RTL">فريتيميك1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    160<span>&nbsp;</span>سم مكعب / فدان
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3005</td>
                                  <td dir="RTL">فيتومكتين5 %SC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1522</td>
                                  <td dir="RTL">كانى مايت 15% SC</td>
                                  <td dir="LTR">Acequinocyl</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>100 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2642</td>
                                  <td dir="RTL">كام ميك سوبر 8.4% SC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    60<span>&nbsp;</span>سم مكعب / فدان
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2586</td>
                                  <td dir="RTL">كومودو 1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1665</td>
                                  <td dir="RTL">كورميت 1.8%EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1019</td>
                                  <td dir="RTL">ماكوميت 10%WP</td>
                                  <td dir="LTR">Hexythizox</td>
                                  <td dir="RTL">20 جم / 100 لتر ماء</td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1355</td>
                                  <td dir="RTL">ميكتى 1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2590</td>
                                  <td dir="RTL">ميكتيام 1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2609</td>
                                  <td dir="RTL">نصرسيازوكس5.45% EC</td>
                                  <td dir="LTR">Hexythizox</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1955</td>
                                  <td dir="RTL">وندر 36 %SC</td>
                                  <td dir="LTR">Chlorfenapyr</td>
                                  <td dir="RTL">
                                    180<span>&nbsp;</span>سم مكعب / فدان
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3085</td>
                                  <td dir="RTL">كوفكس 24 %SC</td>
                                  <td dir="LTR">Spiromesifen</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3027</td>
                                  <td dir="RTL">مكرتون 10 %SC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>10 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3099</td>
                                  <td dir="RTL">فاست ماكس 3.6 %EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3006</td>
                                  <td dir="RTL">تشارجر 24 %S</td>
                                  <td dir="LTR">Chlorfenapyr</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>60 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2953</td>
                                  <td dir="RTL">بيستكتين1.8 %EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3351</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>ساندوسين43% SC
                                  </td>
                                  <td dir="LTR">Bifenazate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>35 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3277</td>
                                  <td dir="RTL">بينفت 48% SC</td>
                                  <td dir="LTR">Bifenazate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>35 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3300</td>
                                  <td dir="RTL">مون مايت 48% SC</td>
                                  <td dir="LTR">Bifenazate</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3310</td>
                                  <td dir="RTL">ماكتبلس1.8% EC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3311</td>
                                  <td dir="RTL">جنيتود 20% SC</td>
                                  <td dir="LTR">Fenazaoruin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3363</td>
                                  <td dir="RTL">كاتش 11% SC</td>
                                  <td dir="LTR">Etoxazole</td>
                                  <td dir="RTL">
                                    100<span>&nbsp;</span>سم مكعب / فدان
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3388</td>
                                  <td dir="RTL">شانتير24% SC</td>
                                  <td dir="LTR">Chlorfenapyr</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>60 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 10- اعفان الثمار*/}
                      <Collapse title="10- اعفان الثمار">
                        {/* <h2 className="details-page-title subTitle">
                          10- اعفان الثمار
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/23fansmar.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تتكون بقع صلبة غائرة لونها مختلف حسب المسبب
                                المرضى في المناطق التي تلامس فيها الثمار التربة
                                أو المعرضة للرطوبة المرتفعة
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تظهر الأعراض عند توفر الظروف الجوية من حرارة
                              معتدلة ورطوبة مرتفعة منذ شهر سبتمبر
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بداية ظهور الأعراض</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">938</td>
                                  <td dir="RTL">تيلدور 50% Sc</td>
                                  <td dir="LTR">Fenhexamid</td>
                                  <td dir="RTL">
                                    600<span>&nbsp;</span>سم مكعب / فدان
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 11- اعفان الجذور وموت البادرات*/}
                      <Collapse title="11- اعفان الجذور وموت البادرات">
                        {/* <h2 className="details-page-title subTitle">
                          11- اعفان الجذور وموت البادرات
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/23fangzor.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                يسببها فطريات عديدة وينتج عنها غياب الجور في
                                الفترة الأولى من عمر النبات قبل الإنبات أو بعد
                                ظهورها فوق سطح التربة مما يؤدى إلى نقص النباتات
                                القائمة بالحقل
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>في بداية النبات وفي أي مرحلة من مراحل النمو</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>معاملة البذورأوالشتلات قبل الزراعة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">2564</td>
                                  <td dir="RTL">اتريو 80%WP</td>
                                  <td dir="LTR">Tebuconazole</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>2 جم /
                                    <span>&nbsp;&nbsp;</span>لتر ماء
                                  </td>
                                  <td dir="LTR">45</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1012</td>
                                  <td dir="RTL">أراكيور 72.2% SL</td>
                                  <td dir="LTR">Propamocarb hydrochloride</td>
                                  <td dir="RTL">
                                    1 سم مكعب / لتر ماء ( معامله بذره بالمشتل )
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1808</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>بريفيكيور انيرجى 84% SL
                                  </td>
                                  <td dir="LTR">
                                    Fosetyl-Aluminium
                                    <br />
                                    Propamocarb hydrochloride
                                  </td>
                                  <td dir="RTL">
                                    3 سم مكعب / لتر ماء (ارض مستديمه )
                                  </td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1869</td>
                                  <td dir="RTL">بينك أس30% SL</td>
                                  <td dir="LTR">Hymexazole</td>
                                  <td dir="RTL">
                                    1 سم مكعب / لتر ماء ( معامله بذره بالمشتل )
                                  </td>
                                  <td dir="LTR">45</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2740</td>
                                  <td dir="RTL">بيوكيور إف1.15% WP</td>
                                  <td dir="LTR">Trichoderma virde</td>
                                  <td colspan="2" dir="RTL">
                                    <span>&nbsp;</span>6 جم /
                                    <span>&nbsp;&nbsp; </span>لتر ماء معامله
                                    الشتله قبل و يعد الزراعه<span>&nbsp;</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1553</td>
                                  <td dir="RTL">تيكو 80% WG</td>
                                  <td dir="LTR">Fosetyl Aluminium</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>3 جم /
                                    <span>&nbsp;&nbsp;</span>لتر ماء (معامله
                                    يذره بالمشتل )
                                  </td>
                                  <td dir="LTR">55</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2153</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>دبل 56% WP
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Hymexazole
                                    <br />
                                    Thiophanate-methyl
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>1 جم /
                                    <span>&nbsp;&nbsp;</span>لتر ماء (معامله
                                    يذره بالمشتل )
                                  </td>
                                  <td dir="LTR">45</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2743</td>
                                  <td dir="RTL">دلتاجولد 48%EC</td>
                                  <td dir="LTR">Metalaxyl-M</td>
                                  <td dir="RTL">200 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">45</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2800</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>دوفيكس 50% SC
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Tebuconazole
                                  </td>
                                  <td dir="RTL">
                                    1 سم مكعب / لتر ماء<span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR">45</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2361</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>ريستارت 56% WP
                                  </td>
                                  <td dir="LTR">
                                    Hymexazole
                                    <br />
                                    Thiophanate-methyl
                                  </td>
                                  <td dir="RTL">400 جم / فدان</td>
                                  <td dir="LTR">45</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2278</td>
                                  <td dir="RTL">ساسكو 5%EW</td>
                                  <td dir="LTR">Tebuconazole</td>
                                  <td dir="RTL">50 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1045</td>
                                  <td dir="RTL">مون كت 25%WP</td>
                                  <td dir="LTR">Flutolanil</td>
                                  <td dir="RTL">3 جم / كجم يذره</td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1236</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>يونيفورم 390 - 39% SE
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Metalaxyl-M (Mefenoxam)
                                  </td>
                                  <td dir="RTL">650 سم مكعب / فدان</td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3255</td>
                                  <td dir="RTL">فورمال 39% SE</td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Metalaxyl-M (Mefenoxam)
                                  </td>
                                  <td dir="RTL">650 سم مكعب / فدان</td>
                                  <td dir="LTR">55</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3384</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>باشفول 38% WG
                                  </td>
                                  <td dir="LTR">
                                    Boscalid
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>1 جم /
                                    <span>&nbsp;&nbsp;</span>لتر ماء
                                    <span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR">45</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 12- البياض الدقيقي*/}
                      <Collapse title="12- البياض الدقيقي">
                        {/* <h2 className="details-page-title subTitle">
                          12- البياض الدقيقي
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/byadd.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهرعلى الأسطح العلوية للأوراق بقع صفراء زاهية
                                يقابلها على السطح السفلى نموات دقيقة رهيفة لونها
                                أبيض أو رمادي خفيف ومع اشتداد الإصابة تتحول هذه
                                البقع إلى اللون البنى وتجف
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>بعد شهرين من الزراعة وحسب عروة الزراعة</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بداية ظهورالإصابة </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">2564</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>اتريو 80% WP
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">15 جم / 100 لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2047</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>اسكودو جولد 24% SC
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Tebuconazole
                                  </td>
                                  <td dir="RTL">125 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">675</td>
                                  <td dir="RTL">أكتابريت 98 % DP</td>
                                  <td dir="LTR">Sulfur</td>
                                  <td dir="RTL">30 كجم / فدان (تعفير )</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2786</td>
                                  <td dir="RTL">برست 24.5 %EC</td>
                                  <td dir="LTR">Myclobutanil</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1791</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>برونتو 32%SC
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Tebuconazole
                                  </td>
                                  <td dir="RTL">400 سم مكعب/ فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2226</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>بيوجيل سى اكس 42.5% SC
                                  </td>
                                  <td dir="LTR">
                                    Chlorothalonil
                                    <br />
                                    Cymoxanil
                                  </td>
                                  <td dir="RTL">200 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1679</td>
                                  <td dir="RTL">ثيوفينال سوبر80%WG</td>
                                  <td dir="LTR">Sulfur</td>
                                  <td dir="RTL">
                                    3<span>&nbsp;</span>كجم / فدان
                                    <span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">583</td>
                                  <td dir="RTL">دومارك10% EC</td>
                                  <td dir="LTR">Tetraconazole</td>
                                  <td dir="RTL">50 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1787</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سوريل زراعي كزد 98%DP
                                  </td>
                                  <td dir="LTR">Sulfur</td>
                                  <td dir="RTL">30 كجم / فدان (تعفير )</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2181</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سيدلى توب 14% Dc
                                  </td>
                                  <td dir="LTR">
                                    Difenoconazole
                                    <br />
                                    Cyflufenamid
                                  </td>
                                  <td dir="RTL">250 سم مكعب/ فدان</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2578</td>
                                  <td dir="RTL">كبريدست-د 98%DP</td>
                                  <td dir="LTR">Sulfur</td>
                                  <td dir="RTL">
                                    15<span>&nbsp;</span>كجم / فدان
                                    <span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2522</td>
                                  <td dir="RTL">مارفيل 45% EW</td>
                                  <td dir="LTR">Prochloraz</td>
                                  <td dir="RTL">75 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2040</td>
                                  <td dir="RTL">نيوسلفر 98% DP</td>
                                  <td dir="LTR">Sulfur</td>
                                  <td dir="RTL">30 كجم / فدان (تعفير )</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2952</td>
                                  <td dir="RTL">كوناماس 10 %EC</td>
                                  <td dir="LTR">Prochloraz</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>25 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3182</td>
                                  <td dir="RTL">اكوا جرين بالس52.5%WG</td>
                                  <td dir="LTR">
                                    Cymoxani
                                    <br />
                                    Famoxadone
                                  </td>
                                  <td dir="RTL">40 جم / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3261</td>
                                  <td dir="RTL">اكستريم32.5%SC</td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">200 سم مكعب/ فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3276</td>
                                  <td dir="RTL">رينت 80%WG</td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">30 جم / 100 لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3349</td>
                                  <td dir="RTL">كافيتا 29.3%SC</td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Tebuconazole
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>125 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3373</td>
                                  <td dir="RTL">ثيوفان جولد 80%WG</td>
                                  <td dir="LTR">Sulfur</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 13- الندوة المبكرة ( البدرية )*/}
                      <Collapse title="13- الندوة المبكرة ( البدرية )">
                        {/* <h2 className="details-page-title subTitle">
                          13- الندوة المبكرة ( البدرية )
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/mobkr.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                بقع على سطح الأوراق السفلى تمتد للأوراق العليا
                                وتتميز بوجود حلقات دائرية حول مركزها وتقرحات على
                                الساق وبقع على الثمار خاصة عند العنق تتميز بوجود
                                حلقات دائرية حول مركزها أيضا وذات لون بني مسود
                                إلى أسود
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تظهر الإصابة على الأوراق والسوق والثمار في فترات
                              الحرارة المعتدلة والرطوبة العالية
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور الإصابة خاصة في العروة الصيفية</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1877</td>
                                  <td dir="RTL">أروم 80 80 %Wp</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1041</td>
                                  <td dir="RTL">أروميل بلص 50% WP</td>
                                  <td dir="LTR">
                                    Copper oxychloride
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">21</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1792</td>
                                  <td dir="RTL">أزوستار 25% SC</td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1569</td>
                                  <td dir="RTL">أطلس 25%EC</td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1564</td>
                                  <td dir="RTL">أكروزيل 69%WP</td>
                                  <td dir="LTR">
                                    Dimethomoroh
                                    <br />
                                    <span>&nbsp;</span>Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2039</td>
                                  <td dir="RTL">أناكوب إل 69 %SC</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>200 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2877</td>
                                  <td dir="RTL">اندوسان 75 %WG</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>200 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2938</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>أزور 25 %SC
                                  </td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1856</td>
                                  <td dir="RTL">أوبن 72% SC</td>
                                  <td dir="LTR">Chlorothalonil</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>350 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1146</td>
                                  <td dir="RTL">أوكسى بلس 47.89 %WP</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2434</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>ايفر-زد 72% WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb
                                    <br />
                                    <span>&nbsp;</span>Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2940</td>
                                  <td dir="RTL">ازور توب 32.5% SC</td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1653</td>
                                  <td dir="RTL">بورميكس 91.3% WP</td>
                                  <td dir="LTR">
                                    Bordeaux mixture
                                    <br />
                                    Cymoxanil
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>400 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">298</td>
                                  <td dir="RTL">بولريام(دى إف)80% DF</td>
                                  <td dir="LTR">Metiram</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1087</td>
                                  <td dir="RTL">بيو أرك6% WP</td>
                                  <td dir="LTR">Bacillas megaterium</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">743</td>
                                  <td dir="RTL">تازولني 72% WP</td>
                                  <td dir="LTR">
                                    Mancozeb
                                    <br />
                                    <span>&nbsp;</span>Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">411</td>
                                  <td dir="RTL">ترايدكس 80% WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">410</td>
                                  <td dir="RTL">ترايدكس سوبر 75 %WG</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2903</td>
                                  <td dir="RTL">توين ام زد 72% WP</td>
                                  <td dir="LTR">
                                    Mancozeb
                                    <br />
                                    <span>&nbsp;</span>Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">85</td>
                                  <td dir="RTL">جالين نحاس69.8%WP</td>
                                  <td dir="LTR">
                                    Benalaxyl
                                    <br />
                                    Copper oxychloride
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">20</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2519</td>
                                  <td dir="RTL">جنفو 75% WG</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>200 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1801</td>
                                  <td dir="RTL">جينوزيب 80% WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">735</td>
                                  <td dir="RTL">دايكوزيب80% WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2219</td>
                                  <td dir="RTL">دلما 50% WP</td>
                                  <td dir="LTR">
                                    Mancozeb
                                    <br />
                                    Tebuconazole
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>200 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2834</td>
                                  <td dir="RTL">ديفلوكيم 25%EC</td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1485</td>
                                  <td dir="RTL">رادوالنصر 72%WP</td>
                                  <td dir="LTR">
                                    Mancozeb
                                    <br />
                                    <span>&nbsp;</span>Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1914</td>
                                  <td dir="RTL">برافوتوب 55 %SC</td>
                                  <td dir="LTR">
                                    Chlorothalonil
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">500 سم مكعب / الفدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1774</td>
                                  <td dir="RTL">ريفاس توب 50% SC</td>
                                  <td dir="LTR">
                                    Difenoconazole
                                    <br />
                                    Mandipropamid
                                  </td>
                                  <td dir="RTL">200 سم مكعب / الفدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1141</td>
                                  <td dir="RTL">زووم(2007)37.38 % WP</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3067</td>
                                  <td dir="RTL">تورينت 73.94 %WG</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Copper oxychloride
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3121</td>
                                  <td dir="RTL">ارتيمس52.5 %WG</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Famoxadone
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp; </span>50 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1651</td>
                                  <td dir="RTL">رمييك30%WG</td>
                                  <td dir="LTR">
                                    Dimethomorph
                                    <br />
                                    Famoxadone
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">945</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سكور 25% EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">20</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1626</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سينوسين80% WP
                                  </td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3296</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سيموكسين 52.5 %WG
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Famoxadone
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp; </span>50 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3381</td>
                                  <td dir="RTL">بوما سمارت 70 %WP</td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Fosetyl Aluminium
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2613</td>
                                  <td dir="RTL">فابولوس 75% WP</td>
                                  <td dir="LTR">
                                    Chlorothalonil
                                    <br />
                                    Tebuconazole
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp; </span>70 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">2</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1026</td>
                                  <td dir="RTL">فلنت 50% WG</td>
                                  <td dir="LTR">Trifloxystrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp; </span>200 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2909</td>
                                  <td dir="RTL">فنجشوبلس 30%Ec</td>
                                  <td dir="LTR">
                                    Difenoconazole
                                    <br />
                                    Propiconazole
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>40 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">674</td>
                                  <td dir="RTL">فنجيوران أو اتش77%WP</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2062</td>
                                  <td dir="RTL">فولآر سى تى 60%SC</td>
                                  <td dir="LTR">
                                    Chlorothalonil
                                    <br />
                                    Dimethomorph
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2919</td>
                                  <td dir="RTL">فريونيكس كوبر 91.3% WP</td>
                                  <td dir="LTR">
                                    Cymoxani
                                    <br />
                                    Bordeaux mixture
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>400 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2707</td>
                                  <td dir="RTL">كاردكس 80 %WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1089</td>
                                  <td dir="RTL">كوبرهسيد 77% WP</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">640</td>
                                  <td dir="RTL">كوسيد(2000)53.8%DF</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>180 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1982</td>
                                  <td dir="RTL">ماجما 52.5% WG</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Famoxadone
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp; </span>50 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1478</td>
                                  <td dir="RTL">مانكوكسيل 72% WP</td>
                                  <td dir="LTR">
                                    Mancozeb
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1528</td>
                                  <td dir="RTL">مانوزد 80% WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2709</td>
                                  <td dir="RTL">ميكسونيل 72 %WG</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2928</td>
                                  <td dir="RTL">ميتاسيل 72 %WP</td>
                                  <td dir="LTR">
                                    Mancozeb
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1386</td>
                                  <td dir="RTL">نوفيكيور 70% WG</td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Copper sulfate tribasic
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>175 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2342</td>
                                  <td dir="RTL">نيوكوبر 85% WP</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1678</td>
                                  <td dir="RTL">يوثان 80% WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1737</td>
                                  <td dir="RTL">يوروزول 25% EC</td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3145</td>
                                  <td dir="RTL">اراكوندا توب 45 %SC</td>
                                  <td dir="LTR">Dimethomorph Pyraclostrobin</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>60 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3214</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>أسيماكسيل 72 %WP
                                  </td>
                                  <td dir="LTR">
                                    Mancozeb
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3219</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>أجرودايفين25% EC
                                  </td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3264</td>
                                  <td dir="RTL">بافيليون 32.5% SC</td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3097</td>
                                  <td dir="RTL">توب سينسور 32.5% SC</td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">200 سم مكعب / فدان</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3232</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>سترايكر 20 %SC
                                  </td>
                                  <td dir="LTR">Penthiopyrad</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2963</td>
                                  <td dir="RTL">فامودين 52.5% W</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Famoxadone
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp; </span>50 جم / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3078</td>
                                  <td dir="RTL">بلوكر32.5% SC</td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3337</td>
                                  <td dir="RTL">كيدافور 25% EC</td>
                                  <td dir="LTR">Difenoconazole</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>50 سم مكعب / 100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 14- الندوة المتأخرة*/}
                      <Collapse title="14- الندوة المتأخرة">
                        {/* <h2 className="details-page-title subTitle">
                          14- الندوة المتأخرة
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/mot25r.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تتكون بقع كبيرة غير منتظمة مائية مخضرة داكنة على
                                السوق والأوراق والثمار - بينما تكبر هذه البقع
                                ويتحول لونها إلى البني الداكن وفى الطقس الرطب
                                يتكون على السطح السفلي للأوراق نمو زغبى رمادي
                                يحيط بهذه البقع
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              ينتشر المرض في الرطوبة العالية والحرارة المنخفضة
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور الاصابة خاصة في العروة النيلي</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">2938</td>
                                  <td dir="RTL">أزور 25 %SC</td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">50سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2940</td>
                                  <td dir="RTL">
                                    أزورتوب 32.5 %SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">75سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1713</td>
                                  <td dir="RTL">أكروبات مانكوزيب 69%WG</td>
                                  <td dir="LTR">
                                    Dimethomorph
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">546</td>
                                  <td dir="RTL">
                                    أكروبات نحاس 73.2%WP
                                    <br />
                                  </td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Copper oxychloride
                                    <br />
                                    Dimethomorph
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">9</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">691</td>
                                  <td dir="RTL">اكروبست الرتا69 %WG</td>
                                  <td dir="LTR">
                                    Dimethomorph
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">744</td>
                                  <td dir="RTL">انادول 80% WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1403</td>
                                  <td dir="RTL">بريفيكس إن 72.2% SL</td>
                                  <td dir="LTR">Propamocarb hydrochloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">4</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">270</td>
                                  <td dir="RTL">بريفيكيور- ن 72.2 % SL</td>
                                  <td dir="LTR">Propamocarb hydrochloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">4</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2254</td>
                                  <td dir="RTL">بلازموكسيل 72% WP</td>
                                  <td dir="LTR">
                                    Chlorothalonil
                                    <br />
                                    Metalaxyl
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2156</td>
                                  <td dir="RTL">بلوكوبر 50% WP</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1353</td>
                                  <td dir="RTL">بيلكوب 85% WP</td>
                                  <td dir="LTR">Copper oxychloride</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1088</td>
                                  <td dir="RTL">بيوزيد 2.5% WP</td>
                                  <td dir="LTR">Trichoderma album</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1789</td>
                                  <td dir="RTL">بيوكسان 72%WP</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3198</td>
                                  <td dir="RTL">بلايت ستوب 50 %WG</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Fluazinam
                                  </td>
                                  <td dir="RTL">250جم / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3283</td>
                                  <td dir="RTL">بوتادول 32.5 %SC</td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Difenoconazole
                                  </td>
                                  <td dir="RTL">300سم مكعب / فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">411</td>
                                  <td dir="RTL">ترايدكس 80% WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">410</td>
                                  <td dir="RTL">ترايدكس سوبر75% WG</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>200جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">189</td>
                                  <td dir="RTL">دايثين إم 45 80 %WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2452</td>
                                  <td dir="RTL">توربو 72%WP</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">85</td>
                                  <td dir="RTL">جالنب نحاس 69.8%WP</td>
                                  <td dir="LTR">
                                    Benalaxyl
                                    <br />
                                    Copper oxychloride
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">20</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2978</td>
                                  <td dir="RTL">دمثيل 56 %SC</td>
                                  <td dir="LTR">
                                    Azoxystrobin
                                    <br />
                                    Chlorothalonil
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1062</td>
                                  <td dir="RTL">رولكس 50% WP</td>
                                  <td dir="LTR">
                                    Copper oxychloride
                                    <br />
                                    MetalaxyL
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">1</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1103</td>
                                  <td dir="RTL">ريدوكوبر 74% WP</td>
                                  <td dir="LTR">
                                    Copper oxychloride
                                    <br />
                                    MetalaxyL
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">763</td>
                                  <td dir="RTL">
                                    ريدوميل جولدبلاس<span>&nbsp;&nbsp; </span>
                                    71.5%W<span>P</span>
                                  </td>
                                  <td dir="LTR">
                                    Copper oxychloride
                                    <br />
                                    MetalaxyL
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>200جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1275</td>
                                  <td dir="RTL">زيوس 72.2% SL</td>
                                  <td dir="LTR">Propamocarb hydrochloride</td>
                                  <td dir="RTL">250سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2171</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>ساندوكوبر 50% WP
                                  </td>
                                  <td dir="LTR">
                                    Copper oxychloride
                                    <br />
                                    MetalaxyL
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1489</td>
                                  <td dir="RTL">فاكوميل 35% WP</td>
                                  <td dir="LTR">Metalaxyl</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2524</td>
                                  <td dir="RTL">فورتينا جولد 44% WG</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1371</td>
                                  <td dir="RTL">كبروسات جولد72%WP</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1171</td>
                                  <td dir="RTL">كربيوتوب 60% WG</td>
                                  <td dir="LTR">
                                    Metiram
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>200جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1839</td>
                                  <td dir="RTL">كربيوديو 11.2% EC</td>
                                  <td dir="LTR">
                                    Dimethomorph
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">200سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2102</td>
                                  <td dir="RTL">كتاجنامان 70% WP</td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>Fosetyl - Aluminium
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1338</td>
                                  <td dir="RTL">كلورثيت 75% WP</td>
                                  <td dir="LTR">Chlorothalonil</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2394</td>
                                  <td dir="RTL">كلوروتوسيب50% SC</td>
                                  <td dir="LTR">Chlorothalonil</td>
                                  <td dir="RTL">125سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1096</td>
                                  <td dir="RTL">كريزيت آر 73.15% WP</td>
                                  <td dir="LTR">
                                    Copper oxychloride
                                    <br />
                                    Cymoxanil
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3252</td>
                                  <td dir="RTL">كولر أستين37 %SC</td>
                                  <td dir="LTR">
                                    Dimethomorph
                                    <br />
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">75سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1071</td>
                                  <td dir="RTL">كيور-بلاس 74.5% WP</td>
                                  <td dir="LTR">
                                    Copper oxychloride
                                    <br />
                                    MetalaxyL
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>150جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">21</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2152</td>
                                  <td dir="RTL">لامنكو 80%WP</td>
                                  <td dir="LTR">Mancozeb</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2139</td>
                                  <td dir="RTL">ميسن تربل 49.2% WP</td>
                                  <td dir="LTR">
                                    Benalaxyl
                                    <br />
                                    Cymoxanil
                                    <br />
                                    Mancozeb
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>250جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3185</td>
                                  <td dir="RTL">
                                    مورفكسانيل جولد<span>&nbsp; </span>
                                    <br />
                                    25% WP
                                  </td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Dimethomorph
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>200جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2594</td>
                                  <td dir="RTL">نيومورف70% WG</td>
                                  <td dir="LTR">
                                    Cymoxanil
                                    <br />
                                    Dimethomorph
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75جم/100 لتر ماء
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2324</td>
                                  <td dir="RTL">هيروس 25%SC</td>
                                  <td dir="LTR">Azoxystrobin</td>
                                  <td dir="RTL">75سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 15- نيماتودا تعقد الجذور*/}
                      <Collapse title="15- نيماتودا تعقد الجذور">
                        {/* <h2 className="details-page-title subTitle">
                          15- نيماتودا تعقد الجذور
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/09/nema.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهر عقد على الجذور يتبعها إصفرار في الأوراق
                                وذبول وتقزم للنباتات وتدهور في المحصول
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>من طور الشتلة الصغيرة إلى النبات المثمر</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              إكتشاف أي تعداد في التربة في بداية الموسم
                              يعتبرخطيرا ويجب بدء العلاج فورا
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR">1927</td>
                                  <td dir="RTL">أوكسنيم النصر24% SL</td>
                                  <td dir="LTR">Oxamyl</td>
                                  <td dir="RTL">
                                    3لتر / فدان رشتين في حاله عدم معامله الشتلات
                                    <span>باحد مبيدات النيماتودا</span>
                                  </td>
                                  <td dir="LTR">40</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2458</td>
                                  <td dir="RTL">أويكوس 3.2% EC</td>
                                  <td dir="LTR">Azadirachtin</td>
                                  <td dir="RTL">
                                    400 سم مكعب / فدان شتلات معامله
                                  </td>
                                  <td dir="LTR">1</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1616</td>
                                  <td dir="RTL">تريفيجو 2%SC</td>
                                  <td dir="LTR">Abamectin</td>
                                  <td dir="RTL">2.5 لتر / فدان شتلات معامله</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1701</td>
                                  <td dir="RTL">دينتو 40%EC</td>
                                  <td dir="LTR">Fenamiphos</td>
                                  <td dir="RTL">3لتر / فدان رشتين</td>
                                  <td dir="LTR">65</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1099</td>
                                  <td dir="RTL">راجبى 20%CS</td>
                                  <td dir="LTR">Cadusafos</td>
                                  <td dir="RTL">1.5لتر / فدان</td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">564</td>
                                  <td dir="RTL">فايديت 10% Gr</td>
                                  <td dir="LTR">Oxamyl</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20كجم / فدان
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">122</td>
                                  <td dir="RTL">فايديت 24% SL</td>
                                  <td dir="LTR">Oxamyl</td>
                                  <td dir="RTL">
                                    2لتر/ فدان (رشتنين) شتلات معاملة
                                    <br />
                                    3لتر/ فدان (رشتنين) شتلات غير معاملة
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2415</td>
                                  <td dir="RTL">فيربوتكت 24% SL</td>
                                  <td dir="LTR">Oxamyl</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>3لتر / فدان رشتين
                                  </td>
                                  <td dir="LTR">50</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1283</td>
                                  <td dir="RTL">فيناتود 10%GR</td>
                                  <td dir="LTR">Fenamiphos</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>20كجم / فدان
                                    <span>&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1139</td>
                                  <td dir="RTL">نيماثورين 10%GR</td>
                                  <td dir="LTR">Fosthiazate</td>
                                  <td dir="LTR">
                                    <span>&nbsp;&nbsp;</span>12.5كجم / فدان
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    شتلات معامله<span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR"></td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2805</td>
                                  <td dir="RTL">نيمابروب 10%GR</td>
                                  <td dir="LTR">Fosthiazate</td>
                                  <td dir="LTR">
                                    <span>&nbsp;&nbsp;</span>12.5كجم / فدان
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    شتلات معامله<span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="LTR">50</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">1395</td>
                                  <td dir="RTL">نيماكاب 20% EC</td>
                                  <td dir="LTR">Ethoprophos</td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>2.5لتر / 100 لتر ماء رش
                                    على الخطوط قبل الز<span>راعه</span>
                                  </td>
                                  <td dir="LTR">45</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3169</td>
                                  <td dir="RTL">نيماكيك30 %SL</td>
                                  <td dir="LTR">Imicyafos</td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>4لتر / فدان رشتين
                                  </td>
                                  <td dir="LTR">30</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3059</td>
                                  <td dir="RTL">فيلاب 20% EC</td>
                                  <td dir="LTR">Ethoprophos</td>
                                  <td dir="LTR">
                                    <span>&nbsp;</span>2.5لتر / 100 لتر ماء رش
                                    على الخطوط قبل الز<span>راعه</span>
                                  </td>
                                  <td dir="LTR">45</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3167</td>
                                  <td dir="RTL">نيما أوكس عالمية 10%GR</td>
                                  <td dir="LTR">Oxamyl</td>
                                  <td dir="RTL">
                                    20 كجم / فدان ( شتلات معامله )
                                  </td>
                                  <td dir="LTR">45</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">2941</td>
                                  <td dir="RTL">فيليوم برامي40 %SC</td>
                                  <td dir="LTR">Fluopyram</td>
                                  <td dir="RTL">
                                    250 سم مكعب / فدان ( شتلات معامله )
                                  </td>
                                  <td dir="LTR">55</td>
                                </tr>
                                <tr>
                                  <td dir="LTR">3162</td>
                                  <td dir="RTL">كابسول برو 30 %CS</td>
                                  <td dir="LTR">Fosthiazate</td>
                                  <td dir="RTL">2 لتر / فدان</td>
                                  <td dir="LTR">45</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
