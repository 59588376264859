import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
// import { Button, Collapse, UncontrolledCollapse } from 'reactstrap';
import Collapse from './collapse/collapse';

function Index() {
  const data = {
    labels: ['2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'المواسم',
        data: [40.614, 43.585, 83.968, 17.582, 22.565],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };

  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [208.31, 4808.153],
            offset: [5],
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
  };

  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/03/main.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  الفراولة
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    تعد مصر من أولي دول العالم في تصدير الفراولة " وفقا لأحدث
                    إحصائيات المجلس التصديرى للحاصلات الزراعية والهيئة العامة
                    للرقابة على الصادرات والواردات " . وتحتوي الفراولة علي
                    مجموعة من الفيتامينات والأملاح المعدنية والسكريات . ويعتمد
                    نجاح زراعة الفراولة على الصنف وموعد الزراعة ونوع التربة
                    ومياه الري ونظام الزراعة سواء بشتلات طازجة أو مبردة ومكافحة
                    الآفات الحشرية والعناية بعمليات خدمة المحصول منذ بداية
                    الزراعة وحتى الحصاد وتنجح زراعة الفراولة في جميع أنواع
                    الأراضي بشرط أن تكون جيدة الصرف وخالية من الحشائش والأملاح
                    وأن تكون التربة متعادلة تقريبا ( 6.5 – 7.5 PH)
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات الفراولة من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات الفراولة المصرية مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%4.33</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات الفراولة المصرية
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- دودة ورق القطن*/}
                      <Collapse title="1- دودة ورق القطن">
                        {/* <h2 className="details-page-title subTitle">
                          1- دودة ورق القطن
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/03/doda.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تتغذى اليرقات حديثة الفقس على البشرة السفلى
                                للأوراق
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>شهري نوفمبر وديسمبر – وشهري مارس وأبريل</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهور الإصابة ويتم الرش على المجموع الخضري</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1364
                                  </td>
                                  <td dir="RTL" align="right">
                                    أجرى50% WG
                                  </td>
                                  <td dir="LTR">Bacillus thuringiensis</td>
                                  <td class="xl69" dir="RTL" align="right">
                                    250 جم/فدان (فقص حديث)
                                  </td>
                                  <td dir="LTR">-</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1732
                                  </td>
                                  <td dir="RTL" align="right">
                                    روكسى 10%EC
                                  </td>
                                  <td dir="LTR">Novaluron</td>
                                  <td dir="RTL">
                                    300 سم مكعب / فدان<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3285
                                  </td>
                                  <td dir="RTL" align="right">
                                    ونسور 24%SC
                                  </td>
                                  <td dir="LTR">Methoxyfenozide</td>
                                  <td dir="RTL">37.5 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2- العنكبوت الأحمر*/}
                      <Collapse title="2- العنكبوت الأحمر">
                        {/* <h2 className="details-page-title subTitle">
                          2- العنكبوت الأحمر
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/03/3ankabot.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ظهور بقع صفراء على الأوراق - خشونة سطح الورقة في
                                مناطق تواجد الأفراد - إصفرار سطح الورقة وذبولها
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              على فترتين الأولى أواخر شهر أكتوبر وأوائل شهر
                              نوفمبر - الثانية منتصف شهر مارس وخلال شهر أبريل
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وصول متوسط أعداد العنكبوت إلى عدد اكبر من
                              أفراد متحركة على سطحى الورقة في الأوراق التي يتم
                              فحصها عشوائيا.
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1691
                                  </td>
                                  <td dir="RTL" align="right">
                                    اجريميك جولد 8.4% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Abamectin<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">60 سم مكعب /فدان</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2935
                                  </td>
                                  <td dir="RTL" align="right">
                                    أكارى نيل 20%Wp
                                  </td>
                                  <td dir="LTR" align="left">
                                    Pyridaben
                                  </td>
                                  <td dir="RTL">100 جم/100 لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1363
                                  </td>
                                  <td dir="RTL" align="right">
                                    اكراميت48%SC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Bifenazate
                                  </td>
                                  <td dir="RTL">35سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1215
                                  </td>
                                  <td dir="RTL" align="right">
                                    اوبريون 24%SC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Spiromesifen
                                  </td>
                                  <td dir="RTL">40 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    688
                                  </td>
                                  <td dir="RTL" align="right">
                                    باروك 10%SC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Ethoxazole
                                  </td>
                                  <td dir="RTL">25 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">15</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1798
                                  </td>
                                  <td dir="RTL" align="right">
                                    بايروميت20%Wp
                                  </td>
                                  <td dir="LTR" align="left">
                                    Pyridaben
                                  </td>
                                  <td dir="RTL">100 جم/100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3320
                                  </td>
                                  <td dir="RTL" align="right">
                                    بازاروف 43% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Bifenazate
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">35 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2612
                                  </td>
                                  <td dir="RTL" align="right">
                                    تامبو 40% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Pyridaben<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">100 جم/100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2197
                                  </td>
                                  <td dir="RTL" align="right">
                                    دلتامك 1.8% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Abamectin<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">40 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2904
                                  </td>
                                  <td dir="RTL" align="right">
                                    ديوراميت 24%SC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Bifenazate
                                  </td>
                                  <td dir="RTL">70سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2784
                                  </td>
                                  <td dir="RTL" align="right">
                                    فالرمونى 24%SC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Bifenazate
                                  </td>
                                  <td dir="RTL">70سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    466
                                  </td>
                                  <td dir="RTL" align="right">
                                    فريتيميك1.8%EC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Abamectin
                                  </td>
                                  <td dir="RTL">60سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3065
                                  </td>
                                  <td dir="RTL" align="right">
                                    <span>&nbsp;</span>فاست ماكس سوبر 8.4% SC
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Abamectin
                                  </td>
                                  <td dir="RTL">60 سم مكعب /فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1078
                                  </td>
                                  <td dir="RTL" align="right">
                                    كيلميت 1.8% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Abamectin
                                  </td>
                                  <td dir="RTL">40 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1719
                                  </td>
                                  <td dir="RTL" align="right">
                                    ماريسول 1.8% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Abamectin
                                  </td>
                                  <td dir="RTL">50سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1019
                                  </td>
                                  <td dir="RTL" align="right">
                                    ماكوميت10%WP
                                  </td>
                                  <td dir="LTR" align="left">
                                    Hexythiazox
                                  </td>
                                  <td dir="RTL">20جم/100 لتر ماء</td>
                                  <td dir="LTR">4</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1285
                                  </td>
                                  <td dir="RTL" align="right">
                                    نصر اكتين 1.8% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Abamectin
                                  </td>
                                  <td dir="RTL">30سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">6</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1643
                                  </td>
                                  <td dir="RTL" align="right">
                                    هلب ستار 20% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Pyridaben<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">100سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2071
                                  </td>
                                  <td dir="RTL" align="right">
                                    هيكسانا 10% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Hexythiazox<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">0 8جم/فدان</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2486
                                  </td>
                                  <td dir="RTL" align="right">
                                    هيكسيجرين 5% EW<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Hexythiazox<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">40 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 3- اعفان الثمار */}
                      <Collapse title="3- اعفان الثمار ">
                        {/* <h2 className="details-page-title subTitle">
                          3- اعفان الثمار
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/03/a3fan.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                يتسبب من فطريات عديدة منها ما يسبب العفن الطرى
                                والعفن الجاف والعفن الجلدي والعفن الرمادي على
                                الثمار
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>مع بداية العقد وفي مراحل نمو الثمار المختلفة</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بداية ظهور الأعراض</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2046
                                  </td>
                                  <td dir="RTL" align="right">
                                    برولكتس 50% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Fenpyrazmine
                                  </td>
                                  <td dir="RTL">
                                    <span>&nbsp;</span>75 جم/لتر ماء
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2270
                                  </td>
                                  <td dir="RTL" align="right">
                                    بيرمدول 40% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Pyrimethanil
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">100سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1087
                                  </td>
                                  <td dir="RTL" align="right">
                                    بيوأرك 6% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Bacillus megaterium
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">
                                    <span>-&nbsp;</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2279
                                  </td>
                                  <td dir="RTL" align="right">
                                    رينجر 50% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Fenhexamid<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">50 1سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">2</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1004
                                  </td>
                                  <td dir="RTL" align="right">
                                    سويتش 62.5% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Cyprodinil
                                    <span>&nbsp;</span>
                                    <br />
                                    Fludioxonil<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">75 سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3190
                                  </td>
                                  <td dir="RTL" align="right">
                                    ستيلو 62% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp; </span>Cyprodinil
                                    <span>&nbsp;</span>
                                    <br />
                                    Fludioxonil<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    75 جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1949
                                  </td>
                                  <td dir="RTL" align="right">
                                    اسيتوكلور خام 93%<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Acetochlor<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">120سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2648
                                  </td>
                                  <td dir="RTL" align="right">
                                    فانجو 50% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Pyraclostrobin<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    50 جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1631
                                  </td>
                                  <td dir="RTL" align="right">
                                    مايستك 20% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Pyrimethanil
                                    <span>&nbsp;</span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    400 جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">5</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 4-   البياض الدقيقى*/}
                      <Collapse title="4- البياض الدقيقى">
                        {/* <h2 className="details-page-title subTitle">
                          4-   البياض الدقيقى
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/03/dakeki.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                تظهر الأعراض في صورة تجعد الأوراق لأعلى حيث تأخذ
                                شكل الملعقة وغالبا ما تميل إلى اللون الأرجواني
                                والسطح السفلى للأوراق عليه نمو أبيض دقيقي والذي
                                ينتشر بعد ذلك على السطح العلوى -وفى حالة الإصابة
                                الشديدة تصاب الأزهار وأعناقها حيث يظهر عليها
                                النمو الأبيض أوقد تظهر النموات البيضاء على
                                الثمار في مراحل النمو المختلفة
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              تنتشر الإصابة في الجو الدافيء والرطوبة العالية
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بداية ظهور الأعراض</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1610
                                  </td>
                                  <td dir="RTL" align="right">
                                    <span>&nbsp;</span>اكتاميل 70% WP
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Thiophanate methyl
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    60 جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2998
                                  </td>
                                  <td dir="RTL" align="right">
                                    اوبن-اكسترا 72% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Chlorothalonil<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    247
                                  </td>
                                  <td dir="RTL" align="right">
                                    توبسين إم 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Thiophanate-methyl<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    60 جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3184
                                  </td>
                                  <td dir="RTL" align="right">
                                    تكنوسين - ام 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Thiophanate-methyl<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    80جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3346
                                  </td>
                                  <td dir="RTL" align="right">
                                    سيت زون 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Thiophanate-methyl<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    60 جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3298
                                  </td>
                                  <td dir="RTL" align="right">
                                    تان بى 70 %WP
                                  </td>
                                  <td dir="LTR" align="left">
                                    Thiophanate-methyl<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    80جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3302
                                  </td>
                                  <td dir="RTL" align="right">
                                    ترايلنت 50 %WG
                                  </td>
                                  <td dir="LTR" align="left">
                                    Trifloxystrobin
                                  </td>
                                  <td dir="RTL">
                                    20جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2870
                                  </td>
                                  <td dir="RTL" align="right">
                                    سيلاتينيل 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    myclobutanil
                                  </td>
                                  <td dir="RTL">25سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1552
                                  </td>
                                  <td dir="RTL" align="right">
                                    صن توب النصر 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Thiophanate-methyl
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    80جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2766
                                  </td>
                                  <td dir="RTL" align="right">
                                    فوتون 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Thiophanate-methyl
                                  </td>
                                  <td dir="RTL">
                                    65جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2849
                                  </td>
                                  <td dir="RTL" align="right">
                                    ميستيك برو 50% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>prochloraz
                                    <span>&nbsp;</span>
                                    <br />
                                    Tebuconazole<span>&nbsp;&nbsp;</span>
                                  </td>
                                  <td dir="RTL">25سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3149
                                  </td>
                                  <td dir="RTL" align="right">
                                    ميكلو ليدر 15% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Myclobutanil
                                    <span>&nbsp;</span>
                                    <br />
                                    prochloraz<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">35سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2060
                                  </td>
                                  <td dir="RTL" align="right">
                                    ايجافينتو 70% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Thiophanate-methyl
                                  </td>
                                  <td dir="RTL">
                                    80جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3205
                                  </td>
                                  <td dir="RTL" align="right">
                                    كريسلف 80% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Sulfur<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    250جم/100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2802
                                  </td>
                                  <td dir="RTL" align="right">
                                    هوركان 25% EC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Pyraclostrobin
                                  </td>
                                  <td dir="RTL">80سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">3</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 5- مرض عفن الجزور*/}
                      <Collapse title="5- مرض عفن الجزور">
                        {/* <h2 className="details-page-title subTitle">
                          5- مرض عفن الجزور
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/03/3afan.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                حدوث تقرحات بنية اللون على الجذورثم تصبح سوداء
                                اللون نتيجة لذلك تموت الشعيرات الجذرية المغذية
                                وينتشر الاسوداد حتى يعم المجموع الجذري الذي
                                تتعفن قشرته وتنفصل عن الاسطوانة الوعائية مما
                                يؤدى إلى إصفرار النباتات وضعفها ويسهل إقتلاع
                                النباتات من التربة
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              في بداية عمر النبات وفي أي مرحلة من مراحل نمو
                              النبات
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>معاملة الشتلات قبل الزراعة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2950
                                  </td>
                                  <td dir="RTL" align="right">
                                    ايمكساتون 70% SP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Hymexazol
                                  </td>
                                  <td dir="RTL">100 جم/100 لتر ماء</td>
                                  <td dir="LTR">60</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    45
                                  </td>
                                  <td dir="RTL" align="right">
                                    الييت 80% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Fosetyl-Aluminium<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">150 جم/100 لتر ماء</td>
                                  <td dir="LTR">45</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 6- تبقع الأوراق*/}
                      <Collapse title="6- تبقع الأوراق">
                        {/* <h2 className="details-page-title subTitle">
                          6- تبقع الأوراق
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/03/tabako3.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                بقع أرجوانية ذات مركز رمادى حوافها حمراء مزرقة -
                                تظهر على الأوراق وأعناقها وأعنـاق الثمار وقد تصل
                                إلى كـــوس الأزهار كما تظهر بقع دائريـة أو
                                بيضاويـة أو مثلثة الشكل لونها بني محمـر ذات
                                حـواف بنفسجية
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p> في أي مرحلة من مراحل نمو النبات</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بداية ظهور الأعراض</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1502
                                  </td>
                                  <td dir="RTL" align="right">
                                    فولتاكس 80% WG<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Folpet<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">200 جم/100 لتر ماء</td>
                                  <td dir="LTR">5</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1817
                                  </td>
                                  <td dir="RTL" align="right">
                                    كورتيانو 50% WP<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Captan<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">200 جم/100 لتر ماء</td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3149
                                  </td>
                                  <td dir="RTL" align="right">
                                    ميكلو ليدر 15 %EC
                                  </td>
                                  <td dir="LTR" align="left">
                                    <span>&nbsp;</span>Myclobutanil
                                    <span>&nbsp;</span>
                                    <br />
                                    prochloraz<span>&nbsp; </span>
                                    <br />
                                    <span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">50سم مكعب / 100 لتر ماء</td>
                                  <td dir="LTR">10</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
