import React from 'react';

function privacyPage(props) {
  return (
    <>
      <section className="uni-banner">
        <div className="container">
          <div className="uni-banner-text-area">
            <h1>سياسة الخصوصية</h1>
            <ul>
              <li>
                <a href="/">الرئيسيه</a>
              </li>
              <li>سياسة الخصوصية</li>
            </ul>
          </div>
        </div>
      </section>

      <div className="terms ptb-100">
        <div className="container">
          <h6>مقدمة</h6>
          <p>
            تعنى (سياسة الخصوصية) لدينا بطريقة جمعنا وحفظنا واستخدامنا وطريقة
            حماية معلوماتك الشخصية ومن المهم ان تقوم بالاطلاع سياسة الخصوصية
            هذه. ونقصد بالمعلومات الشخصية هي المعلومات التي ترتبط بشخص معين
            والتي تستخدم في التعريف عنه. نحن لا نأخذ بعين الاعتبار أي معلومات
            مجهولة المصدر لأنها لا تصلح في تشكيل معلومات شخصية ولكننا نقوم بجمع
            المعلومات الشخصية من الراغبين باستخدام موقع محاصيل مصر او صفحات
            الانترنت المرتبطة (بما في ذلك وعلى سبيل الحصر بيعك وشرائك لبضائع او
            حين تتصل هاتفيا او بواسطة البريد الالكتروني بفريق مركز دعم
            المستخدمين لدينا). وبمجرد تزويدك لنا بمعلوماتك الشخصية تكون قد
            فوضتنا لمعالجة هذه المعلومات وفق بنود وشروط (سياسة الخصوصية). .
          </p>

          <p>
            قد نقوم بتعديل (سياسة الخصوصية) في اي وقت وذلك من خلال الإعلان عن
            هذا التعديل بنشر النسخة المعدلة على الموقع. وتكون النسخة المعدلة من
            (سياسة الخصوصية) سارية المفعول اعتبارا من تاريخ نشرها. في اعقاب
            النشر يعتبر استمرارك في استخدام الموقع موافقة منك بالشروط والبنود
            الواردة في النسخة المعدلة لطريقة معالجتنا وتعاملنا مع معلوماتك
            الشخصية التي كنت قد زودتنا بها. نأمل منكم الاطلاع على الموقع من حين
            لأخر للإعلانات عن اية تعديلات على سياسة الخصوصية.
          </p>
          <h2>تغطي (سياسة الخصوصية) المواضيع التالية:</h2>

          <ul>
            <li>
              <span>1.</span>جمعنا لمعلوماتك الشخصية.
            </li>
            <li>
              <span>2.</span> استخدامنا لمعلوماتك الشخصية.
            </li>
            <li>
              <span>3.</span> استخدامك لمعلوماتك الشخصية ومعلومات المستخدمين
              الاخرين.
            </li>
            <li>
              <span>4.</span> استخدام ودخول وتصفح وتعديل معلوماتك الشخصية
            </li>
            <li>
              <span>5.</span> روابط مواقع الطرف الثالث
            </li>
            <li>
              <span>6.</span>ملفات تعريف الارتباط Cookies
            </li>
            <li>
              <span>7.</span> لا للرسائل الالكترونية المضللة او الخادعة
            </li>
            <li>
              <span>8.</span> حماية معلوماتك الشخصية
            </li>
            <li>
              <span>9.</span> كيف يمكنك الاتصال بنا للاستفسار عن (سياسة
              الخصوصية) 
            </li>
          </ul>
          <h4>جمعنا لمعلوماتك الشخصية</h4>
          <ul className="payment">
            <li>
              <span>1.</span>
              كجزء من التسجيل على الموقع أو استخدامه سوف يطب منك تزويدنا
              بمعلومات شخصية محددة مثل اسمك وعنوان الشحن وبريدك الالكتروني و/أو
              رقم هاتفك ومعلومات اخرى مشابهة وبعض المعلومات الاضافية عنك مثل
              تاريخ ميلادك أو اي معلومات محددة لهويتك. إضافة إلى ذلك وبهدف
              توثيقنا لهويتك قد نحتاج منك أن تقدم لنا اثبات ساري المفعول يثبت
              هويتك (مثلا نسخة عن جواز سفرك وتأشيرة او تصريح الاقامة والهوية
              الوطنية و/ أو رخصة القيادة).
            </li>
          </ul>
          <ul className="payment">
            <li>
              قد نحتاج ايضا الى جمع معلومات مالية محددة منك، مثلا بطاقتك
              الائتمانية و/أو تفاصيل حسابك المصرفي.
            </li>
            <li>
              يجب عليك ادخال هذه المعلومات المالية في قسم "حسابي" على الموقع.
              وسوف نستخدم هذه المعلومات لغايات اصدار الفواتير واستكمال طلباتك.
              عند تسجيلك في الموقع، ينبغي عليك عدم نشر اي معلومات شخصية (بما في
              ذلك اي معلومات مالية) على اي جزء من الموقع باستثناء جزء" إدارة
              الحساب" وهذا من شأنه حمايتك من امكانية تعرضك للاحتيال او سرقة
              معلومات هويتك. كما ان نشرك لأي من معلوماتك الشخصية على اي جزء من
              الموقع باستثناء “حسابي" قد يؤدي الى ايقاف استخدامك للموقع.
            </li>
            <li>
              سوف يتم جمع معلومات عن عملياتك وأنشطتك على الموقع سواء كانت عملية
              شراء أو بيع سلع.
            </li>
            <li>
              الرجاء ملاحظة اننا قد نستخدم عنوان برتوكول الانترنت خاصتك او (IP)
              وهو (رقم فريد يتم تعيينه لخادم الكمبيوتر أو مزود خدمة الإنترنت
              الخاص بك أوISP لتحليل انشطتك وتحسين إدارة الموقع.
            </li>
            <li>
              قد نجمع معلومات اضافية حول جهاز الكمبيوتر الخاص بك (على سبيل
              المثال نوع المتصفح) ومعلومات التصفح (على سبيل المثال الصفحات التي
              تزورها على الموقع) بالإضافة إلى عدد المرات التي تستخدم بها الموقع.
            </li>
          </ul>
          <p>
            أخيرًا، قد نجمع معلومات إضافية منك أو عنك بطرق أخرى غير موصوفة هنا
            تحديدًا. على سبيل المثال، قد نقوم بجمع المعلومات المتعلقة بتواصلك مع
            فريق دعم العملاء أو من خلال اجابتك على الاستبيانات. قد نجمع أيضًا
            التقييمات والتعليقات الأخرى المتعلقة باستخدامك للموقع. عندما نقوم
            بتجميع المعلومات الشخصية للأغراض الإحصائية، يجب أن تكون أسماء أصحاب
            المعلومات مجهولة.
          </p>
          <h3>استخدامنا لمعلوماتك الشخصية</h3>
          <p>
            نقوم بمشاركة معلوماتك الشخصية كما هو موضح أدناه فقط مع محاصيل مصر
            والشركات التابعة لها:
          </p>
          <ul className="payment">
            <li>
              نستخدم معلوماتك الشخصية لتقديم خدمات ودعم من فريق العملاء وبهدف
              قياس مستوى خدماتنا وتحسينها لك ومنع النشاطات غير القانونية وتنفيذ
              لبنود اتفاقية الاستخدام الموفقة معك ("اتفاقية الاستخدام")، اضافة
              الى حل المشاكل وجمع الرسوم وتزويدك برسائل الكترونية ترويجية وكذلك
              من اجل توثيق المعلومات التي زودتنا بها مع أطراف ثالثة مثلا قد نلجأ
              الى مشاركة بعض من معلوماتك الشخصية مع البنوك او التفويض لبطاقات
              الائتمان لمعالجة وتوثيق خدمات أو مع أطراف ثالثة لغايات التحقق من
              عمليات الاحتيال.
            </li>
            <li>
              برغم حرصنا للمحافظة على سرّيتك، الا اننا قد نحتاج الى الافصاح عن
              معلوماتك الشخصية لأجهزة تنفيذ القانون والهيئات الحكومية او أطراف
              ثالثة، نكون ملزمين بفعل ذلك بأوامر من المحكمة وغيرها من الدوائر
              القانونية لنكون ملتزمين ببنود القانون أو عند اعتقادنا ان الافصاح
              عن معلوماتك الشخصية ربما يقي من أذى جسدي او خسارة مالية او للأخبار
              عن نشاط مشبوه او للتحقيق في امكانية انتهاك لبنود وشروط اتفاقية
              المستخدم واية اتفاقيات أو لحماية الحقوق أو ملكية أو سلامة سوق أو
              مستخدمينا أو الغير.
            </li>
            <li>
              قد نفصح عن معلوماتك الشخصية في حال تم بيع محاصيل مصر او اي من
              الشركات الفرعية او التابعة لها أو اي من موجوداتها المتعلقة
              بأعمالها الى المشتري المحتمل وذلك بهدف استمرار نشاط الموقع أو فيما
              يتعلق بهذا البيع للغايات الموضحة في هذه السياسة. قد نشارك معلوماتك
              الشخصية مع مجموعة الشركات الأخرى التابعة لنا للأغراض الموضحة في
              سياسة الخصوصية هذه. ان معلومات الاتصال ستكون المعلومات الشخصية
              التي قمت بتزويدنا بها عند التسجيل والتي يجب أن تبقى محدثة دائما.
            </li>
            <li>
              سيتم عرض المعلومات المتعلقة بالمنتجات التي تقوم بشرائها أو بيعها
              أو المزادات التي تشارك فيها على الموقع. يمكن أن تتضمن هذه
              المعلومات تفاصيل حول هوية المستخدم الخاصة بك والتقييمات والتعليقات
              المرتبطة باستخدامك للموقع.
            </li>
            <li>
              لا نبيع أو نؤجر اي من معلوماتك الشخصية لطرف ثالث ضمن نطاق عملنا
              التجاري المعتاد وسوف نشارك فقط معلوماتك الشخصية فقط وفق ما جاء في
              (سياسة الخصوصية) هذا.
            </li>
            <li>
              بمجرد تسجيلك في أو استخدامك للموقع تعتبر أنك أعطيتنا تفويضا واضحا
              لتسلمك رسائل إلكترونية ترويجية حول خدماتنا إضافة الى رسائل
              إلكترونية بخصوص التغيرات والملامح الجديدة على الموقع، بحال عدم
              رغبتك في تلقي مثل هذه الرسائل، ما عليك سوى اختيار الرابط الخاص
              بإيقاف إرسال هذه الرسائل إلى عنوانك البريدي والمتوفر في أسفل اي من
              هذه الرسائل الإلكترونية
            </li>
            <li>
              بالإضافة الى ذلك نقوم باستخدام ملاحظاتك حول الموقع لغايات التسويق
              ومن خلال تقديمك لهذه الملاحظات تكون وافقت على أن نستخدم هذه
              الملاحظات لغايات التسويق.
            </li>
          </ul>
          <h2>استخدامك لمعلوماتك الشخصية ومعلومات المستخدمين الاخرين</h2>
          <ul className="payment">
            <li>
              استطاعتك الوصول ومراجعة معلوماتك الشخصية من خلال صفحة إدارة "حسابي
              “على الموقع، فاذا تغيرت معلوماتك الشخصية باي طريقة او اعطيت
              معلومات غير صحيحة على الموقع فعليك القيام بتحديثها او تصحيحها
              حالا، اما من خلال عرضها على "حسابي" او من خلال الاتصال بفريق خدمة
              العملاء. يحتوي الرابط الخاص ب " دعم العملاء" والموجود في أعلى كل
              صفحة على البريد الإلكتروني ورقم الهاتف الخاص بهذا القسم.
            </li>
            <li>
              يرجى العلم أننا سوف نحتفظ بمعلوماتك الشخصية خلال وبعد انتهائك من
              استخدام الموقع بحسب ما هو مطلوب قانونا، وذلك بهدف التغلب على
              العوائق التقنية، ولمنع الاحتيال، وللمساعدة في اي تحقيق قانوني
              ولاتخاذ اي اجراءات اخرى ينص عليها القانون.
            </li>
          </ul>
          <h1> روابط مواقع الطرف الثالث</h1>
          <p>
            قد يحتوي الموقع على روابط من مواقع الكترونية اخرى، الرجاء العلم
            باننا غير مسؤولين عن مستوى الخصوصية المتبعة في هذه المواقع الاخرى.
            نحن نشجعك حين تنتقل من موقعنا الى أي موقع أخر بان تقوم بقراءة بيانات
            (سياسة الخصوصية) الخاصة بهم إذا كان في نيتك اعطائهم معلوماتك
            الشخصية. ان (سياسة الخصوصية) عندنا تطبق فقط على معلوماتك الشخصية
            التي جمعناها على موقعنا.
          </p>

          <h1>ملفات تعريف الارتباط</h1>
          <ul className="payment">
            <li>
              مثل العديد من مواقع الانترنت فان موقعنا يستخدم ملفات تعريف
              الارتباط وهي ملفات حاسوب صغيرة يتم تثبيتها على الجزء الصلب من
              حاسوبك، فحين تذهب لزيارة صفحات انترنت معينة على الموقع، تتعرف
              السجل على المتصفح الخاص بك من خلال رقم فريد وعشوائي.
            </li>
            <li>
              كما ان ملفات تعريف الارتباط التي نستخدمها لا تكشف اي من معلوماتك
              الشخصية. ان ملفات تعريف الارتباط هذه تساعدنا في تحسين ادائك على
              الموقع وتعيننا على تفهم اي اجزاء من الموقع هي الاكثر استخداما، انت
              لك مطلق الحرية في الغاء ملفات تعريف الارتباط إذا أتيح لك ذلك من
              المتصفح ولا يؤثر ذلك على استخدامك للموقع.
            </li>
          </ul>

          <h1>حماية معلوماتك الشخصية</h1>
          <ul className="payment">
            <li>
              عند تزودنا بمعلوماتك الشخصية تكون قد وافقت على نقل هذه المعلومات
              خارج دولة مصر، ونحن نتخذ كافة الاحتياطات للمحافظة على معلوماتك
              وذلك بعدم الوصول اليها او استخدامها او الافصاح عنها بشكل غير مخول.
            </li>
            <li>
              كافة المعلومات الشخصية مشفرة، وعلى اي حال فان الانترنت وسيلة غير
              امنة ونحن لا نضمن سرية معلوماتك الشخصية.
            </li>
            <li>
              يجب ان يتم ادخال اسم المستخدم وكلمة السر في كل مرة عند الدخول الى
              حسابك أو القيام بأية معاملات في الموقع.
            </li>
            <li>
              يجب ان يتم اختيار كلمة السر بحذر باستخدام ارقام وحروف واشكال
              مميزة.
            </li>
            <li>لا تشارك اسم المستخدم وكلمة السر مع اي أحد.</li>
            <li>
              في حال كان لديك شك حول اختراق اسم المستخدم وكلمة السر، يرجى على
              الفور الاتصال بفريق خدمة العملاء والعمل على تغيير كلمة السر وذلك
              بالدخول الى قسم حسابي على الموقع.
            </li>
          </ul>
          <h1>كيف تتصل بنا للاستفسار بخصوص (سياسة الخصوصية)</h1>

          <p>
            إذا كان لديك اسئلة او اهتمامات حول جمعنا واستخدامنا لمعلوماتك
            الشخصية، الرجاء الاتصال بفريق خدمة العملاء على رابط "دعم العملاء" في
            أعلى أي صفحة ويب خاصة على الموقع.   محاصيل مصر، الجيزة، الدقي، ٣١
            شارع إيران،
          </p>
        </div>
      </div>
    </>
  );
}

export default privacyPage;
