import React, { useState } from 'react';
import './collapse.css';

function Collapse(props) {
  const [showBody, setShowBody] = useState(false);

  return (
    <li>
      <h2
        className="collapseHeader"
        onClick={() => {
          window.open(props.url);
        }}
      >
        {props.title}
      </h2>
    </li>
  );
}

export default Collapse;
// style={{
//   height: `${showBody ? 'auto' : '0'}`,
// }}
// {showBody && <li className="collapseBody">{props.children}</li>}
