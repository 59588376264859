import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
import Collapse from './collapse/collapse';

function Index() {
  const data = {
    labels: ['2017', '2018', '2019', '2020', '2021'],
    datasets: [
      {
        label: 'المواسم',
        data: [0.114, 0.095, 0.203, 0.232, 0.294],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };

  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [0.94, 3909.138],
            offset: [5],
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
  };
  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/12/main.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  البرقوق
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    يطلق على هذا النوع من الفواكه اسم الفواكه الحجرية وذلك
                    لإحتوائها على بذرة كبيرة صلبة في منتصف الثمرة . ويمتلك
                    البرقوق قيمة غذائية عالية ذات أهمية كبيرة لجسم الإنسان .
                  </p>
                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات البرقوق من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات البرقوق المصري مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%0.02</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات البرقوق المصري
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>
                  {/* <div className="blog-quote">
                    <h3 className="details-page-title">إحصائيات</h3>
                  </div> */}

                  {/* <p>
                    <Bar
                      data={data}
                      options={{
                        barPercentage: 0.4,
                        hoverBackgroundColor: '#071327',
                        scales: {
                          y: {
                            title: {
                              display: true,
                              text: 'الكمية بالالف طن',
                              padding: 5,
                              font: {
                                size: 20,
                              },
                            },
                          },
                        },
                      }}
                    />
                  </p> */}

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- ذباب الفاكهة*/}
                      <Collapse title="1- ذباب الفاكهة">
                        {/* <h2 className="details-page-title subTitle">
                          1- ذباب الفاكهة
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/12/zobab.jpeg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ظهور نقط صمغية مكان الوخذات في الثمار الغير
                                ناضجة - في الثمار الناضجة يتحول مكان الوخز إلى
                                لون مختلف ويصبح لينا ويخرج منه سائل عند الضغط
                                على الثمرة وينشأ هذا الضرر من تجوال الذبابة داخل
                                الثمرة وتغذيتها على محتوياتها وكذلك تسرب
                                البكتيريا والفطريات داخل الثمرة
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p> شهر مايو وشهر يونيو</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بداية ظهور الاصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    665
                                  </td>
                                  <td dir="RTL" align="right">
                                    اكتاثيون 57% EC
                                  </td>
                                  <td class="xl67" dir="LTR" align="left">
                                    Malathion
                                  </td>
                                  <td dir="RTL">
                                    100 سم مكعب /<span>&nbsp; </span>20 لتر ماء.
                                    5% ماده جازبه (
                                    <span>حزم قاتله . رش جزئي )</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2- البياض الدقيقي*/}
                      <Collapse title="2- البياض الدقيقي">
                        {/* <h2 className="details-page-title subTitle">
                          2- البياض الدقيقي
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/12/byad.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                مساحات بيضاء دقيقية المظهر على سطح الورقة العلوي
                                وفي كثير من الاحيان السفلي أيضا عند وجود إصابة
                                شديدة
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>بداية من شهر مارس</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند بدء ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3127
                                  </td>
                                  <td dir="RTL" align="right">
                                    كينتوبلص 35 %SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    %25 Pyraclostrobin% + 10 Epoxiconazole
                                    <br />
                                  </td>
                                  <td dir="RTL">
                                    50 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">15</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
