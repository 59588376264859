import React from 'react';
import './sub.css';
import { Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
// import { Button, Collapse, UncontrolledCollapse } from 'reactstrap';
import Collapse from './collapse/collapse';

function Index() {
  const data = {
    labels: ['2014', '2015', '2016', '2017', '2018'],
    datasets: [
      {
        label: 'المواسم',
        data: [61.726, 65.896, 51.547, 23.921, 27.22],
        borderColor: '#000',
        backgroundColor: '#3f9f42',
      },
    ],
  };
  const dashboardRequestsStatisticsChart = {
    data: (canvas) => {
      // canvas.style.width = '1000px';
      canvas.style.height = '200px';
      // console.log(canvas);
      return {
        labels: ['مصر', 'العالم'],
        datasets: [
          {
            label: '# of Votes',
            pointRadius: 20,
            pointHoverRadius: 10,
            backgroundColor: ['#3f9f42', '#f7b06d'],
            borderWidth: 2,
            data: [203.09, 10792.645],
            offset: [5],
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: { enabled: true },
      },
      maintainAspectRatio: false,
      // radius: '150%',
      aspectRatio: 300,
      pieceLabel: {
        render: 'percentage',
        fontColor: ['white'],
        precision: 10,
      },
    },
    // plugins: [ChartDataLabels],
  };

  return (
    <>
      <section className="details-text-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-details-text-area">
                <img
                  className="details-main-img mainImg"
                  src="/assets/images/media/crops/05/main.jpg"
                  alt="img"
                />
                <h1 className="details-page-title text-center font-weight-bolder mainHeader">
                  الجوافة
                </h1>
                <div className="bdt-text">
                  <div className="blog-quote">
                    <h3 className="details-page-title">المقدمة</h3>
                  </div>

                  <p>
                    تعد الجوافة من اهم المحاصيل الاقتصادية . كما إنها ذات اهمية
                    غذائية عالية لجسم الإنسان فهى تحتوي علي أعلي نسبة من فيتامين
                    ج إذا قورنت بثمار الفواكه الأخري . وايضا لها قيمة طبية عالية
                    . وتمتاز الجوافة المصرية بجودتها العالية ومع كل عام يزداد
                    الإقبال عليها ويزيد حجم الصادرات من الجوافة المصرية وذلك
                    طبقا لتقارير الحكومة المصرية
                  </p>

                  <div className="blog-quote">
                    <h3 className="details-page-title">
                      حجم صادرات الجوافة من مصر
                    </h3>
                  </div>
                  <Row style={{ margine: '0' }}>
                    <Col md="4">
                      <p>حصة صادرات الجوافة المصرية مقارنةً بالعالم</p>

                      <p
                        // style={{
                        //   marginRight: '7.7em',
                        //   marginTop: '3.5em',
                        //   fontSize: '0.8rem',
                        //   color: '#666666',
                        // }}
                        className="publicchartsp123"
                      >
                        إجمالي نسبة مصر:{' '}
                        <span style={{ color: '#3f9f42' }}>%1.88</span>
                      </p>
                      <p
                        // style={{ marginTop: '-1em', color: 'green' }}
                        className="publicchartspie123"
                      >
                        <Pie
                          data={dashboardRequestsStatisticsChart.data}
                          options={dashboardRequestsStatisticsChart.options}
                          // plugins={[ChartDataLabels]}
                        />
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        المصادر:{' '}
                        <a
                          href="https://intracen.org/"
                          target="_blank"
                          rel="noreferrer"
                          // style={{ color: '#666666' }}
                          style={{ color: 'green' }}
                        >
                          الموقع العالمي للتجارة
                        </a>
                      </p>
                    </Col>
                    <Col md="8">
                      <p>
                        حجم صادرات الجوافة المصرية
                        <Bar
                          data={data}
                          options={{
                            barPercentage: 0.4,
                            hoverBackgroundColor: '#071327',
                            scales: {
                              y: {
                                title: {
                                  display: true,
                                  text: 'الكمية بالأف طن',
                                  padding: 5,
                                  font: {
                                    size: 20,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      </p>
                    </Col>
                  </Row>

                  <div className="blog-quote">
                    <h3 className="details-page-title">الآفات وطرق مكافحتها</h3>
                  </div>

                  <div className="support-list">
                    <ul>
                      {/*1- الحشرة القشرية الرخوة ( البولفيناريا )*/}
                      <Collapse title="1- الحشرة القشرية الرخوة ( البولفيناريا )">
                        {/* <h2 className="details-page-title subTitle">
                          1- الحشرة القشرية الرخوة ( البولفيناريا )
                        </h2> */}

                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/05/qshrya.png"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                وجود الحشرات الكاملة والحوريات على الأوراق
                                والأفرع والثمار - نمو فطر العفن الأسود - وجود
                                النمل الذي يتغذى على الندوة العسلية التي تفرزها
                                هذه الحشرات
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>
                              ظهور الحشرات الكاملة والحوريات طوال العام مع قضاء
                              فترة الشتاء على هيئة أكياس بيض أو حشرات بالغة في
                              الشقوق والأجزاء السفلي من الشجرة والأفرع الداخلية
                            </p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>
                              عند وصول الإصابة حوالي 5% من مجمل الأشجار التي يتم
                              فحصها عشوائيا مع الأخذ في الاعتبار نسبة الإصابة
                              بالشجرة الواحدة
                            </p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    176
                                  </td>
                                  <td dir="RTL" align="right">
                                    البوليوم 80% مايونيز<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Mineral Oil
                                  </td>
                                  <td dir="RTL">
                                    2.5 لتر / 100 لتر ماء ( رش شتوى)
                                  </td>
                                  <td dir="LTR">8</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    174
                                  </td>
                                  <td dir="RTL" align="right">
                                    كزد أويل 95%EC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Mineral Oil
                                  </td>
                                  <td dir="RTL">
                                    105 لتر / 100 لتر ماء ( رش شتوى)
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/*2- ذباب الفاكهة*/}
                      <Collapse title="2- ذباب الفاكهة">
                        {/* <h2 className="details-page-title subTitle">
                          2- ذباب الفاكهة
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/05/zobab.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                ظهور وخزات بلون أخضر داكن على جسم الثمرة ولين في
                                منطقة الوخزنتيجة تغذية اليرقات داخل الثمرة مع
                                خروج سائل من الثقوب في الثمارالكاملة النضج
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>في شهري أغسطس وسبتمبر</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p> عند ظهور الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    174
                                  </td>
                                  <td dir="RTL" align="right">
                                    كزد أويل 95%EC
                                  </td>
                                  <td dir="LTR" align="left">
                                    Mineral Oil
                                  </td>
                                  <td dir="RTL">
                                    105 لتر / 100 لتر ماء ( رش شتوى)
                                  </td>
                                  <td dir="LTR">12</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>

                      {/* 3- تبقع الأوراق */}
                      <Collapse title="3- تبقع الأوراق ">
                        {/* <h2 className="details-page-title subTitle">
                          3- تبقع الأوراق
                        </h2> */}
                        <ul>
                          <li>
                            <div className="imgContainer">
                              <img
                                className="details-main-img ourimgs"
                                src="/assets/images/media/crops/05/tbq3.jpg"
                                alt="img"
                              />
                              <h3 className="h5">مظهر الإصابة</h3>
                              <p>
                                هذا المرض ينتج عن الإصابة بالفطر Cercospora
                                psidii أو الفطر Cercospora sawadae وتكون علي
                                هيئة بقع بيضاء على الأوراق مستديرة أو غير منتظمة
                                مكونة لون بني محمر أو بني وعادة ما تصاب الأوراق
                                القديمة وعند شدة الإصابة تتجعد هذه الأوراق وتسقط
                              </p>
                            </div>
                          </li>
                          <li>
                            <h3 className="h5">ميعاد ظهور الإصابة</h3>
                            <p>يزداد المرض من شهر ديسمبر إلى شهر فبراير</p>
                          </li>

                          <li>
                            <h3 className="h5">توقيت المكافحة</h3>
                            <p>عند ظهورأعراض الإصابة</p>
                          </li>
                          <li>
                            <h3 className="h5">
                              طرق المكافحة{' '}
                              <span className="troqMokaf7a">
                                (طبقا لتوصيات "لجنة مبيدات الآفات الزراعية")
                              </span>
                            </h3>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">رقم التسجيل</th>
                                  <th scope="col">
                                    الاسم التجارى للمبيد والتركيز
                                  </th>
                                  <th scope="col">الاسم العام</th>
                                  <th scope="col">معدل الاستخدام</th>
                                  <th scope="col">"فترة الامان (PH)يوم"</th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr>
                                  <td dir="LTR" align="right">
                                    1192
                                  </td>
                                  <td dir="RTL" align="right">
                                    اميستار توب 32.5% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Azoxystrobin<span>&nbsp; </span>
                                    <br />
                                    Difenoconazole<span>&nbsp; </span>
                                    <br />
                                  </td>
                                  <td dir="RTL">
                                    50 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">7</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    2918
                                  </td>
                                  <td dir="RTL" align="right">
                                    ترولز 25% EW<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Tebuconazole<span>&nbsp;</span>
                                  </td>
                                  <td dir="RTL">
                                    50 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">3</td>
                                </tr>
                                <tr>
                                  <td dir="LTR" align="right">
                                    3237
                                  </td>
                                  <td dir="RTL" align="right">
                                    لاسيرتا ماكس 32.5% SC<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR" align="left">
                                    Azoxystrobin<span>&nbsp; </span>
                                    <br />
                                    Difenoconazole<span>&nbsp; </span>
                                    <br />
                                  </td>
                                  <td dir="RTL">
                                    50 سم مكعب / 100 لتر ماء<span>&nbsp;</span>
                                  </td>
                                  <td dir="LTR">14</td>
                                </tr>
                              </tbody>
                            </table>
                          </li>
                        </ul>
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
