import React from 'react';
import './countries-Table.css';
// Citrus
// Tomato
// onionsAndShallots
// Potatos
// GuavaAndMango
// Grapes
// Strawberry
// plums
// apricots
// peachs
// Pepper

const data = {
  المانيا: {
    Citrus: [
      873805, 919387, 851169, 976502, 936681, 21452, 26564, 23652, 18502,
      1030349.1000000001, 1133384.0100000002, 1246722.4110000003,
    ],
    Grapes: [
      1484980, 1499794, 1426777, 1559667, 1685740, 6, 1, 48, 0,
      1854314.0000000002, 2039745.4000000004, 2243719.9400000004,
    ],
    GuavaAndMango: [
      242998, 242856, 360218, 253276, 223566, 11378, 9331, 13796, 14444,
      245922.6, 270514.86000000004, 297566.3460000001,
    ],
    Pepper: [
      77695, 63872, 55891, 81193, 77464, 0, 0, 0, 0, 85210.40000000001,
      93731.44000000002, 103104.58400000003,
    ],
    Potatos: [
      268721, 287865, 305410, 354300, 387790, 12457, 14396, 12724, 10062,
      426569.00000000006, 469225.9000000001, 516148.4900000001,
    ],
    Strawberry: [
      149719, 179332, 236849, 186766, 200585, 842, 732, 4099, 9798,
      220643.50000000003, 242707.85000000006, 266978.63500000007,
    ],
    Tomato: [
      1212946, 1321919, 1180736, 1565169, 1509903, 548, 964, 424, 1084,
      1660893.3, 1826982.6300000001, 2009680.8930000004,
    ],
    apricots: [
      110770, 110991, 102631, 102691, 112740, 0, 0, 0, 0, 124014.00000000001,
      136415.40000000002, 150056.94000000003,
    ],
    onionsAndShallots: [
      202296, 227083, 198000, 222814, 243107, 274, 205, 141, 381, 267417.7,
      294159.47000000003, 323575.4170000001,
    ],
    peachs: [
      375452, 401166, 355319, 418262, 403118, 2, 2, 0, 21, 443429.80000000005,
      487772.7800000001, 536550.0580000002,
    ],
    plums: [
      188275, 109272, 98232, 86089, 123654, 19, 1, 0, 0, 136019.40000000002,
      149621.34000000003, 164583.47400000005,
    ],
  },
  النمسا: {
    Citrus: [
      92472, 89448, 88625, 105000, 0, 1134, 717, 848, 1187, 120749.99999999999,
      132825, 146107.5,
    ],
    Grapes: [
      93761, 95151, 90061, 85396, 0, 0, 0, 0, 0, 98205.4, 108025.94,
      118828.53400000001,
    ],
    GuavaAndMango: [
      35255, 41343, 48606, 43803, 0, 0, 0, 0, 0, 50373.45, 55410.795,
      60951.874500000005,
    ],
    Pepper: [
      8979, 8955, 7714, 11114, 0, 0, 0, 0, 0, 12781.099999999999, 14059.21,
      15465.131,
    ],
    Potatos: [
      53963, 55783, 65837, 57436, 0, 16, 0, 0, 19, 66051.4, 72656.54, 79922.194,
    ],
    Strawberry: [
      9865, 12044, 16926, 12625, 0, 208, 78, 566, 265, 14518.749999999998,
      15970.625, 17567.6875,
    ],
    Tomato: [
      160589, 183370, 174366, 207058, 0, 1, 0, 0, 0, 238116.69999999998,
      261928.37, 288121.207,
    ],
    apricots: [
      22201, 21827, 18842, 21059, 0, 0, 0, 0, 0, 24217.85, 26639.635000000002,
      29303.598500000004,
    ],
    onionsAndShallots: [
      20663, 22118, 21418, 19986, 0, 9, 7, 17, 37, 22983.899999999998, 25282.29,
      27810.519000000004,
    ],
    peachs: [
      35513, 39392, 32535, 36769, 0, 0, 0, 0, 0, 42284.35, 46512.785,
      51164.06350000001,
    ],
    plums: [
      19712, 12893, 10595, 10222, 0, 0, 0, 0, 0, 11755.3, 12930.83, 14223.913,
    ],
  },
  بلجيكا: {
    Citrus: [
      152130, 155547, 140983, 136598, 0, 1518, 566, 2826, 455,
      157087.69999999998, 172796.47, 190076.11700000003,
    ],
    Grapes: [
      145542, 101913, 105453, 94605, 0, 0, 126, 8, 0, 108795.74999999999,
      119675.325, 131642.8575,
    ],
    GuavaAndMango: [
      508628, 556181, 753420, 616125, 0, 0, 638, 7539, 7838, 708543.75,
      779398.1250000001, 857337.9375000002,
    ],
    Pepper: [
      22120, 20283, 16120, 17823, 0, 0, 0, 0, 0, 20496.449999999997,
      22546.094999999998, 24800.7045,
    ],
    Potatos: [
      114041, 119266, 111904, 130907, 0, 13876, 16566, 20130, 19429, 150543.05,
      165597.355, 182157.09050000002,
    ],
    Strawberry: [
      79447, 73062, 89636, 78673, 0, 438, 71, 1558, 601, 90473.95, 99521.345,
      109473.47950000002,
    ],
    Tomato: [
      320251, 288145, 249365, 312555, 0, 832, 1540, 1655, 1561, 359438.25,
      395382.075, 434920.28250000003,
    ],
    apricots: [
      16939, 15454, 15477, 11767, 0, 12, 0, 0, 0, 13532.05, 14885.255000000001,
      16373.780500000003,
    ],
    onionsAndShallots: [
      54528, 57626, 56714, 80233, 0, 18, 12, 5, 30, 92267.95,
      101494.74500000001, 111644.21950000002,
    ],
    peachs: [
      90791, 62402, 53656, 62796, 0, 311, 86, 30, 363, 72215.4, 79436.94,
      87380.634,
    ],
    plums: [
      13423, 8172, 5454, 5864, 0, 0, 0, 0, 0, 6743.599999999999, 7417.96,
      8159.756,
    ],
  },
  كندا: {
    Citrus: [
      482642, 484911, 499391, 511570, 532823, 0, 170, 173, 104, 586105.3,
      644715.8300000001, 709187.4130000002,
    ],
    Grapes: [
      332797, 321128, 305569, 319139, 320670, 10, 7, 0, 0, 352737, 388010.7,
      426811.77,
    ],
    GuavaAndMango: [
      96447, 91520, 107679, 89123, 103917, 0, 0, 0, 0, 114308.70000000001,
      125739.57000000002, 138313.52700000003,
    ],
    Pepper: [
      35282, 32140, 30311, 32606, 35405, 0, 0, 0, 0, 38945.5, 42840.05,
      47124.05500000001,
    ],
    Potatos: [
      351032, 339574, 327629, 354463, 433734, 17, 0, 4, 9, 477107.4, 524818.14,
      577299.954,
    ],
    Strawberry: [
      137658, 149316, 178602, 160572, 189035, 299, 306, 803, 539,
      207938.50000000003, 228732.35000000006, 251605.58500000008,
    ],
    Tomato: [
      581233, 612071, 566474, 610916, 654898, 2780, 3330, 1601, 1169, 720387.8,
      792426.5800000001, 871669.2380000001,
    ],
    apricots: [
      8657, 8072, 8385, 7156, 8474, 0, 0, 0, 0, 9321.400000000001,
      10253.540000000003, 11278.894000000004,
    ],
    onionsAndShallots: [
      106415, 101851, 118391, 121389, 142205, 102, 179, 342, 137, 156425.5,
      172068.05000000002, 189274.85500000004,
    ],
    peachs: [
      80911, 76322, 74298, 84956, 84003, 0, 0, 0, 0, 92403.3, 101643.63,
      111807.99300000002,
    ],
    plums: [
      31233, 21229, 18312, 18464, 25793, 0, 0, 0, 0, 28372.300000000003,
      31209.530000000006, 34330.48300000001,
    ],
  },
  الصين: {
    Citrus: [
      632876, 639149, 702670, 676293, 0, 262, 434, 1618, 69, 1014439.5,
      1115883.4500000002, 1227471.7950000004,
    ],
    Grapes: [0, 0, 0, 0, 0, 0, 0, 0, 7, 0, 0, 0],
    GuavaAndMango: [11, 7, 1, 0, 0, 11, 0, 0, 11, 0, 0, 0],
    Pepper: [
      103451, 117520, 194247, 154781, 0, 0, 0, 0, 0, 232171.5,
      255388.65000000002, 280927.5150000001,
    ],
    Potatos: [245, 5, 4, 0, 0, 103, 18, 230, 333, 0, 0, 0],
    Strawberry: [
      1107, 1293, 520, 37, 0, 18, 0, 0, 3, 55.5, 61.050000000000004,
      67.15500000000002,
    ],
    Tomato: [
      551894, 633749, 594239, 491602, 0, 56612, 58881, 106676, 67265, 737403,
      811143.3, 892257.6300000001,
    ],
    apricots: [0, 119, 35, 25, 0, 0, 0, 0, 0, 37.5, 41.25, 45.37500000000001],
    onionsAndShallots: [
      166745, 370644, 822329, 752766, 0, 12, 0, 0, 1, 1129149,
      1242063.9000000001, 1366270.2900000003,
    ],
    peachs: [
      24922, 58437, 74979, 92983, 0, 11, 0, 0, 0, 139474.5, 153421.95,
      168764.14500000002,
    ],
    plums: [
      20747, 23256, 38363, 60786, 0, 0, 0, 0, 0, 91179, 100296.90000000001,
      110326.59000000003,
    ],
  },
  فرنسا: {
    Citrus: [
      292658, 279842, 251207, 280630, 308788, 494, 534, 855, 506,
      339666.80000000005, 373633.4800000001, 410996.82800000015,
    ],
    Grapes: [
      705298, 701818, 704469, 719891, 815505, 0, 30, 0, 1, 897055.5000000001,
      986761.0500000002, 1085437.1550000003,
    ],
    GuavaAndMango: [
      136049, 110753, 158367, 102101, 117863, 317, 0, 35, 137,
      129649.30000000002, 142614.23000000004, 156875.65300000005,
    ],
    Pepper: [
      20016, 22041, 13145, 18569, 28960, 0, 0, 0, 0, 31856.000000000004,
      35041.600000000006, 38545.76000000001,
    ],
    Potatos: [
      184609, 173740, 164256, 164639, 208114, 1728, 2025, 1452, 1437,
      228925.40000000002, 251817.94000000003, 276999.73400000005,
    ],
    Strawberry: [
      85883, 92254, 109445, 86215, 89149, 227, 117, 1351, 593,
      98063.90000000001, 107870.29000000002, 118657.31900000003,
    ],
    Tomato: [
      1233973, 1236900, 1118056, 1358146, 1321027, 2587, 5096, 4086, 4813,
      1453129.7000000002, 1598442.6700000004, 1758286.9370000006,
    ],
    apricots: [
      31066, 35955, 23981, 35478, 38621, 0, 0, 0, 0, 42483.100000000006,
      46731.41000000001, 51404.551000000014,
    ],
    onionsAndShallots: [
      133303, 145958, 152427, 142594, 155635, 105, 98, 123, 159, 171198.5,
      188318.35, 207150.18500000003,
    ],
    peachs: [
      202289, 220156, 181444, 206877, 232587, 22, 14, 22, 9, 255845.7,
      281430.27, 309573.297,
    ],
    plums: [
      60355, 39531, 32427, 32870, 49124, 0, 0, 0, 0, 54036.4, 59440.04000000001,
      65384.044000000016,
    ],
  },
  إيطاليا: {
    Citrus: [
      92176, 88414, 95372, 84441, 0, 7361, 5402, 5900, 4260, 97107.15,
      106817.865, 117499.65150000002,
    ],
    Grapes: [
      156327, 146084, 166075, 141858, 0, 410, 162, 22, 234, 163136.69999999998,
      179450.37, 197395.407,
    ],
    GuavaAndMango: [
      191939, 187933, 264827, 201321, 0, 23462, 10273, 24353, 15062, 231519.15,
      254671.065, 280138.1715,
    ],
    Pepper: [
      15251, 15906, 13317, 19759, 0, 0, 0, 0, 0, 22722.85, 24995.135000000002,
      27494.648500000003,
    ],
    Potatos: [
      77619, 97146, 93979, 81905, 0, 336, 211, 27, 106, 94190.75,
      103609.82500000001, 113970.80750000002,
    ],
    Strawberry: [
      22941, 34812, 55498, 36612, 0, 1903, 1093, 9527, 2804, 42103.799999999996,
      46314.18, 50945.598000000005,
    ],
    Tomato: [
      459829, 440631, 404372, 479699, 0, 9696, 7545, 3449, 14130, 551653.85,
      606819.235, 667501.1585,
    ],
    apricots: [
      21189, 23575, 15956, 26481, 0, 82, 0, 0, 0, 30453.149999999998,
      33498.465000000004, 36848.31150000001,
    ],
    onionsAndShallots: [
      30852, 32015, 38713, 39559, 0, 164, 61, 146, 243, 45492.85, 50042.135,
      55046.34850000001,
    ],
    peachs: [
      105972, 133604, 103556, 140024, 0, 20, 2, 60, 0, 161027.59999999998,
      177130.36, 194843.396,
    ],
    plums: [
      26197, 18417, 14504, 13500, 0, 12, 0, 0, 0, 15524.999999999998, 17077.5,
      18785.25,
    ],
  },
  اليابان: {
    Citrus: [
      180233, 222575, 237355, 216646, 202361, 7, 18, 0, 0, 222597.1,
      244856.81000000003, 269342.49100000004,
    ],
    Grapes: [
      26752, 34169, 34838, 34994, 33680, 0, 0, 0, 0, 37048, 40752.8,
      44828.08000000001,
    ],
    GuavaAndMango: [
      23582, 15751, 17939, 13341, 28982, 0, 0, 0, 0, 31880.200000000004,
      35068.22000000001, 38575.042000000016,
    ],
    Pepper: [0, 0, 0, 0, 1059, 0, 0, 0, 0, 1164.9, 1281.39, 1409.5290000000002],
    Potatos: [
      30351, 33575, 33383, 36707, 38220, 80, 303, 0, 0, 42042,
      46246.200000000004, 50870.82000000001,
    ],
    Strawberry: [
      135448, 126825, 131341, 99561, 129471, 0, 0, 0, 0, 142418.1,
      156659.91000000003, 172325.90100000004,
    ],
    Tomato: [
      362150, 361635, 342335, 335924, 306860, 0, 0, 0, 0, 337546,
      371300.60000000003, 408430.6600000001,
    ],
    apricots: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    onionsAndShallots: [
      31273, 35201, 35817, 31424, 39852, 0, 0, 0, 0, 43837.200000000004,
      48220.920000000006, 53043.01200000001,
    ],
    peachs: [
      832, 1526, 1091, 1518, 2074, 4, 2, 0, 0, 2281.4, 2509.5400000000004,
      2760.4940000000006,
    ],
    plums: [
      39326, 29536, 25002, 22477, 30739, 0, 0, 0, 0, 33812.9, 37194.19,
      40913.609000000004,
    ],
  },
  المكسيك: {
    Citrus: [
      160114, 181731, 195119, 212522, 0, 0, 0, 0, 0, 244400.3, 268840.33,
      295724.36300000007,
    ],
    Grapes: [3742, 1226, 576, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    GuavaAndMango: [
      48330, 59901, 58824, 60291, 0, 0, 0, 0, 0, 69334.65, 76268.115,
      83894.92650000002,
    ],
    Pepper: [
      11707, 13938, 14481, 12363, 0, 0, 0, 0, 0, 14217.449999999999, 15639.195,
      17203.1145,
    ],
    Potatos: [
      39953, 43305, 44521, 43249, 0, 0, 0, 0, 0, 49736.35, 54709.985,
      60180.9835,
    ],
    Strawberry: [
      48034, 39123, 65811, 63712, 0, 0, 0, 0, 0, 73268.79999999999, 80595.68,
      88655.24799999999,
    ],
    Tomato: [
      15368, 21323, 25980, 27232, 0, 0, 0, 0, 0, 31316.8, 34448.48,
      37893.32800000001,
    ],
    apricots: [
      2852, 1692, 2003, 1204, 0, 0, 0, 0, 0, 1384.6, 1523.06, 1675.366,
    ],
    onionsAndShallots: [
      7177, 9004, 5949, 5757, 0, 0, 0, 0, 0, 6620.549999999999, 7282.605,
      8010.8655,
    ],
    peachs: [
      36497, 47695, 50291, 39909, 0, 0, 0, 0, 0, 45895.35, 50484.885,
      55533.37350000001,
    ],
    plums: [
      19331, 12430, 10786, 8769, 0, 0, 0, 0, 0, 10084.349999999999, 11092.785,
      12202.0635,
    ],
  },
  هولندا: {
    Citrus: [
      787517, 932061, 915374, 907526, 0, 59248, 55422, 50848, 58760,
      1043654.8999999999, 1148020.39, 1262822.429,
    ],
    Grapes: [
      339359, 317412, 312570, 332447, 0, 2234, 4385, 1822, 778, 382314.05,
      420545.455, 462600.0005000001,
    ],
    GuavaAndMango: [
      346126, 374006, 432281, 340144, 0, 982, 223, 3242, 2150, 391165.6,
      430282.16000000003, 473310.37600000005,
    ],
    Pepper: [
      47814, 66506, 51264, 51482, 0, 0, 0, 0, 0, 59204.299999999996, 65124.73,
      71637.20300000001,
    ],
    Potatos: [
      93063, 96712, 107453, 119685, 0, 1980, 3730, 4329, 3184, 137637.75,
      151401.52500000002, 166541.67750000005,
    ],
    Strawberry: [
      106793, 122213, 196677, 125540, 0, 12113, 9222, 40041, 15707, 144371,
      158808.1, 174688.91000000003,
    ],
    Tomato: [
      1015491, 1105080, 1005031, 1166813, 0, 50693, 62352, 42980, 71456,
      1341834.95, 1476018.445, 1623620.2895000002,
    ],
    apricots: [
      8996, 9524, 7621, 8418, 0, 0, 0, 0, 0, 9680.699999999999, 10648.77,
      11713.647,
    ],
    onionsAndShallots: [
      240234, 287591, 334437, 365191, 0, 484, 82, 40, 138, 419969.64999999997,
      461966.615, 508163.27650000004,
    ],
    peachs: [
      78529, 85166, 77309, 73371, 0, 59, 13, 66, 63, 84376.65, 92814.315,
      102095.74650000001,
    ],
    plums: [
      45499, 34204, 27369, 22385, 0, 27, 0, 0, 0, 25742.749999999996,
      28317.024999999998, 31148.7275,
    ],
  },
  بولندا: {
    Citrus: [
      195384, 212475, 215350, 235572, 0, 163, 1057, 835, 639, 270907.8,
      297998.58, 327798.438,
    ],
    Grapes: [
      201807, 242255, 244767, 268647, 0, 0, 0, 0, 0, 308944.05, 339838.455,
      373822.30050000007,
    ],
    GuavaAndMango: [
      32899, 48468, 105507, 63969, 0, 94, 69, 430, 444, 73564.34999999999,
      80920.785, 89012.8635,
    ],
    Pepper: [
      29224, 13380, 16784, 20095, 0, 0, 0, 0, 0, 23109.25, 25420.175000000003,
      27962.192500000005,
    ],
    Potatos: [
      28830, 33371, 36379, 37599, 0, 96, 168, 134, 23, 43238.85, 47562.735,
      52319.0085,
    ],
    Strawberry: [
      9732, 19441, 66307, 43101, 0, 6, 34, 1722, 33, 49566.149999999994,
      54522.765, 59975.04150000001,
    ],
    Tomato: [
      390339, 464326, 217383, 517729, 0, 1526, 3582, 2910, 3915, 595388.35,
      654927.185, 720419.9035000001,
    ],
    apricots: [
      13675, 13529, 16940, 11425, 0, 0, 0, 0, 0, 13138.749999999998, 14452.625,
      15897.8875,
    ],
    onionsAndShallots: [
      23638, 28810, 30951, 29143, 0, 0, 0, 1, 0, 33514.45, 36865.895,
      40552.4845,
    ],
    peachs: [
      106330, 110675, 103844, 92534, 0, 0, 0, 0, 0, 106414.09999999999,
      117055.51, 128761.061,
    ],
    plums: [
      35346, 26753, 21573, 15903, 0, 0, 0, 0, 0, 18288.449999999997, 20117.295,
      22129.0245,
    ],
  },
  روسيا: {
    Citrus: [
      435402, 360601, 371359, 381772, 470952, 25191, 16736, 19464, 22542,
      518047.20000000007, 569851.9200000002, 626837.1120000002,
    ],
    Grapes: [
      558745, 629841, 636650, 525128, 479640, 1190, 2716, 2570, 6629, 527604,
      580364.4, 638400.8400000001,
    ],
    GuavaAndMango: [
      220413, 217926, 132657, 125654, 222152, 105202, 102146, 52843, 57468,
      244367.2, 268803.92000000004, 295684.3120000001,
    ],
    Pepper: [
      57733, 56295, 57578, 60746, 54133, 0, 23, 0, 0, 59546.3,
      65500.93000000001, 72051.02300000002,
    ],
    Potatos: [
      49767, 66216, 77068, 71443, 102942, 6825, 7219, 7648, 6678,
      113236.20000000001, 124559.82000000002, 137015.80200000003,
    ],
    Strawberry: [
      132060, 56916, 78595, 86022, 53985, 31528, 7286, 25195, 24997,
      59383.50000000001, 65321.85000000001, 71854.03500000002,
    ],
    Tomato: [
      1189212, 1231478, 1281039, 1249706, 1260308, 106534, 149557, 120291,
      155130, 1386338.8, 1524972.6800000002, 1677469.9480000003,
    ],
    apricots: [
      44747, 42014, 49445, 57390, 55834, 0, 0, 0, 0, 61417.4, 67559.14000000001,
      74315.05400000002,
    ],
    onionsAndShallots: [
      24261, 50815, 70326, 83592, 101531, 976, 2264, 3206, 4961, 111684.1,
      122852.51000000001, 135137.76100000003,
    ],
    peachs: [
      212060, 207834, 214016, 272568, 282278, 1691, 1103, 1719, 2059,
      310505.80000000005, 341556.38000000006, 375712.0180000001,
    ],
    plums: [
      32501, 22431, 21769, 20232, 26293, 143, 0, 0, 5, 28922.300000000003,
      31814.530000000006, 34995.98300000001,
    ],
  },
  السعودية: {
    Citrus: [
      96469, 95166, 94540, 104168, 0, 10677, 5577, 4618, 6330, 119793.2,
      131772.52000000002, 144949.77200000003,
    ],
    Grapes: [
      69828, 79110, 86784, 126695, 0, 19019, 17779, 14805, 11758, 145699.25,
      160269.17500000002, 176296.09250000003,
    ],
    GuavaAndMango: [
      21618, 31379, 35510, 25455, 0, 613, 963, 613, 3092, 29273.249999999996,
      32200.574999999997, 35420.6325,
    ],
    Pepper: [
      18922, 14271, 13385, 12560, 0, 63, 9, 28, 172, 14443.999999999998,
      15888.4, 17477.24,
    ],
    Potatos: [
      50354, 49804, 55595, 56782, 0, 13192, 3148, 10439, 8729,
      65299.299999999996, 71829.23, 79012.153,
    ],
    Strawberry: [
      138059, 119844, 83625, 96358, 0, 92980, 71556, 33372, 36629, 110811.7,
      121892.87000000001, 134082.15700000004,
    ],
    Tomato: [
      328813, 363609, 358877, 422887, 0, 92567, 121510, 113040, 132785,
      486320.05, 534952.055, 588447.2605000001,
    ],
    apricots: [
      7363, 5893, 6934, 7559, 0, 10, 19, 23, 17, 8692.849999999999,
      9562.134999999998, 10518.348499999998,
    ],
    onionsAndShallots: [
      59828, 42106, 47818, 61196, 0, 20103, 9112, 10971, 13970, 70375.4,
      77412.94, 85154.23400000001,
    ],
    peachs: [
      24144, 26343, 32167, 35813, 0, 2213, 1432, 2068, 1634, 41184.95,
      45303.445, 49833.789500000006,
    ],
    plums: [
      13263, 9695, 10079, 9704, 0, 0, 0, 22, 0, 11159.599999999999, 12275.56,
      13503.116,
    ],
  },
  اسبانيا: {
    Citrus: [
      114309, 134565, 143274, 157292, 179798, 0, 174, 284, 238,
      197777.80000000002, 217555.58000000005, 239311.13800000006,
    ],
    Grapes: [
      133670, 149183, 170890, 173684, 184675, 0, 0, 0, 0, 203142.50000000003,
      223456.75000000006, 245802.42500000008,
    ],
    GuavaAndMango: [
      250416, 255007, 333387, 314118, 277365, 494, 238, 856, 1934, 305101.5,
      335611.65, 369172.81500000006,
    ],
    Pepper: [
      11322, 14375, 9820, 6310, 8971, 0, 0, 0, 0, 9868.1, 10854.910000000002,
      11940.401000000003,
    ],
    Potatos: [
      51358, 46443, 53295, 53903, 39275, 41, 31, 2, 30, 43202.5,
      47522.75000000001, 52275.02500000001,
    ],
    Strawberry: [
      23750, 37835, 45319, 48246, 61586, 34, 217, 1181, 125, 67744.6,
      74519.06000000001, 81970.96600000001,
    ],
    Tomato: [
      247500, 329133, 217114, 288688, 246055, 3928, 6882, 244, 3828, 270660.5,
      297726.55000000005, 327499.2050000001,
    ],
    apricots: [
      1034, 608, 523, 526, 764, 0, 0, 0, 0, 840.4000000000001,
      924.4400000000002, 1016.8840000000002,
    ],
    onionsAndShallots: [
      82763, 100586, 110054, 120251, 130710, 3, 1, 1, 15, 143781, 158159.1,
      173975.01,
    ],
    peachs: [
      5936, 8413, 6265, 4977, 8748, 0, 0, 0, 0, 9622.800000000001,
      10585.080000000002, 11643.588000000003,
    ],
    plums: [
      51803, 21818, 15825, 14522, 21720, 0, 0, 0, 0, 23892.000000000004,
      26281.200000000004, 28909.320000000007,
    ],
  },
  السويد: {
    Citrus: [
      86018, 93085, 84483, 89712, 0, 0, 0, 222, 42, 103168.79999999999,
      113485.68, 124834.248,
    ],
    Grapes: [
      171357, 170318, 158572, 165248, 0, 1, 1, 15, 0, 190035.19999999998,
      209038.72, 229942.59200000003,
    ],
    GuavaAndMango: [
      22169, 24279, 28535, 21601, 0, 0, 0, 1672, 975, 24841.149999999998,
      27325.265, 30057.791500000003,
    ],
    Pepper: [
      6205, 6287, 5230, 6802, 0, 0, 0, 0, 0, 7822.299999999999, 8604.53,
      9464.983000000002,
    ],
    Potatos: [
      28672, 30603, 26915, 29334, 0, 5, 9, 0, 0, 33734.1, 37107.51,
      40818.261000000006,
    ],
    Strawberry: [
      18684, 19961, 26701, 21174, 0, 135, 12, 972, 11, 24350.1, 26785.11,
      29463.621000000003,
    ],
    Tomato: [
      179902, 164353, 149500, 171270, 0, 2639, 2370, 2203, 3119,
      196960.49999999997, 216656.55, 238322.20500000002,
    ],
    apricots: [
      3395, 3662, 3017, 3358, 0, 0, 0, 0, 0, 3861.7, 4247.87, 4672.657,
    ],
    onionsAndShallots: [
      13791, 14166, 12899, 13734, 0, 18, 13, 36, 42, 15794.099999999999,
      17373.51, 19110.861,
    ],
    peachs: [
      26268, 29416, 24330, 29710, 0, 0, 0, 0, 0, 34166.5, 37583.15,
      41341.465000000004,
    ],
    plums: [
      12138, 10295, 8514, 8320, 0, 0, 0, 0, 0, 9568, 10524.800000000001,
      11577.280000000002,
    ],
  },
  سويسرا: {
    Citrus: [
      91268, 83792, 77622, 89233, 94149, 479, 1405, 822, 1475,
      103563.90000000001, 113920.29000000002, 125312.31900000003,
    ],
    Grapes: [
      88808, 85822, 83505, 96483, 108829, 0, 0, 0, 0, 119711.90000000001,
      131683.09000000003, 144851.39900000003,
    ],
    GuavaAndMango: [
      31682, 12784, 15008, 22180, 27526, 0, 0, 0, 155, 30278.600000000002,
      33306.46000000001, 36637.10600000001,
    ],
    Pepper: [
      13557, 9557, 9361, 10860, 15465, 0, 0, 0, 0, 17011.5, 18712.65,
      20583.915000000005,
    ],
    Potatos: [
      55785, 53506, 52575, 56523, 68702, 11, 19, 61, 4, 75572.20000000001,
      83129.42000000001, 91442.36200000002,
    ],
    Strawberry: [
      6758, 7566, 11415, 10994, 13421, 0, 0, 0, 27, 14763.1, 16239.410000000002,
      17863.351000000002,
    ],
    Tomato: [
      204599, 211178, 195685, 243899, 236318, 40, 25, 0, 0, 259949.80000000002,
      285944.78, 314539.25800000003,
    ],
    apricots: [
      24445, 18996, 17981, 20751, 31498, 0, 0, 0, 0, 34647.8, 38112.58000000001,
      41923.83800000001,
    ],
    onionsAndShallots: [
      47081, 46416, 45811, 52713, 52757, 5, 7, 6, 11, 58032.700000000004,
      63835.97000000001, 70219.56700000001,
    ],
    peachs: [
      53020, 54010, 51604, 63299, 63557, 0, 0, 0, 0, 69912.70000000001,
      76903.97000000002, 84594.36700000003,
    ],
    plums: [
      5086, 4140, 3867, 4067, 4045, 0, 0, 0, 0, 4449.5, 4894.450000000001,
      5383.895000000001,
    ],
  },
  بريطانيا: {
    Citrus: [
      859885, 886409, 886452, 896952, 861584, 58278, 60039, 57528, 62697,
      947742.4, 1042516.6400000001, 1146768.3040000002,
    ],
    Grapes: [
      671772, 700166, 648398, 636456, 620891, 282, 193, 246, 445,
      682980.1000000001, 751278.1100000002, 826405.9210000003,
    ],
    GuavaAndMango: [
      119784, 112920, 146574, 138163, 69589, 4527, 2051, 2216, 1848,
      76547.90000000001, 84202.69000000002, 92622.95900000003,
    ],
    Pepper: [
      66346, 65506, 53897, 58296, 58023, 0, 0, 0, 0, 63825.3, 70207.83,
      77228.61300000001,
    ],
    Potatos: [
      221799, 206977, 212095, 239526, 293600, 4164, 6978, 6993, 9111, 322960,
      355256, 390781.60000000003,
    ],
    Strawberry: [
      171951, 229772, 295654, 194880, 201740, 4997, 6608, 27471, 32157,
      221914.00000000003, 244105.40000000005, 268515.94000000006,
    ],
    Tomato: [
      822873, 839802, 788802, 897348, 825745, 19989, 24863, 22173, 24691,
      908319.5000000001, 999151.4500000002, 1099066.5950000002,
    ],
    apricots: [
      24254, 23064, 20159, 18734, 18149, 8, 0, 0, 0, 19963.9,
      21960.290000000005, 24156.319000000007,
    ],
    onionsAndShallots: [
      170851, 179548, 175222, 163382, 230473, 1561, 882, 1067, 1341,
      253520.30000000002, 278872.33, 306759.563,
    ],
    peachs: [
      121609, 135276, 143809, 131259, 123628, 1134, 1133, 985, 778,
      135990.80000000002, 149589.88000000003, 164548.86800000005,
    ],
    plums: [
      32074, 23995, 21753, 19487, 23171, 57, 1, 0, 21, 25488.100000000002,
      28036.910000000003, 30840.601000000006,
    ],
  },
  الامارات: {
    Citrus: [
      119082, 101030, 92242, 97419, 0, 7757, 5140, 4926, 5308,
      112031.84999999999, 123235.035, 135558.53850000002,
    ],
    Grapes: [
      115782, 100664, 86473, 64643, 0, 1775, 4703, 6276, 2368, 74339.45,
      81773.395, 89950.7345,
    ],
    GuavaAndMango: [
      73994, 64633, 58206, 69882, 0, 22238, 14331, 9886, 19696,
      80364.29999999999, 88400.73, 97240.803,
    ],
    Pepper: [
      21933, 19603, 20201, 18466, 0, 8, 0, 21, 78, 21235.899999999998,
      23359.489999999998, 25695.439,
    ],
    Potatos: [
      37091, 36058, 40159, 43936, 0, 6249, 4779, 6630, 5948, 50526.399999999994,
      55579.04, 61136.944,
    ],
    Strawberry: [
      114034, 90827, 89108, 101418, 0, 23821, 3033, 12952, 3557, 116630.7,
      128293.77, 141123.14700000003,
    ],
    Tomato: [
      319131, 248507, 237904, 294274, 0, 35789, 37032, 32310, 39401, 338415.1,
      372256.61, 409482.271,
    ],
    apricots: [
      4749, 4098, 3613, 4845, 0, 8, 1, 2, 8, 5571.75, 6128.925,
      6741.817500000001,
    ],
    onionsAndShallots: [
      98370, 87266, 86503, 83485, 0, 7482, 3721, 4147, 6967, 96007.74999999999,
      105608.525, 116169.3775,
    ],
    peachs: [
      22299, 19691, 18888, 20977, 0, 678, 590, 717, 690, 24123.55,
      26535.905000000002, 29189.495500000005,
    ],
    plums: [
      63959, 35347, 33023, 37689, 0, 0, 2, 0, 48, 43342.35, 47676.585,
      52444.243500000004,
    ],
  },
  'الولايات المتحده الامريكية': {
    Citrus: [
      1753390, 1833666, 1848267, 1918481, 2017805, 34, 9, 119, 0, 2219585.5,
      2441544.0500000003, 2685698.4550000005,
    ],
    Grapes: [
      2272435, 2485922, 2390594, 2918067, 2911618, 0, 0, 0, 0,
      3202779.8000000003, 3523057.7800000007, 3875363.558000001,
    ],
    GuavaAndMango: [
      232289, 243602, 220282, 285707, 280781, 0, 0, 0, 0, 308859.10000000003,
      339745.01000000007, 373719.5110000001,
    ],
    Pepper: [
      52472, 56605, 52418, 45110, 50252, 0, 0, 0, 0, 55277.200000000004,
      60804.92000000001, 66885.41200000003,
    ],
    Potatos: [
      642573, 609567, 871269, 849306, 1085037, 96, 41, 0, 234,
      1193540.7000000002, 1312894.7700000003, 1444184.2470000004,
    ],
    Strawberry: [
      435898, 445013, 458979, 471259, 537289, 65, 137, 75, 25, 591017.9,
      650119.6900000001, 715131.6590000001,
    ],
    Tomato: [
      1223128, 1466503, 1390785, 1552077, 1810980, 237, 74, 92, 132,
      1992078.0000000002, 2191285.8000000003, 2410414.3800000004,
    ],
    apricots: [
      2515, 3662, 3168, 1943, 2562, 0, 0, 0, 0, 2818.2000000000003,
      3100.0200000000004, 3410.022000000001,
    ],
    onionsAndShallots: [
      655950, 637205, 658227, 718787, 818223, 1, 22, 0, 8, 900045.3,
      990049.8300000001, 1089054.813,
    ],
    peachs: [
      90062, 97441, 87395, 80697, 86161, 0, 0, 0, 0, 94777.1,
      104254.81000000001, 114680.29100000003,
    ],
    plums: [
      383251, 221372, 185928, 178967, 257146, 0, 0, 0, 67, 282860.60000000003,
      311146.6600000001, 342261.3260000001,
    ],
  },
  الكويت: {
    Citrus: [
      20516, 24673, 31081, 19676, 0, 3752, 1795, 2610, 1481, 22627.399999999998,
      24890.14, 27379.154000000002,
    ],
    Grapes: [
      36872, 32509, 34718, 31348, 0, 848, 2257, 1827, 330, 36050.2, 39655.22,
      43620.742000000006,
    ],
    GuavaAndMango: [
      39293, 39282, 34923, 37484, 0, 15697, 8679, 3463, 6955, 43106.6, 47417.26,
      52158.986000000004,
    ],
    Pepper: [
      9903, 6105, 9483, 8180, 0, 13, 9, 3, 7, 9407, 10347.7, 11382.470000000001,
    ],
    Potatos: [
      20640, 23922, 24151, 31611, 0, 9114, 1373, 1421, 1056, 36352.649999999994,
      39987.91499999999, 43986.70649999999,
    ],
    Strawberry: [
      31779, 37638, 40789, 49416, 0, 2233, 2351, 2744, 5027, 56828.399999999994,
      62511.24, 68762.364,
    ],
    Tomato: [
      89701, 88514, 79301, 86980, 0, 23145, 16447, 12133, 10200,
      100026.99999999999, 110029.7, 121032.67000000001,
    ],
    apricots: [
      2201, 3502, 5440, 7287, 0, 14, 3, 1, 5, 8380.05, 9218.055, 10139.8605,
    ],
    onionsAndShallots: [
      22501, 18605, 23112, 20683, 0, 3789, 2398, 2316, 3258, 23785.449999999997,
      26163.995, 28780.394500000002,
    ],
    peachs: [
      10771, 9598, 15277, 7618, 0, 7649, 577, 622, 334, 8760.699999999999,
      9636.77, 10600.447000000002,
    ],
    plums: [
      3217, 2748, 2013, 2329, 0, 1, 0, 0, 0, 2678.35, 2946.185, 3240.8035,
    ],
  },
  ماليزيا: {
    Citrus: [
      84889, 91089, 98273, 95659, 113877, 4659, 6822, 7994, 7038,
      125264.70000000001, 137791.17, 151570.28700000004,
    ],
    Grapes: [
      1621, 2341, 1008, 1017, 2893, 0, 0, 0, 0, 3182.3, 3500.5300000000007,
      3850.583000000001,
    ],
    GuavaAndMango: [
      74682, 72555, 78090, 85975, 81493, 161, 168, 0, 237, 89642.3,
      98606.53000000001, 108467.18300000002,
    ],
    Pepper: [
      6552, 6231, 6862, 5485, 5876, 0, 0, 0, 0, 6463.6, 7109.960000000001,
      7820.956000000002,
    ],
    Potatos: [
      7672, 9549, 10844, 11391, 15498, 650, 653, 1007, 1225, 17047.800000000003,
      18752.580000000005, 20627.838000000007,
    ],
    Strawberry: [
      173934, 181336, 179596, 211263, 207246, 807, 850, 2551, 2966, 227970.6,
      250767.66000000003, 275844.42600000004,
    ],
    Tomato: [
      151503, 169729, 167983, 156441, 208019, 19565, 22951, 21687, 19575,
      228820.90000000002, 251702.99000000005, 276873.28900000005,
    ],
    apricots: [
      431, 548, 517, 498, 528, 0, 0, 0, 0, 580.8000000000001, 638.8800000000001,
      702.7680000000001,
    ],
    onionsAndShallots: [
      20009, 23606, 17609, 24007, 30624, 15, 106, 0, 3, 33686.4,
      37055.04000000001, 40760.54400000001,
    ],
    peachs: [
      3151, 4246, 4492, 4876, 5160, 20, 23, 17, 0, 5676.000000000001,
      6243.600000000001, 6867.960000000002,
    ],
    plums: [
      12541, 7943, 8062, 6242, 11342, 0, 0, 0, 0, 12476.2, 13723.820000000002,
      15096.202000000003,
    ],
  },
  العراق: {
    Citrus: [
      38100, 39139, 9450, 46782, 0, 89, 76, 6, 0, 53799.299999999996, 59179.23,
      65097.153000000006,
    ],
    Grapes: [
      126547, 195017, 22051, 145482, 0, 798, 657, 3184, 0, 167304.3, 184034.73,
      202438.20300000004,
    ],
    GuavaAndMango: [
      103908, 157076, 60859, 75773, 0, 2354, 3470, 7663, 11962, 87138.95,
      95852.845, 105438.12950000001,
    ],
    Pepper: [
      33778, 25205, 10347, 53692, 0, 0, 0, 0, 0, 61745.799999999996, 67920.38,
      74712.418,
    ],
    Potatos: [
      8434, 7126, 7200, 4228, 0, 2816, 3239, 3915, 861, 4862.2, 5348.42,
      5883.262000000001,
    ],
    Strawberry: [
      40648, 108932, 11284, 99655, 0, 4335, 729, 265, 2099, 114603.24999999999,
      126063.575, 138669.9325,
    ],
    Tomato: [
      193919, 262412, 149090, 251359, 0, 15651, 13856, 4894, 3058, 289062.85,
      317969.135, 349766.04850000003,
    ],
    apricots: [
      12730, 12965, 9635, 21477, 0, 0, 0, 0, 0, 24698.55, 27168.405000000002,
      29885.245500000005,
    ],
    onionsAndShallots: [
      89, 117, 84, 556, 0, 15, 47, 63, 0, 639.4, 703.34, 773.6740000000001,
    ],
    peachs: [
      26092, 24808, 40875, 59500, 0, 5758, 8522, 7353, 223, 68425, 75267.5,
      82794.25,
    ],
    plums: [
      5092, 4311, 4506, 5912, 0, 0, 0, 0, 0, 6798.799999999999,
      7478.679999999999, 8226.548,
    ],
  },
  اكرانيا: {
    Citrus: [
      34347, 30978, 44503, 57808, 0, 346, 203, 702, 960, 66479.2,
      73127.12000000001, 80439.83200000002,
    ],
    Grapes: [
      23099, 37154, 49792, 77892, 0, 37, 83, 71, 36, 89575.79999999999,
      98533.37999999999, 108386.718,
    ],
    GuavaAndMango: [
      2035, 3008, 47419, 62710, 0, 329, 854, 1490, 2795, 72116.5,
      79328.15000000001, 87260.96500000001,
    ],
    Pepper: [904, 751, 2548, 3088, 0, 0, 0, 0, 0, 3551.2, 3906.32, 4296.952],
    Potatos: [
      384, 465, 760, 867, 0, 18, 9, 20, 70, 997.05, 1096.755,
      1206.4305000000002,
    ],
    Strawberry: [
      2759, 5065, 21300, 14836, 0, 54, 137, 2138, 1403, 17061.399999999998,
      18767.54, 20644.294,
    ],
    Tomato: [
      171570, 216511, 256445, 292601, 0, 17700, 30507, 31363, 34856,
      336491.14999999997, 370140.265, 407154.29150000005,
    ],
    apricots: [
      2601, 2142, 5893, 7818, 0, 0, 0, 0, 0, 8990.699999999999, 9889.77,
      10878.747000000001,
    ],
    onionsAndShallots: [
      1655, 3280, 6687, 7610, 0, 0, 0, 43, 78, 8751.5, 9626.650000000001,
      10589.315000000002,
    ],
    peachs: [
      24919, 18746, 35311, 43757, 0, 0, 60, 110, 16, 50320.549999999996,
      55352.605, 60887.86550000001,
    ],
    plums: [
      8723, 8331, 5794, 6223, 0, 12, 0, 0, 0, 7156.45, 7872.095, 8659.3045,
    ],
  },

  فنلندا: {
    Citrus: [
      53285, 60090, 56407, 58718, 0, 0, 0, 0, 0, 67525.7, 74278.27,
      81706.09700000001,
    ],
    Grapes: [
      59593, 63326, 64259, 71229, 0, 0, 0, 0, 0, 81913.34999999999, 90104.685,
      99115.1535,
    ],
    GuavaAndMango: [
      7284, 4774, 4559, 5011, 0, 0, 0, 0, 0, 5762.65, 6338.915,
      6972.806500000001,
    ],
    Pepper: [
      3954, 4901, 4095, 4371, 0, 0, 0, 0, 0, 5026.65, 5529.315, 6082.2465,
    ],
    Potatos: [
      11747, 13887, 12677, 13951, 0, 5, 3, 0, 0, 16043.65, 17648.015,
      19412.8165,
    ],
    Strawberry: [
      7550, 9919, 12665, 7727, 0, 0, 0, 0, 0, 8886.05, 9774.655, 10752.1205,
    ],
    Tomato: [
      84079, 94542, 88912, 106106, 0, 4177, 4294, 2003, 2262, 122021.9,
      134224.09, 147646.499,
    ],
    apricots: [
      1428, 1832, 1509, 1318, 0, 0, 0, 0, 0, 1515.6999999999998, 1667.27,
      1833.997,
    ],
    onionsAndShallots: [
      4838, 4544, 4501, 4917, 0, 0, 0, 0, 0, 5654.549999999999, 6220.005,
      6842.005500000001,
    ],
    peachs: [
      11987, 14332, 11327, 13763, 0, 0, 0, 0, 0, 15827.449999999999, 17410.195,
      19151.214500000002,
    ],
    plums: [
      2179, 1543, 1707, 1629, 0, 0, 0, 0, 0, 1873.35, 2060.685,
      2266.7535000000003,
    ],
  },
  اليونان: {
    Citrus: [
      7756, 9044, 9681, 9069, 5967, 0, 170, 34, 3, 6563.700000000001,
      7220.0700000000015, 7942.077000000002,
    ],
    Grapes: [
      14491, 26611, 16722, 12313, 22520, 37, 0, 11, 0, 24772.000000000004,
      27249.200000000008, 29974.12000000001,
    ],
    GuavaAndMango: [
      74666, 75988, 111412, 73197, 69688, 27197, 17980, 45490, 27509, 76656.8,
      84322.48000000001, 92754.72800000002,
    ],
    Pepper: [
      978, 1104, 1160, 1151, 1643, 0, 0, 0, 0, 1807.3000000000002,
      1988.0300000000004, 2186.8330000000005,
    ],
    Potatos: [
      2313, 1992, 1729, 1108, 2962, 61, 97, 51, 5, 3258.2000000000003,
      3584.0200000000004, 3942.422000000001,
    ],
    Strawberry: [
      3675, 5064, 15985, 7739, 4804, 16, 166, 3567, 628, 5284.400000000001,
      5812.840000000001, 6394.124000000002,
    ],
    Tomato: [
      38885, 34149, 32932, 37650, 35249, 1258, 1476, 761, 1052, 38773.9,
      42651.29000000001, 46916.419000000016,
    ],
    apricots: [
      478, 472, 465, 766, 1126, 0, 0, 0, 0, 1238.6000000000001,
      1362.4600000000003, 1498.7060000000004,
    ],
    onionsAndShallots: [
      2674, 2366, 2584, 2916, 3676, 73, 57, 59, 62, 4043.6000000000004,
      4447.960000000001, 4892.756000000001,
    ],
    peachs: [
      1997, 2040, 2281, 3181, 5437, 0, 0, 0, 0, 5980.700000000001,
      6578.770000000001, 7236.647000000002,
    ],
    plums: [
      6362, 3934, 3159, 2721, 4567, 31, 0, 0, 0, 5023.700000000001,
      5526.0700000000015, 6078.677000000002,
    ],
  },
  الهند: {
    Citrus: [
      87279, 106757, 120430, 112362, 0, 627, 248, 474, 532, 129216.29999999999,
      142137.93, 156351.723,
    ],
    Grapes: [4, 0, 0, 0, 0, 0, 16, 0, 12, 0, 0, 0],
    GuavaAndMango: [
      139, 160, 16, 33, 0, 0, 0, 0, 0, 37.949999999999996, 41.745, 45.9195,
    ],
    Pepper: [
      3882, 4155, 6715, 5550, 0, 0, 0, 0, 0, 6382.499999999999, 7020.75,
      7722.825000000001,
    ],
    Potatos: [
      88, 136, 46, 3, 0, 0, 0, 0, 20, 3.4499999999999997, 3.795, 4.1745,
    ],
    Strawberry: [
      1824, 1233, 39833, 67668, 0, 2726, 58, 8125, 4273, 77818.2, 85600.02,
      94160.02200000001,
    ],
    Tomato: [
      31963, 49752, 44399, 29859, 0, 16266, 37122, 23679, 15445, 34337.85,
      37771.635, 41548.798500000004,
    ],
    apricots: [118, 394, 523, 836, 0, 0, 0, 0, 0, 961.4, 1057.54, 1163.294],
    onionsAndShallots: [
      822, 1112, 1718, 697, 0, 10, 6, 0, 1, 801.55, 881.705, 969.8755000000001,
    ],
    peachs: [
      398, 343, 381, 199, 0, 0, 0, 0, 0, 228.85, 251.735, 276.90850000000006,
    ],
    plums: [
      164803, 136876, 93147, 85570, 0, 0, 0, 0, 0, 98405.49999999999,
      108246.04999999999, 119070.655,
    ],
  },
  إندونيسيا: {
    Citrus: [
      258258, 319519, 382513, 277973, 0, 1027, 634, 1622, 857,
      319668.94999999995, 351635.845, 386799.4295,
    ],
    Grapes: [164, 182, 98, 4, 0, 0, 0, 0, 0, 4.6, 5.06, 5.566],
    GuavaAndMango: [
      29011, 18771, 17861, 17649, 0, 5334, 773, 1288, 0, 20296.35, 22325.985,
      24558.583500000004,
    ],
    Pepper: [
      1270, 7560, 8973, 5735, 0, 0, 0, 0, 0, 6595.249999999999, 7254.775,
      7980.2525000000005,
    ],
    Potatos: [
      477, 1180, 1478, 1986, 0, 0, 0, 0, 0, 2283.8999999999996, 2512.29,
      2763.5190000000002,
    ],
    Strawberry: [
      61712, 56628, 56596, 65512, 0, 0, 0, 0, 87, 75338.79999999999, 82872.68,
      91159.948,
    ],
    Tomato: [
      149471, 136363, 239686, 150319, 0, 2997, 1980, 3695, 2394,
      172866.84999999998, 190153.535, 209168.88850000003,
    ],
    apricots: [4, 5, 2, 20, 0, 0, 0, 0, 0, 23, 25.3, 27.830000000000002],
    onionsAndShallots: [13, 5, 1, 1, 0, 0, 0, 0, 0, 1.15, 1.265, 1.3915],
    peachs: [
      649, 1000, 1966, 1739, 0, 0, 0, 0, 0, 1999.85, 2199.835,
      2419.8185000000003,
    ],
    plums: [
      3801, 3186, 1633, 1072, 0, 0, 0, 0, 0, 1232.8, 1356.0800000000002,
      1491.6880000000003,
    ],
  },

  المغرب: {
    Citrus: [
      1156, 8340, 9683, 19303, 0, 20, 22, 144, 96, 22198.449999999997,
      24418.295, 26860.1245,
    ],
    Grapes: [
      1, 9, 108, 185, 0, 0, 0, 0, 0, 212.74999999999997, 234.02499999999998,
      257.4275,
    ],
    GuavaAndMango: [
      25430, 28467, 25389, 30260, 0, 0, 29, 0, 0, 34799, 38278.9,
      42106.79000000001,
    ],
    Pepper: [183, 57, 107, 64, 0, 0, 0, 0, 0, 73.6, 80.96, 89.056],
    Potatos: [
      181, 157, 1, 14, 0, 0, 0, 0, 0, 16.099999999999998, 17.71, 19.481,
    ],
    Strawberry: [
      283, 351, 86, 1235, 0, 86, 20, 28, 0, 1420.25, 1562.275,
      1718.5025000000003,
    ],
    Tomato: [
      148, 249, 417, 195, 0, 152, 471, 1258, 3501, 224.24999999999997,
      246.67499999999998, 271.34250000000003,
    ],
    apricots: [19, 6, 7, 12, 0, 0, 0, 0, 0, 13.799999999999999, 15.18, 16.698],
    onionsAndShallots: [
      5844, 6526, 5824, 5964, 0, 37, 13, 85, 27, 6858.599999999999, 7544.46,
      8298.906,
    ],
    peachs: [
      977, 234, 1105, 132, 0, 0, 0, 0, 0, 151.79999999999998, 166.98, 183.678,
    ],
    plums: [
      8287, 28445, 19022, 18575, 0, 0, 0, 45, 38, 21361.25, 23497.375000000004,
      25847.112500000007,
    ],
  },

  عمان: {
    Citrus: [
      16771, 20439, 23924, 22462, 0, 4686, 2933, 3226, 2774, 25831.3, 28414.43,
      31255.873000000003,
    ],
    Grapes: [
      17441, 16949, 14269, 20579, 0, 252, 240, 397, 72, 23665.85, 26032.435,
      28635.678500000005,
    ],
    GuavaAndMango: [
      34111, 29178, 25042, 30385, 0, 8363, 6120, 2084, 8532, 34942.75,
      38437.025, 42280.72750000001,
    ],
    Pepper: [
      1480, 2065, 3395, 3473, 0, 3, 14, 54, 50, 3993.95, 4393.345,
      4832.679500000001,
    ],
    Potatos: [
      2564, 4422, 4203, 3423, 0, 713, 904, 2040, 1051, 3936.45, 4330.095,
      4763.1045,
    ],
    Strawberry: [
      29458, 26262, 24446, 29737, 0, 12421, 3694, 7273, 2632,
      34197.549999999996, 37617.305, 41379.035500000005,
    ],
    Tomato: [
      44021, 51214, 47041, 62899, 0, 9682, 14163, 15039, 17825,
      72333.84999999999, 79567.235, 87523.95850000001,
    ],
    apricots: [
      1048, 1385, 1858, 1426, 0, 1, 0, 15, 0, 1639.8999999999999, 1803.89,
      1984.2790000000002,
    ],
    onionsAndShallots: [
      19167, 33414, 23648, 24014, 0, 2413, 2228, 2458, 2568, 27616.1, 30377.71,
      33415.481,
    ],
    peachs: [
      4502, 5481, 4823, 5396, 0, 364, 305, 1013, 971, 6205.4,
      6825.9400000000005, 7508.5340000000015,
    ],
    plums: [2627, 1578, 766, 919, 0, 0, 0, 0, 0, 1056.85, 1162.535, 1278.7885],
  },

  قطر: {
    Citrus: [
      8300, 11048, 12210, 12042, 0, 261, 0, 0, 0, 13848.3, 15233.130000000001,
      16756.443000000003,
    ],
    Grapes: [
      29148, 29402, 26529, 25139, 0, 874, 0, 0, 0, 28909.85, 31800.835000000003,
      34980.91850000001,
    ],
    GuavaAndMango: [
      21691, 16539, 18054, 19048, 0, 4544, 0, 0, 0, 21905.199999999997,
      24095.719999999998, 26505.291999999998,
    ],
    Pepper: [4787, 4528, 4597, 4308, 0, 10, 0, 0, 0, 4954.2, 5449.62, 5994.582],
    Potatos: [
      11566, 9880, 13119, 13777, 0, 924, 0, 0, 0, 15843.55, 17427.905,
      19170.6955,
    ],
    Strawberry: [
      28647, 30851, 28633, 36491, 0, 104, 0, 0, 0, 41964.649999999994,
      46161.115, 50777.226500000004,
    ],
    Tomato: [
      48271, 49835, 51168, 54191, 0, 4028, 0, 0, 0, 62319.649999999994,
      68551.615, 75406.7765,
    ],
    apricots: [
      1568, 1127, 1188, 1459, 0, 5, 0, 0, 0, 1677.85, 1845.635,
      2030.1985000000002,
    ],
    onionsAndShallots: [
      15339, 15671, 17743, 22171, 0, 505, 0, 0, 0, 25496.649999999998,
      28046.315, 30850.946500000002,
    ],
    peachs: [
      4082, 3250, 3461, 3952, 0, 290, 0, 0, 0, 4544.799999999999, 4999.28,
      5499.2080000000005,
    ],
    plums: [
      1762, 1294, 1161, 2325, 0, 4, 0, 0, 0, 2673.75, 2941.1250000000005,
      3235.2375000000006,
    ],
  },
};

function Table({ country }) {
  return (
    <>
      {/* <p className="unit">العملة المستخدمة هي بالدولار الامريكي</p> */}
      <table className="table table-bordered countries-table">
        {/* <col />
      <colgroup span="4"></colgroup>
      <colgroup span="3"></colgroup>
      <colgroup span="3"></colgroup> */}

        <thead>
          <tr>
            <th
              colSpan="13"
              // className="mahsool"
              className="title title-hover"
              style={{
                fontSize: '1.3rem',
              }}
            >
              واردات دولة {country} بالألف دولار
            </th>
          </tr>
          <tr>
            <td
              rowSpan="2"
              className="title title-hover"
              style={{
                fontSize: '1rem',
              }}
            >
              المحصول
            </td>
            <th
              colSpan="5"
              scope="colgroup"
              className="separateByBorder bottomSeparateByBorder"
            >
              واردات {country} من دول العالم
            </th>
            <th
              colSpan="4"
              scope="colgroup"
              className="separateByBorder bottomSeparateByBorder"
            >
              واردات {country} من مصر
            </th>
            <th colSpan="3" scope="colgroup" className="bottomSeparateByBorder">
              التوقعات
            </th>
          </tr>
          <tr>
            <th scope="col" className="bold-util bottomSeparateByBorder">
              2017
            </th>
            <th scope="col" className="bold-util bottomSeparateByBorder">
              2018
            </th>
            <th scope="col" className="bold-util bottomSeparateByBorder">
              2019
            </th>
            <th scope="col" className="bold-util bottomSeparateByBorder">
              2020
            </th>
            <th
              scope="col"
              className="separateByBorder bold-util bottomSeparateByBorder"
            >
              2021
            </th>
            <th scope="col" className="bold-util bottomSeparateByBorder">
              2017
            </th>
            <th scope="col" className="bold-util bottomSeparateByBorder">
              2018
            </th>
            <th scope="col" className="bold-util bottomSeparateByBorder">
              2019
            </th>
            <th
              scope="col"
              className="separateByBorder bold-util bottomSeparateByBorder"
            >
              2020
            </th>
            <th scope="col" className="bold-util bottomSeparateByBorder">
              2023
            </th>
            <th scope="col" className="bold-util bottomSeparateByBorder">
              2024
            </th>
            <th scope="col" className="bold-util bottomSeparateByBorder">
              2025
            </th>
          </tr>
        </thead>

        <tbody>
          <tr className="no-cell">
            <th scope="row" className="mahsool">
              الموالح
            </th>
            {data[country]['Citrus'].map((el, i) => {
              return <td key={i}>{el.toLocaleString()}</td>;
            })}
            {/* <td> 1,565,169,000</td>
          <td> 1,565,169,000</td>
          <td> 1,565,169,000</td>
          <td> 1,565,169,000</td>
          <td> 1,565,169,000</td>
          <td> 1,565,169,000</td>
          <td> 1,565,169,000</td>
          <td> 1,565,169,000</td>
          <td> 1,565,169,000</td> */}
          </tr>
          <tr className="no-cell">
            <th scope="row" className="mahsool">
              الطماطم
            </th>
            {data[country]['Tomato'].map((el, i) => {
              return <td key={i}>{el.toLocaleString()}</td>;
            })}
          </tr>
          <tr className="no-cell">
            <th scope="row" className="mahsool">
              البصل و الثوم
            </th>
            {data[country]['onionsAndShallots'].map((el, i) => {
              return <td key={i}>{el.toLocaleString()}</td>;
            })}
          </tr>
          <tr className="no-cell">
            <th scope="row" className="mahsool">
              البطاطس
            </th>
            {data[country]['Potatos'].map((el, i) => {
              return <td key={i}>{el.toLocaleString()}</td>;
            })}
          </tr>
          <tr className="no-cell">
            <th scope="row" className="mahsool">
              المانجو و الجوافة
            </th>
            {data[country]['GuavaAndMango'].map((el, i) => {
              return <td key={i}>{el.toLocaleString()}</td>;
            })}
          </tr>
          <tr className="no-cell">
            <th scope="row" className="mahsool">
              العنب
            </th>
            {data[country]['Grapes'].map((el, i) => {
              return <td key={i}>{el.toLocaleString()}</td>;
            })}
          </tr>
          <tr className="no-cell">
            <th scope="row" className="mahsool">
              الفرولة
            </th>
            {data[country]['Strawberry'].map((el, i) => {
              return <td key={i}>{el.toLocaleString()}</td>;
            })}
          </tr>
          <tr className="no-cell">
            <th scope="row" className="mahsool">
              البرقوق
            </th>
            {data[country]['plums'].map((el, i) => {
              return <td key={i}>{el.toLocaleString()}</td>;
            })}
          </tr>
          <tr className="no-cell">
            <th scope="row" className="mahsool">
              المشمش
            </th>
            {data[country]['apricots'].map((el, i) => {
              return <td key={i}>{el.toLocaleString()}</td>;
            })}
          </tr>
          <tr className="no-cell">
            <th scope="row" className="mahsool">
              الخوخ
            </th>
            {data[country]['peachs'].map((el, i) => {
              return <td key={i}>{el.toLocaleString()}</td>;
            })}
          </tr>
          <tr className="no-cell">
            <th scope="row" className="mahsool">
              الفلفل
            </th>
            {data[country]['Pepper'].map((el, i) => {
              return <td key={i}>{el.toLocaleString()}</td>;
            })}
          </tr>
          {/* <tr>
          <th scope="row" className="mahsool">
            البطاطس
          </th>
          {data[country]['Pepper'].map((el) => {
            return <td>{el}</td>;
          })}
        </tr> */}
        </tbody>
      </table>
    </>
  );
}

export default Table;
